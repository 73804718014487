import React, { useEffect, useState } from 'react'
import { BlanksScanner, LotesScanner } from '../common/seplac'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { PERMISSOES, LoteModel, ProdutoModel } from '../common/Models'
import { format, parseISO } from 'date-fns'
import styled from '@emotion/styled'
import { useLocation, useHistory } from 'react-router-dom'
import { notification, Tabs, Collapse, Tooltip, Button, Modal, Progress } from 'antd'
import { AuthStore, getEmpresaAtiva, getUsuarioLogado } from '../auth/AuthStore'
import Axios from 'axios'
import { createSimpleStore } from 'react-simple-reducer'
import { DeleteOutlined } from '@ant-design/icons'
import useAxios from 'axios-hooks'
import { Show } from 'components/Show'
import path from 'path-browserify'
import { BoletimOcorrencia } from 'empresas/DetalheEmpresa'

const Store = createSimpleStore(
  {
    produtos: [] as ProdutoModel[],
    auditoria: null as any,
  },
  {
    insertProdutos(state, produtos: ProdutoModel[]) {
      state.produtos = [...state.produtos, ...produtos]
    },
    removeProduto(state, produto) {
      state.produtos = state.produtos.filter((p) => p.id !== produto.id)
    },
    auditoriaSuccess(state, auditoria) {
      state.produtos = []
      state.auditoria = auditoria
    },
    clearDevolucao(state) {
      state.auditoria = null
    },
  }
)

export const Auditoria = () => {
  usePermissaoUsuario([PERMISSOES.CRIAR_AUDITORIA, PERMISSOES.AUDITAR], { changeRoute: true })
  const [produtosAuditoria, setProdutosAuditoria] = useState({})
  const { data } = useLocation().state as any
  const dataFim = data?.dataFim ? format(new Date(data.dataFim), 'dd/MM/yyyy') : null
  const dataInicio = data?.dataInicio ? format(new Date(data.dataInicio), 'dd/MM/yyyy') : null
  const { Layout, ConsultaContainer, ConfirmaContainer } = Auditoria

  useEffect(() => {
    getProdutosAuditoria()
  }, [])

  async function getProdutosAuditoria() {
    const response = await Axios.get(`/auditorias/produtos/${data.id}`).then((x) => x?.data)
    setProdutosAuditoria(response)
  }

  const {
    produtosCarroEstampados,
    produtosCarroQrImpresso,
    produtosMotoEstampados,
    produtosMotoQrImpresso,
    produtosNãoAuditado,
    produtosTotal,
    dataConclusao,
  } = produtosAuditoria as any

  const totalProdutosAuditoria =
    produtosCarroEstampados?.length +
    produtosCarroQrImpresso?.length +
    produtosMotoEstampados?.length +
    produtosMotoQrImpresso?.length

  return (
    <Store.Provider>
      <Layout>
        <h2>AUDITORIA - {`(${dataInicio} à ${dataFim})`} </h2>
        <h3>
          Restam <strong className="blue">{totalProdutosAuditoria}</strong> Pendências -{' '}
          <strong className="blue">
            {((produtosNãoAuditado / produtosTotal) * 100).toFixed(0)}%
          </strong>
        </h3>
        <ConsultaContainer>
          <Tabs>
            <Tabs.TabPane tab="Produto" key="1">
              <InsereProduto />
            </Tabs.TabPane>
          </Tabs>
        </ConsultaContainer>
        <ListaProdutos>{(produto) => <Produto key={produto.id} produto={produto} />}</ListaProdutos>

        {!dataConclusao && (
          <ConfirmaContainer>
            <Confirmar getProdutosAuditoria={getProdutosAuditoria} />
          </ConfirmaContainer>
        )}
        <hr />

        <AuditoriaInfo />

        <Tabs style={{ width: '100%' }}>
          <Tabs.TabPane tab={<strong>PLACAS</strong>} key="1">
            <CarrosContainer produtosCarro={produtosAuditoria} />
            <MotosContainer produtosMoto={produtosAuditoria} />
          </Tabs.TabPane>
          {dataConclusao && (
            <Tabs.TabPane tab={<strong>BOLETIM DE OCORRÊNCIA</strong>} key="2">
              <BoletimOcorrencia auditoriaId={data.id} auditoria={produtosAuditoria} />
            </Tabs.TabPane>
          )}
        </Tabs>
        {!dataConclusao && (
          <Finalizar produtosNãoAuditado={produtosNãoAuditado} produtosTotal={produtosTotal} />
        )}
      </Layout>
    </Store.Provider>
  )
}
Auditoria.Layout = styled.div`
  text-align: center;

  h2 {
    font-weight: bolder;
  }
  h3 {
    font-weight: bolder;
  }

  .blue {
    color: #037bfc;
  }

  hr {
    background: rgba(39, 39, 39, 0.1);
    height: 3px;
    border: 0;
    width: 90%;
    border-radius: 3px;
  }
`
Auditoria.ConsultaContainer = styled.div`
  display: flex;
  justify-content: center;
`
Auditoria.ConfirmaContainer = styled.div`
  width: 85%;
  display: flex;
  margin: auto;
`

const ListaProdutos = ({ children }) => {
  const { Layout } = ListaProdutos
  const { produtos } = Store.useState()
  return <Layout>{produtos?.map((p) => children(p))}</Layout>
}
ListaProdutos.Layout = styled.div`
  width: 85%;
  margin: auto;
  margin-bottom: 10px;
`

const Produto = ({ produto }: { produto: ProdutoModel }) => {
  const { Layout } = Produto
  const dispatch = Store.useDispatch()

  function handleClickDelete() {
    dispatch(Store.actions.removeProduto(produto))
  }

  return (
    <Layout>
      <strong>Produto</strong>
      <div>Serial: {produto?.serial}</div>
      <div>Tipo: {produto?.tipoProduto?.descricao}</div>
      <div>
        Criado em:{' '}
        {produto?.createdAt ? format(parseISO(produto.createdAt), 'dd/MM/yyyy HH:mm') : null}
      </div>
      <div>Pertencente ao lote: {produto?.loteId}</div>
      <div>
        <Tooltip title="Remover produto da lista de envio">
          <Button shape="circle" icon={<DeleteOutlined />} onClick={handleClickDelete} />
        </Tooltip>
      </div>
    </Layout>
  )
}
Produto.Layout = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
`

const Confirmar = ({ getProdutosAuditoria }) => {
  const { produtos } = Store.useState()
  const dispatch = Store.useDispatch()

  const { data } = useLocation().state as any

  const [{ loading }, reqAuditar] = useAxios(
    {
      url: '/auditorias/auditar',
      method: 'POST',
      data: { produtos, auditoriaId: data.id },
    },
    { manual: true }
  )

  async function handleClick() {
    if (produtos.length === 0) {
      notification.error({ message: 'Informe o produto que você deseja auditar!' })
      return
    }

    try {
      const auditoriaResponse = await reqAuditar().then((x) => x.data)
      dispatch(Store.actions.auditoriaSuccess(auditoriaResponse))
      getProdutosAuditoria()
      notification.success({ message: 'Produtos auditados com sucesso' })
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Ocorreu um erro ao confirmar produtos'
      notification.error({ message })
    }
  }

  return (
    <Button loading={loading} onClick={handleClick}>
      {' '}
      Confirmar{' '}
    </Button>
  )
}

const AuditoriaInfo = () => {
  const { data } = useLocation().state as any
  const dataFim = data.dataFim ? format(new Date(data.dataFim), 'dd/MM/yyyy') : null
  const { InfoContainer } = AuditoriaInfo
  const { Panel } = Collapse

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header={<strong>Importante</strong>} key="1">
        <InfoContainer id="InfoContainer">
          <div>
            <ul>
              <li>
                Resolva as pendências até dia <strong className="red">{dataFim}</strong> para evitar
                bloqueios.
              </li>
              <li>É necessário validar placas em estoque e estampadas.</li>
              <li>Placas recebidas após o início desta auditoria serão incluídas na próxima.</li>
            </ul>
          </div>
          <div>
            <ol>
              <h2>Como realizar a auditoria?</h2>
              <li>Selecione o Lote ou Produto</li>
              <li>Faça a bipagem da placa</li>
            </ol>
          </div>
        </InfoContainer>
      </Panel>
    </Collapse>
  )
}
AuditoriaInfo.InfoContainer = styled.div`
  margin-top: 20px;
  width: 95%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  place-items: center;
  text-align: left;

  ul {
    list-style-position: inside;
    padding: 0;
  }
  ol {
    list-style-position: inside;
    padding: 0;
  }

  li {
    font-weight: bold;
    font-size: 16px;
  }

  .red {
    color: #ff4d4d;
  }
`

const CarrosContainer = ({ produtosCarro }) => {
  const { Panel } = Collapse
  const { Layout, HeaderTitle } = CarrosContainer
  const { produtosCarroEstampados, produtosCarroQrImpresso } = produtosCarro
  const qtdBlanksCarro = `Blanks (${produtosCarroQrImpresso?.length})`
  const qtdEstampadasCarro = `Estampadas (${produtosCarroEstampados?.length})`
  const admin = AuthStore.useSelector(getUsuarioLogado)?.admin

  return (
    <Layout>
      <Collapse defaultActiveKey={['HeaderTitle_Carros']}>
        <HeaderTitle
          id="HeaderTitle_Carros"
          header={`PLACA DE CARROS (${
            produtosCarroQrImpresso?.length + produtosCarroEstampados?.length
          })`}
          key={'HeaderTitle_Carros'}
        >
          <Collapse defaultActiveKey={admin ? ['1', '2'] : []}>
            <Panel header={qtdBlanksCarro} key="1" showArrow={admin} disabled={!admin}>
              {admin && (
                <ol>
                  {produtosCarroQrImpresso?.map((blank) => {
                    const createdAt = blank?.createdAt
                      ? format(
                          new Date(String(blank?.createdAt).replace(' +00:00', '')),
                          'dd/MM/yyyy'
                        )
                      : ''
                    return (
                      <>
                        <li key={blank?.serial}>
                          <strong>N° {blank?.serial} </strong>
                          <strong className="gray">
                            {' '}
                            - Lote {blank?.loteId} recebida em {createdAt}
                          </strong>
                        </li>
                      </>
                    )
                  })}
                </ol>
              )}
            </Panel>
            <Panel header={qtdEstampadasCarro} key="2" showArrow={admin} disabled={!admin}>
              {admin && (
                <ol>
                  {produtosCarroEstampados?.map((placa) => {
                    const createdAt = placa?.createdAt
                      ? format(
                          new Date(String(placa?.createdAt).replace(' +00:00', '')),
                          'dd/MM/yyyy'
                        )
                      : ''
                    return (
                      <>
                        <li key={placa?.serial}>
                          <strong>N° {placa?.serial} </strong>
                          <strong className="gray">
                            {' '}
                            - Lote {placa?.loteId} recebida em {createdAt}
                          </strong>
                        </li>
                      </>
                    )
                  })}
                </ol>
              )}
            </Panel>
          </Collapse>
        </HeaderTitle>
      </Collapse>
    </Layout>
  )
}
CarrosContainer.Layout = styled.div`
  text-align: left;
  width: 80%;
  margin: auto;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 30px;

  .ant-collapse-header {
    font-size: 15px;
    font-weight: bold;

    &:hover {
      background: #36a0fc;
      transition: 0.3s;
    }
  }

  .gray {
    color: gray;
    font-weight: bold;
  }

  ol {
    font-weight: bold;
  }

  li {
    margin-bottom: 8px;
  }
`
CarrosContainer.HeaderTitle = styled(Collapse.Panel)`
  background: #e9e9e9;
  .ant-collapse-content-box {
    padding: 0px;
  }
`

const MotosContainer = ({ produtosMoto }) => {
  const { Panel } = Collapse
  const { Layout, HeaderTitle } = MotosContainer
  const { produtosMotoEstampados, produtosMotoQrImpresso } = produtosMoto
  const qtdBlanksMoto = `Blanks (${produtosMotoQrImpresso?.length})`
  const qtdEstampadasMoto = `Estampadas (${produtosMotoEstampados?.length})`
  const admin = AuthStore.useSelector(getUsuarioLogado)?.admin

  return (
    <Layout>
      <Collapse defaultActiveKey={['HeaderTitle_Motos']}>
        <HeaderTitle
          header={`PLACA DE MOTOS (${
            produtosMotoQrImpresso?.length + produtosMotoEstampados?.length
          })`}
          key="HeaderTitle_Motos"
        >
          <Collapse defaultActiveKey={admin ? ['1', '2'] : []}>
            <Panel header={qtdBlanksMoto} key="1" showArrow={admin} disabled={!admin}>
              {admin && (
                <ol>
                  {produtosMotoQrImpresso?.map((blank) => {
                    const createdAt = blank?.createdAt
                      ? format(
                          new Date(String(blank?.createdAt).replace(' +00:00', '')),
                          'dd/MM/yyyy'
                        )
                      : ''
                    return (
                      <>
                        <li key={blank.serial}>
                          <strong>N° {blank.serial} </strong>
                          <strong className="gray">
                            {' '}
                            - Lote {blank.loteId} recebida em {createdAt}
                          </strong>
                        </li>
                      </>
                    )
                  })}
                </ol>
              )}
            </Panel>
            <Panel header={qtdEstampadasMoto} key="2" showArrow={admin} disabled={!admin}>
              {admin && (
                <ol>
                  {produtosMotoEstampados?.map((placa) => {
                    const createdAt = placa?.createdAt
                      ? format(
                          new Date(String(placa?.createdAt).replace(' +00:00', '')),
                          'dd/MM/yyyy'
                        )
                      : ''
                    return (
                      <>
                        <li key={placa.serial}>
                          <strong>N° {placa.serial} </strong>
                          <strong className="gray">
                            {' '}
                            - Lote {placa.loteId} recebida em {createdAt}
                          </strong>
                        </li>
                      </>
                    )
                  })}
                </ol>
              )}
            </Panel>
          </Collapse>
        </HeaderTitle>
      </Collapse>
    </Layout>
  )
}
MotosContainer.Layout = styled.div`
  text-align: left;
  width: 80%;
  margin: auto;
  justify-content: center;

  .ant-collapse-header {
    font-size: 15px;
    font-weight: bold;

    &:hover {
      background: #36a0fc;
      transition: 0.3s;
    }
  }

  .gray {
    color: gray;
    font-weight: bold;
  }

  ol {
    font-weight: bold;
  }

  li {
    margin-bottom: 8px;
  }
`
MotosContainer.HeaderTitle = styled(Collapse.Panel)`
  background: #e9e9e9;
  .ant-collapse-content-box {
    padding: 0px;
  }
`

const InsereProduto = () => {
  const dispatch = Store.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()

  function handleScan(produto: ProdutoModel) {
    if (produto.empresaDetentoraId !== idEmpresaAtiva) {
      return notification.info({ message: 'Blank não pertence à empresa atual' })
    }

    dispatch(Store.actions.insertProdutos([produto]))
  }

  return (
    <div>
      <BlanksScanner onScan={handleScan} showProduto={false} style={{ width: 500 }} />
    </div>
  )
}

const Finalizar = ({ produtosNãoAuditado, produtosTotal }) => {
  const { Layout, ButtonFinalizar, AuditoriaSemPendencia } = Finalizar
  const [showModal, setShowModal] = useState<boolean>()
  const history = useHistory()

  const closeModal = () => setShowModal(false)
  const handleFinalizarAuditoria = () => setShowModal(true)

  return (
    <Layout>
      <ButtonFinalizar onClick={handleFinalizarAuditoria}>Finalizar Auditoria</ButtonFinalizar>

      <Modal width={500} visible={showModal} closable={false} footer={null}>
        <Show when={produtosNãoAuditado === 0}>
          <AuditoriaSemPendencia>
            Sua Auditoria foi finalizada com sucesso!
            <Button
              type="primary"
              style={{ marginLeft: 5 }}
              onClick={() => {
                closeModal()
                history.push('/produtos/auditorias')
              }}
            >
              OK
            </Button>
          </AuditoriaSemPendencia>
        </Show>

        <Show when={produtosNãoAuditado > 0}>
          <AuditoriaComPendencia
            produtosNãoAuditado={produtosNãoAuditado}
            produtosTotal={produtosTotal}
          />
          <Button type="primary" style={{ marginLeft: 5, marginTop: 5 }} onClick={closeModal}>
            Cancelar
          </Button>
        </Show>
      </Modal>
    </Layout>
  )
}
Finalizar.Layout = styled.div`
  width: 100%;
  margin-top: 15px;
`
Finalizar.ButtonFinalizar = styled(Button)`
  background: red;
  color: white;
  font-weight: bold;
  border: none;
  :hover {
    background: red;
    color: white;
  }
  :focus {
    background: red;
    color: white;
  }
`
Finalizar.AuditoriaSemPendencia = styled.div``

interface IUpdateProgress {
  progress?: number
  loading?: boolean
  success?: boolean
  message?: string
}
interface IFiles extends IUpdateProgress {
  file: File
}

const AuditoriaComPendencia = ({ produtosNãoAuditado, produtosTotal }) => {
  const history = useHistory()
  const { getBase64, ListaDeArquivos } = AuditoriaComPendencia
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const { data: auditoria } = useLocation().state as any
  const [files, setFiles] = useState<IFiles[]>([])

  const uploadFiles = async () => {
    const upload = await Promise.all(
      files.map(async (file, index) => {
        try {
          const fileName = `BO_${empresaAtiva?.id}_${index}_${format(
            new Date(),
            'dd-MM-yyyy-HH-mm-ss'
          )}${path.extname(file.file.name)}`
          const fileBase64 = await getBase64(file.file)

          const uploadBoletimOcorrencia = await Axios.post(
            '/auditorias/upload-boletim-ocorrencia',
            {
              fileBase64,
              fileName,
              contentType: file.file.type,
              auditoriaId: auditoria.id,
            },
            {
              onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                updateProgress({ index, progress, loading: true })
              },
            }
          ).then((res) => res.data)

          updateProgress({ index, progress: 100, loading: false, success: true })

          return uploadBoletimOcorrencia
        } catch (error: any) {
          const message = error?.response?.data?.message ?? 'Ocorreu um erro ao enviar o arquivo'
          notification.error({ message })

          updateProgress({ index, progress: 0, loading: false, message, success: false })
          return { success: false }
        }
      })
    )

    const error = await upload.find((f) => f.success === false)
    if (error) {
      return
    }

    try {
      await Axios.post('/auditorias/finalizar-auditoria-boletim-ocorrencia', {
        auditoriaId: auditoria.id,
      })
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Ocorreu um erro ao finalizar a auditoria'
      notification.error({ message })
      return
    }
    history.push('/produtos/auditorias')
  }

  const updateProgress = ({
    index,
    progress,
    loading,
    message,
    success,
  }: IUpdateProgress & { index: number }) => {
    setFiles((previousFiles) => {
      previousFiles.splice(index, 1, {
        ...previousFiles[index],
        progress,
        loading,
        message,
        success,
      })
      return [...previousFiles]
    })
  }

  const handleFile = (file: File) => {
    setFiles((previousFiles) => {
      const verificarDuplicidade = previousFiles.find((f) => f.file.name === file.name)
      if (verificarDuplicidade) {
        notification.error({ message: 'Arquivo duplicado!' })
        return previousFiles
      }

      previousFiles.push({ file })
      return [...previousFiles]
    })
  }

  return (
    <>
      Restam {((produtosNãoAuditado / produtosTotal) * 100).toFixed(0)}% para concluir a auditoria!
      Para prosseguir é necessário anexar o Boletim de Ocorrência!
      <div>
        <Button
          type="primary"
          style={{ marginLeft: 5, marginBottom: 5 }}
          onClick={() => {
            document.getElementById('file-input')?.click()
          }}
        >
          Anexar Arquivo
        </Button>
      </div>
      <ListaDeArquivos>
        {files.map((file, key) => (
          <strong key={key} style={{ margin: 5 }}>
            {file.file.name}
            {!file?.progress && (
              <Button
                style={{ marginLeft: 10 }}
                title="Excluir"
                icon={<DeleteOutlined />}
                onClick={() => {
                  setFiles((previousFiles) => {
                    previousFiles.splice(key, 1)
                    return [...previousFiles]
                  })
                }}
              />
            )}
            {file.loading && <Progress width={30} type="circle" percent={file.progress} />}
            {file.success && <Progress width={30} type="circle" percent={100} />}
            {file.message}
          </strong>
        ))}

        {!!files.length && (
          <div>
            <Button type="primary" style={{ marginLeft: 5 }} onClick={uploadFiles}>
              Enviar
            </Button>
          </div>
        )}
      </ListaDeArquivos>
      <input
        id="file-input"
        type="file"
        hidden
        onChange={(e) => {
          const { files } = e.target
          if (files) {
            handleFile(files[0])
          }
        }}
      />
    </>
  )
}
AuditoriaComPendencia.ListaDeArquivos = styled.div`
  display: flex;
  flex-direction: column;
`
AuditoriaComPendencia.getBase64 = async (file: File) => {
  return await new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const baseURL = reader.result
      resolve(baseURL)
    }
  })
}
