import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { GerenciaEmpresas } from './GerenciaEmpresas'
import { DetalheEmpresa } from './DetalheEmpresa'

const Empresas = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/:empresaId`} component={DetalheEmpresa} />
      <Route path={`${match.path}/`} component={GerenciaEmpresas} />
    </Switch>
  )
}

export default Empresas
