import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Table, Button, notification, Select } from 'antd'
import axios from 'axios'
import { TextFieldInput } from 'common/TextField'
import { Show } from '../common/Show'
import { PedidoModel, PERMISSOES, SERVICOS } from 'common/Models'
import { PlacaMercosul } from 'common/seplac/PlacaMercosul'
import usePermissaoUsuario from 'common/hooks/usePermissaoUsuario'
import { StatusEmplacamentoDetran } from '../common/seplac/StatusEmplacamentoDetran'
import { AuthStore } from 'auth/AuthStore'

export const PendenciasDetran = () => {
  usePermissaoUsuario([PERMISSOES.REENVIAR_PENDENCIAS_DETRAN], { changeRoute: true })

  const { Flex, CnpjFabricanteContainer, CnpjEstampadorContainer } = PendenciasDetran
  const [fabricanteCnpj, setFabricanteCnpj] = useState('00.909.170/0001-93')
  const [estampadoraCnpj, setEstampadoraCnpj] = useState('')
  const [pendencias, setPendencias] = useState([])
  const [loading, setLoading] = useState(false)

  async function handleBuscarPendenciasDetran() {
    if (fabricanteCnpj && estampadoraCnpj) {
      try {
        setLoading(true)
        const data = await axios
          .get('/emplacamentos/pendencias', {
            params: { fabricanteCnpj, estampadoraCnpj },
          })
          .then((x) => x.data)
        setPendencias(data)
        setLoading(false)
      } catch (e: any) {
        setLoading(false)
        const message =
          e.response?.data?.message ?? 'Ocorreu um erro ao buscar as pendências junto ao detran'
        if (message === 'Nenhuma Pendência Encontrada') return notification.success({ message })
        notification.error({ message })
      }
    }
  }

  return (
    <>
      <Flex>
        <CnpjFabricanteContainer>
          <label>
            CNPJ Fabricante: <br />
            <TextFieldInput
              placeholder="Informe o CNPJ Fabricante"
              value={fabricanteCnpj}
              onChange={(e) => setFabricanteCnpj(e.target.value)}
              mask="cnpj"
            />
          </label>
        </CnpjFabricanteContainer>
        <CnpjEstampadorContainer>
          <label>
            CNPJ Estampadora: <br />
            <SelecaoEmpresa
              cnpjEstampadora={estampadoraCnpj}
              setCnpjEstampadora={setEstampadoraCnpj}
            />
          </label>
        </CnpjEstampadorContainer>
        <div>
          <Button onClick={async () => await handleBuscarPendenciasDetran()} type="primary">
            Buscar
          </Button>
        </div>
      </Flex>
      <Tabela data={pendencias} loading={loading} />
    </>
  )
}

PendenciasDetran.Flex = styled.div`
  display: flex;
  button {
    margin-top: 22px;
    width: 120px;
  }
`
PendenciasDetran.CnpjFabricanteContainer = styled.div`
  width: 170px;
  margin-right: 16px;
`
PendenciasDetran.CnpjEstampadorContainer = styled.div`
  width: 360px;
  margin-right: 16px;
`

const Tabela = ({ data, loading }) => {
  return (
    <Table
      dataSource={data}
      loading={loading}
      style={{ marginTop: 16 }}
      columns={[
        {
          title: 'Placa',
          width: 270,
          render: (row) => {
            if (row.pedido?.emplacamento?.servicoId === SERVICOS.PAR_DE_PLACAS) {
              return (
                <div style={{ position: 'relative', padding: '3% 0' }}>
                  <Show
                    condition={row.pedido?.emplacamento.pedido.servicoId === SERVICOS.PAR_DE_PLACAS}
                  >
                    <PlacaMercosul
                      placa={row.pedido?.emplacamento.placa}
                      categoria={row.pedido?.emplacamento.categoriaVeiculo}
                      colecao={row.pedido?.emplacamento.especieVeiculo === 'COLECAO'}
                      moto={row.pedido?.emplacamento.moto}
                      style={{ width: 200 }}
                    />
                  </Show>
                  <PlacaMercosul
                    placa={row?.pedido?.emplacamento?.placa}
                    categoria={row.pedido?.emplacamento.categoriaVeiculo}
                    colecao={row.pedido?.emplacamento.especieVeiculo === 'COLECAO'}
                    moto={row.pedido?.emplacamento.moto}
                    style={{ width: 200 }}
                  />
                </div>
              )
            }
            return (
              <Show condition={!!row?.pedido?.emplacamento}>
                <PlacaMercosul
                  placa={row?.pedido?.emplacamento?.placa}
                  categoria={row?.pedido?.emplacamento?.categoriaVeiculo}
                  colecao={row.pedido?.emplacamento.especieVeiculo === 'COLECAO'}
                  moto={row?.pedido?.emplacamento?.moto}
                  style={{ width: 200 }}
                />
              </Show>
            )
          },
        },
        { title: 'Códigos de Autorização', dataIndex: ['pendencia', 'codgChaveAutorizacao'] },
        { title: 'Num. Denatran', dataIndex: ['pendencia', 'numDenatran'] },
        {
          title: 'Pendências',
          width: 300,
          render: (row: { pendencia; pedido: PedidoModel }) => {
            const pedido: PedidoModel = {
              ...row.pedido,
              emplacamento: {
                ...row.pedido?.emplacamento,
                dataEnvioEmplacamentoDetranGo: row.pendencia.pendenciaInstalacaoPlaca
                  ? (null as any)
                  : row.pedido?.emplacamento.dataEnvioEmplacamentoDetranGo,
                dataEnvioImagensInstalacaoDetranGo: row.pendencia.pendenciaImageInstalacao
                  ? (null as any)
                  : row.pedido?.emplacamento.dataEnvioImagensInstalacaoDetranGo,
                dataEnvioNfeDetranGo: row.pendencia.pendenciaChaveAcessoNFE
                  ? (null as any)
                  : row.pedido?.emplacamento.dataEnvioNfeDetranGo,
              },
            }
            return <StatusEmplacamentoDetran pedido={pedido} />
          },
        },
      ]}
    />
  )
}

const SelecaoEmpresa = ({ cnpjEstampadora, setCnpjEstampadora }) => {
  const { empresas } = AuthStore.useState()

  const optDefault = [{ id: 0, descricao: 'Escolha uma empresa', cnpj: '' }]

  const onChangeEmpresaCnpj = (cnpj) => {
    if (cnpj === optDefault[0].cnpj) return
    setCnpjEstampadora(cnpj)
  }

  const filterEmpresas = (inputValue, option) => {
    if (!isNaN(inputValue)) return option.children[0] === +inputValue
    return option.children[2].toUpperCase().includes(inputValue.toUpperCase())
  }

  return (
    <div>
      <Select
        showSearch
        filterOption={filterEmpresas}
        style={{ width: '100%' }}
        placeholder="Escolha uma cidade"
        notFoundContent="Não encontrado"
        value={optDefault[0].cnpj || cnpjEstampadora}
        onChange={onChangeEmpresaCnpj}
      >
        {optDefault.concat(empresas).map((e) => (
          <Select.Option disabled={e.id === 0} key={e.id} value={e.cnpj}>
            {e.id} - {e.descricao}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}
