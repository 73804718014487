import React from 'react'
import useAxios from 'axios-hooks'
import { Select } from 'antd'
import { EmpresaModel } from '../Models'

interface IPropsSelectEmpresa extends React.HTMLAttributes<typeof Select> {
  tipoEmpresa: 'EMPLACADOR' | 'FABRICANTE'
  showSearch?
  onChangeEmpresa: (empresa: EmpresaModel) => any
}
const SelectEmpresa = (props: IPropsSelectEmpresa) => {
  const [{ data: empresas, error, loading }] = useAxios({
    url: '/empresas',
    method: 'GET',
    params: {
      tipoEmpresa: props.tipoEmpresa,
    },
  })

  if (error || loading) return <div>Loading ...</div>
  const { onChangeEmpresa, ...restProps } = props

  return (
    <>
      <Select
        filterOption={(input: any, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Escolha a empresa"
        onChange={(id) => onChangeEmpresa(empresas.find((e) => e.id === id))}
        {...(restProps as any)}
      >
        <Select.Option key={''} value={null as any}>
          Selecione uma empresa
        </Select.Option>
        {(empresas || []).map(OptionEmpresa)}
      </Select>
    </>
  )
}

const OptionEmpresa = (empresa) => {
  return (
    <Select.Option key={empresa.id} value={empresa.id}>
      {empresa.descricao}
    </Select.Option>
  )
}

export default SelectEmpresa
