import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { ListaPedidos } from './ListaPedidos/ListaPedidos'
import { PendentesFinalizacaoDetranGo } from './PendentesFinalizacaoDetranGo'
import { IniciosEstampagem } from './IniciosEstampagem'
import { PendenciasDetran } from './PendenciasDetran'
import { RelatorioCobrancas } from '../relatorios/RelatorioCobrancas'

const Emplacamentos = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/estampagem`} component={ListaPedidos} />
      <Route
        path={`${match.path}/pendentes-finalizacao`}
        component={PendentesFinalizacaoDetranGo}
      />
      <Route path={`${match.path}/pendencias-detran`} component={PendenciasDetran} />
      <Route path={`${match.path}/inicios-estampagem`} component={IniciosEstampagem} />
    </Switch>
  )
}

export default Emplacamentos
