import { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import Axios from 'axios'
import { get as _get } from 'lodash'

const cache = new LRU({ max: 10 })

const ConfigureAxios = ({ token, empresaId, usuarioId }, { forbidden }) => {
  const baseURL = process.env.REACT_APP_API_HOST
  const _token: any = token ? 'Bearer ' + token : null

  const axios = Axios.create({
    baseURL,
    headers: {
      Authorization: _token,
      empresaId,
      usuarioId,
    },
  })

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (_get(error, 'response.status') === 403) {
        console.log('forbidden')
        forbidden()
      }
      return await Promise.reject(error)
    }
  )

  configure({ axios, cache })

  Axios.defaults.headers.common.Authorization = _token
  Axios.defaults.headers.common.empresaId = empresaId
  Axios.defaults.headers.common.usuarioId = usuarioId

  Axios.defaults.baseURL = baseURL

  Axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (_get(error, 'response.status') === 403) {
        console.log('forbidden')
        forbidden()
      }
      return await Promise.reject(error)
    }
  )
}

export default ConfigureAxios
