import React from 'react'
import { getUsuarioLogado, getEmpresaAtiva, AuthStore } from './auth/AuthStore'
import { Link, useHistory, Redirect } from 'react-router-dom'

import { Card } from 'antd'
import styled from '@emotion/styled'

import { permissoesEmplacamento } from './Menu'
import { checkPermissao } from './common/checkPermissao'
import useAxios from 'axios-hooks'
import { EmpresaModel, TIPOS_PRODUTOS } from './common/Models'
import {
  CarryOutOutlined,
  ExportOutlined,
  ImportOutlined,
  ShoppingCartOutlined,
  WarningOutlined,
} from '@ant-design/icons'

const Home = () => {
  const { ErrorContainer } = Home
  const history = useHistory()
  const usuario = AuthStore.useSelector(getUsuarioLogado)
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const tipoEmpresa = empresaAtiva?.tipoEmpresa
  const permissoes = empresaAtiva?.permissoes ?? []
  const usuarioHasPermissaoEstampagem = checkPermissao(
    permissoesEmplacamento,
    permissoes,
    usuario?.admin
  )
  if (!usuario) {
    history.replace('/login')
    return null
  }
  if (tipoEmpresa === 'FABRICANTE') {
    return (
      <>
        <LinksFabricante />
        <EstampadoresEstoqueMinimo />
      </>
    )
  }

  if (usuarioHasPermissaoEstampagem) return <Redirect to={'/emplacamentos/estampagem'} />

  return (
    <ErrorContainer>Você não tem permissão para visualizar a tela de estampagem!</ErrorContainer>
  )
}
Home.ErrorContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinksFabricante = () => {
  return (
    <Styles.Card title="Fabricante">
      <ul>
        <li>
          <Styles.Link to="/lotes/cadastro">Solicitar lote de Blanks</Styles.Link>
        </li>
        <li>
          <Styles.Link to="/lotes">Lista de Lotes</Styles.Link>
          <ul>
            <li>Consultar Qrs gerados no SERPRO</li>
            <li>Baixar lista de Qrs gerados</li>
          </ul>
        </li>
        <li>
          <Styles.Link to="/produtos/qr-code/validar">Validar Qr Codes Impressos</Styles.Link>
        </li>
        <li>
          <Styles.Link to="/pedidos/venda">Enviar Produtos a Estampadores</Styles.Link>
        </li>
        <li>
          <Styles.Link to="/produtos/rastreio">Rastrear Produtos</Styles.Link>
        </li>
        <li>
          <Styles.Link to="/empresas">Gerenciar Empresas</Styles.Link>
        </li>
        <li>
          <Styles.Link to="/emplacamentos/pendentes-finalizacao">
            Emplacamentos Pendentes junto ao <strong>Detran-GO</strong>
          </Styles.Link>
        </li>
        <li>
          <Styles.Link to="/emplacamentos/inicios-estampagem">Inícios de Estampagem</Styles.Link>
        </li>
      </ul>
    </Styles.Card>
  )
}

const EstampadoresEstoqueMinimo = () => {
  const { Table } = EstampadoresEstoqueMinimo
  const [{ data: estoque }] = useAxios(
    `/produtos/agrupado?tipoProdutoId[]=${TIPOS_PRODUTOS.BLANK_CARRO}&tipoProdutoId[]=${TIPOS_PRODUTOS.BLANK_MOTO}`,
    {
      useCache: false,
    }
  )
  const [{ data: empresas }] = useAxios({
    url: '/empresas',
    params: {
      emAuditoria: 'true',
    },
  })
  const [{ data: produtosEmTransito }] = useAxios('/produtos/transito/agrupado', {
    useCache: false,
  })

  return (
    <Styles.Card title="Estoque das empresas" style={{ marginTop: 12 }}>
      <Table>
        <thead>
          <tr>
            <td>Empresa</td>
            <td colSpan={2} style={{ textAlign: 'center' }}>
              Blanks de CARRO
            </td>
            <td colSpan={2} style={{ textAlign: 'center' }}>
              Blanks de MOTO
            </td>
          </tr>
        </thead>
        <tbody>
          {empresas
            ?.sort((a, b) => {
              if (a.id === 323) return -1
              if (b.id === 323) return 1
              if (a.municipio.nome > b.municipio.nome) return 1
              if (a.municipio.nome < b.municipio.nome) return -1
              return a.descricao > b.descricao ? 1 : -1
            })
            .map((e) => {
              const estoqueEmpresa = estoque?.filter((x) => x.empresaDetentoraId === e.id)
              return (
                <EstoqueEmpresa
                  key={e.id}
                  empresa={e}
                  estoque={estoqueEmpresa}
                  produtosEmTransito={produtosEmTransito}
                />
              )
            })}
        </tbody>
      </Table>
    </Styles.Card>
  )
}
EstampadoresEstoqueMinimo.Table = styled.table`
  width: 100%;
  td {
    padding: 4px;
    border-bottom: 1px solid #f0f0f0;
  }
  thead td {
    font-size: 0.9em;
    font-weight: bold;
  }
`

const EstoqueEmpresa = ({
  empresa,
  estoque,
  produtosEmTransito,
}: {
  empresa: EmpresaModel
  estoque
  produtosEmTransito
}) => {
  if (!empresa) return null
  const estoquePlacaCarro =
    estoque?.find((e) => e.tipoProdutoId === TIPOS_PRODUTOS.BLANK_CARRO)?.total ?? ''
  const estoquePlacaMoto =
    estoque?.find((e) => e.tipoProdutoId === TIPOS_PRODUTOS.BLANK_MOTO)?.total ?? ''

  const qtdSaidaCarro = EstoqueEmpresa.getQtdProdutosTransito(
    produtosEmTransito,
    TIPOS_PRODUTOS.BLANK_CARRO,
    empresa.id,
    'SAIDA'
  )
  const qtdEntradaCarro = EstoqueEmpresa.getQtdProdutosTransito(
    produtosEmTransito,
    TIPOS_PRODUTOS.BLANK_CARRO,
    empresa.id,
    'ENTRADA'
  )
  const qtdSaidaMoto = EstoqueEmpresa.getQtdProdutosTransito(
    produtosEmTransito,
    TIPOS_PRODUTOS.BLANK_MOTO,
    empresa.id,
    'SAIDA'
  )
  const qtdEntradaMoto = EstoqueEmpresa.getQtdProdutosTransito(
    produtosEmTransito,
    TIPOS_PRODUTOS.BLANK_MOTO,
    empresa.id,
    'ENTRADA'
  )

  const estoquePlacaCarroReal = estoquePlacaCarro - qtdSaidaCarro + qtdEntradaCarro
  const estoquePlacaMotoReal = estoquePlacaMoto - qtdSaidaMoto + qtdEntradaMoto

  return (
    <tr style={{ textAlign: 'center' }}>
      <td style={{ textAlign: 'left' }}>
        {empresa.descricao}
        <br />
        <small style={{ color: '#444' }}>{empresa.municipio?.nome}</small>
        <br />
        <small style={{ color: 'red' }}>{empresa.auditoriaPendente && 'Em Auditoria'}</small>
      </td>
      <td>
        <EstoqueReal quantidade={estoquePlacaCarroReal} critico={empresa.estoqueMinimoPlacaCarro} />
      </td>
      <td>
        <EstoqueSistema
          quantidade={estoquePlacaCarro}
          entrada={qtdEntradaCarro}
          saida={qtdSaidaCarro}
        />
      </td>
      <td style={{ paddingLeft: 24 }}>
        <EstoqueReal quantidade={estoquePlacaMotoReal} critico={empresa.estoqueMinimoPlacaMoto} />
      </td>
      <td>
        <EstoqueSistema
          quantidade={estoquePlacaMoto}
          entrada={qtdEntradaMoto}
          saida={qtdSaidaMoto}
        />
      </td>
    </tr>
  )
}
EstoqueEmpresa.Td = styled.td<{ critico: boolean }>(
  (props) => `
  ${
    props.critico
      ? `
    font-size: 1.3em;
    color: red;
  `
      : ''
  }
`
)
EstoqueEmpresa.getQtdProdutosTransito = (
  produtosEmTransito,
  tipoProduto: TIPOS_PRODUTOS,
  empresaId,
  tipo: 'SAIDA' | 'ENTRADA'
) => {
  if (!produtosEmTransito) return 0
  return produtosEmTransito.reduce((prev, curr) => {
    if (curr.tipoProdutoId !== tipoProduto) return prev
    if (tipo === 'SAIDA' && curr.empresaDetentoraId === empresaId) return prev + curr.total
    if (tipo === 'ENTRADA' && curr.empresaTransitoId === empresaId) return prev + curr.total
    return prev
  }, 0)
}

const ContainerEstoque = styled.div<{ critico?: boolean }>(
  ({ critico }) => `
  border: 1px solid #F5F5F5;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 45%);
  border-radius: 8px;
  min-width: 100px;
  font-size: 1.2em;
  padding: 4px 0;
  .path {
    fill: #888888;
  }
  .flex {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .anticon {
    color: #888;
  }
  ${
    critico
      ? `
    background: #e25b5b;
    color: white;
    .anticon {
      color: white;
    }
  `
      : ''
  }
`
)

const EstoqueReal = ({ quantidade, critico }) => {
  return (
    <ContainerEstoque critico={critico && +quantidade < +critico}>
      <div>
        <CarryOutOutlined /> {+quantidade ? <strong>{quantidade}</strong> : '-'}
      </div>
      <div>
        <WarningOutlined /> {critico || '-'}
      </div>
    </ContainerEstoque>
  )
}
const EstoqueSistema = ({ quantidade, entrada, saida }) => {
  return (
    <ContainerEstoque>
      <div>
        <ShoppingCartOutlined /> {quantidade || '-'}
      </div>
      <div className="flex">
        <div>
          <ImportOutlined /> {entrada || '-'}
        </div>
        <div>
          <ExportOutlined /> {saida || '-'}
        </div>
      </div>
    </ContainerEstoque>
  )
}

// _eslint-disable-next-line_ @typescript-eslint/no-unused-vars
const LinksEstampador = () => {
  return (
    <Styles.Card title="Estampador">
      <Styles.Link to="/emplacamentos/estampagem">Estampagem</Styles.Link>
      <Styles.Link to="/lotes/recebimento">Recebimento de Lotes</Styles.Link>
    </Styles.Card>
  )
}

const Styles = {
  Card: styled(Card)`
    margin: 0 auto;
    max-width: 800px;
  `,
  Link: styled(Link)`
    display: block;
    margin: 4px 0;
  `,
}

export { Home }
