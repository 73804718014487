import { Button, Card, Input, Row, Select } from 'antd'
import React, { useState } from 'react'
import useAxios from 'axios-hooks'
import styled from '@emotion/styled'
import { TabelaProdutos } from '../common/seplac'
import { PERMISSOES } from '../common/Models'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { AuthStore } from '../auth/AuthStore'

export const Estoque = () => {
  usePermissaoUsuario([PERMISSOES.VISUALIZAR_ESTOQUE], { changeRoute: true })

  const [{ data: empresas }] = useAxios({
    url: '/empresas',
  })

  return (
    <div>
      <Card title="Estoque de produtos">
        <Filtro empresas={empresas}>
          <ListagemProdutos />
        </Filtro>
      </Card>
    </div>
  )
}

const Filtro = ({ empresas, children }) => {
  const { idEmpresaAtiva } = AuthStore.useState()
  const [empresaSelecionadaId, setEmpresaSelecionadaId] = useState(idEmpresaAtiva)
  const [codigoBarras, setCodigoBarras] = useState('')
  const [lote, setLote] = useState('')

  const optionEmpresa = (empresa) => (
    <Select.Option key={empresa.id} value={empresa.id}>
      {empresa.descricao}
    </Select.Option>
  )

  return (
    <div>
      <Styles.Row>
        <label> Empresa </label>
        <br />
        <Select
          style={{ width: 200 }}
          placeholder="Escolha a Empresa"
          defaultValue={empresaSelecionadaId || 0}
          onChange={(id) => setEmpresaSelecionadaId(id)}
        >
          {(empresas || []).map(optionEmpresa)}
        </Select>
      </Styles.Row>
      <Styles.Row>
        <label> Código de Barras </label>
        <br />
        <Input
          style={{ width: 200 }}
          value={codigoBarras}
          onChange={(e) => setCodigoBarras(e.target.value)}
          autoFocus
        />
      </Styles.Row>
      <Styles.Row>
        <label> Lote </label>
        <br />
        <Input style={{ width: 200 }} value={lote} onChange={(e) => setLote(e.target.value)} />
      </Styles.Row>
      <Styles.Row>
        <Button> Pesquisar </Button>
      </Styles.Row>
      <Styles.Row>{React.cloneElement(children, { empresaId: empresaSelecionadaId })}</Styles.Row>
    </div>
  )
}

const ListagemProdutos = ({ empresaId = null }) => {
  const [{ data: produtos }] = useAxios({
    url: '/produtos/estoque',
    params: {
      empresaId,
    },
  })

  if (!produtos) return null

  return <TabelaProdutos produtos={produtos}></TabelaProdutos>
}

const Styles = {
  Row: styled(Row)`
    margin-bottom: 10px;
  `,
}
