import { PrinterOutlined } from '@ant-design/icons'
import { Table, Button, Tooltip } from 'antd'
import { AuthStore } from 'auth/AuthStore'
import useAxios from 'axios-hooks'
import { format, parseISO } from 'date-fns'
import React from 'react'
import usePermissaoUsuario from '../../common/hooks/usePermissaoUsuario'
import { PERMISSOES } from '../../common/Models'
import { ComprovanteDevolucao } from './ComprovanteDevolucao'

export const ListaDevolucoes = () => {
  const { idEmpresaAtiva } = AuthStore.useState()

  const [{ data }] = useAxios(`/pedidos/devolucoes/${idEmpresaAtiva}`, { useCache: false })

  const [pedidoId, setPedidoId] = React.useState<number | null>(null)

  usePermissaoUsuario([PERMISSOES.DEVOLVER_BLANK, PERMISSOES.RECEBER_BLANKS_DEVOLVIDOS], {
    changeRoute: true,
  })

  return (
    <div>
      <Table
        columns={[
          { title: 'Id', dataIndex: 'id' },
          { title: 'Origem', render: (row) => <>{row?.empresaOrigem?.descricao}</> },
          { title: 'Destino', render: (row) => <>{row?.empresaDestino?.descricao}</> },
          {
            title: 'Data',
            render: (row) => <>{format(parseISO(row.createdAt), 'dd/MM/yyyy HH:mm')}</>,
          },
          { title: 'Usuário', render: (row) => <>{row.usuario.nome}</> },
          {
            title: '',
            render: (row) => (
              <Tooltip title="Imprimir Devolução">
                <Button icon={<PrinterOutlined />} onClick={() => setPedidoId(row.id)} />
              </Tooltip>
            ),
          },
        ]}
        dataSource={data || []}
      />
      <ComprovanteDevolucao pedidoId={pedidoId} onClose={() => setPedidoId(null)} />
    </div>
  )
}
