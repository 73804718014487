import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Lista } from './Lista'
import { Detalhe } from './Detalhe'
import { Recebimento } from './Recebimento'
import { Cadastro } from './Cadastro'

const Lotes = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/`} exact component={Lista} />
      <Route path={`${match.path}/cadastro`} exact component={Cadastro} />
      <Route path={`${match.path}/detalhe/:loteId`} exact component={Detalhe} />
      <Route path={`${match.path}/recebimento`} exact component={Recebimento} />
    </Switch>
  )
}

export default Lotes
