import React from 'react'
import { Button, Card, List, notification } from 'antd'
import { useParams, Link } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { Qr } from '../common/Qr'
import { PERMISSOES, ProdutoModel } from '../common/Models'

import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { Show } from '../common/Show'

const Detalhe = () => {
  usePermissaoUsuario([PERMISSOES.BAIXAR_BLANKS], { changeRoute: true })

  const { loteId } = useParams<{ loteId }>()
  const [{ data: lote }, fetchLote] = useAxios({
    url: `/lotes/${loteId}`,
    method: 'GET',
  })

  return (
    <Card title={`Lote: ${lote?.id} | Quantidade de Blanks: ${lote?.qtdBlanks}`}>
      <DownloadQrs />
      <Show condition={!lote?.produtos?.length}>
        <SalvaQrs fetchLote={fetchLote} />
      </Show>
      <ListaBlanks blanks={lote?.produtos ?? []} />
    </Card>
  )
}

const ListaBlanks = ({ blanks }: { blanks: ProdutoModel[] }) => {
  return (
    <>
      <List
        itemLayout="vertical"
        dataSource={blanks}
        renderItem={(blank) => <Blank blank={blank} />}
      />
    </>
  )
}

const DownloadQrs = () => {
  const { loteId } = useParams<{ loteId }>()
  const [{ loading }, download] = useAxios(
    {
      url: `/lotes/download-qrcodes/${loteId}`,
      method: 'GET',
      responseType: 'blob',
    },
    { manual: true }
  )

  async function handleClick() {
    const { data } = await download()
    _downloadBlob(data, loteId + '.tgz')
  }

  return (
    <Button loading={loading} onClick={handleClick}>
      Efetuar o download dos QRs
    </Button>
  )
}

const SalvaQrs = ({ fetchLote }) => {
  const { loteId } = useParams<{ loteId }>()
  const [{ loading }, createBlanks] = useAxios(
    {
      url: '/lotes/blanks',
      method: 'POST',
      data: { loteId },
    },
    { manual: true }
  )

  async function handleClick() {
    try {
      await createBlanks()
      await fetchLote()
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Ocorreu um erro ao salvar os QRs'
      notification.error({ message })
    }
  }

  return (
    <Button loading={loading} onClick={handleClick} style={{ marginLeft: 12 }}>
      Buscar os QRs no SERPRO e salvar
    </Button>
  )
}

const Blank = ({ blank }: { blank: ProdutoModel }) => {
  return (
    <List.Item key={blank.id} extra={<Qr qrCodeBase64={blank.qrCodeBase64} />}>
      <List.Item.Meta
        title={
          <>
            Serial: <Link to={`/produtos/${blank.id}`}>{blank.serial}</Link>
          </>
        }
      />
    </List.Item>
  )
}

function _downloadBlob(blob, name: string) {
  const blobUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = blobUrl
  link.download = name
  document.body.appendChild(link)
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  )
  document.body.removeChild(link)
}

export { Detalhe }
