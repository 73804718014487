import { PERMISSOES } from './Models'

export const checkPermissao = (
  permissoesNecessarias: PERMISSOES[],
  permissoes: PERMISSOES[],
  // _eslint-disable-next-line_ @typescript-eslint/no-unused-vars
  admin?: boolean
) => {
  return permissoesNecessarias.some((p) => permissoes?.includes(p))
}
