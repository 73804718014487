import { Button, Card, Descriptions, notification, Tabs, Tooltip, Spin } from 'antd'
import React from 'react'
import { SelectEmpresa } from 'common/seplac'
import styled from '@emotion/styled'
import { EmpresaModel, LoteModel, PedidoModel, PERMISSOES, ProdutoModel } from 'common/Models'
import { createSimpleStore } from 'react-simple-reducer'
import { BlanksScanner, LotesScanner } from '../../common/seplac'
import { format, parseISO } from 'date-fns'
import { DisplayFlex } from '../../common/DisplayFlex'
import { DeleteOutlined, DownOutlined } from '@ant-design/icons'
import AnimateHeight from 'react-animate-height'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import usePermissaoUsuario from '../../common/hooks/usePermissaoUsuario'
import { ComprovanteVenda } from './ComprovanteVenda'

const Store = createSimpleStore(
  {
    cliente: null as EmpresaModel | null,
    lotes: [] as LoteModel[],
    produtos: [] as ProdutoModel[],
    loading: false,
    pedidoCriado: null as PedidoModel | null,
  },
  {
    setCliente(state, cliente: EmpresaModel) {
      state.cliente = cliente
    },
    insertProduto(state, produto: ProdutoModel) {
      if (state.produtos.some((x) => x.id === produto.id)) return
      state.produtos.push(produto)
    },
    insertLote(state, lote: LoteModel) {
      if (state.lotes.some((x) => x.id === lote.id)) return
      state.lotes.push(lote)
    },
    removeLote(state, lote: LoteModel) {
      state.lotes = state.lotes.filter((x) => x.id !== lote.id)
    },
    removeProduto(state, produto: ProdutoModel) {
      state.produtos = state.produtos.filter((x) => x.id !== produto.id)
    },
    createPedidoStarted(state) {
      state.loading = true
    },
    createPedidoSuccess(state, venda: PedidoModel) {
      state.loading = false
      state.cliente = null
      state.produtos = []
      state.lotes = []
      state.pedidoCriado = venda
    },
    createPedidoError(state) {
      state.loading = false
    },
    resetPedidoCriado(state) {
      state.pedidoCriado = null
    },
  },
  {
    thunks: {
      createPedido() {
        return async (dispatch, getState) => {
          const { cliente, produtos, lotes } = getState()
          if (!cliente) return notification.error({ message: 'É necessário selecionar um cliente' })
          if (!produtos?.length && !lotes?.length) {
            return notification.error({ message: 'É necessário informar lotes ou produtos' })
          }
          dispatch(Store.actions.createPedidoStarted())
          try {
            const vendaCriada = await Axios.post('/pedidos/venda', {
              empresaDestinoId: cliente.id,
              produtos: _getProdutos(produtos, lotes),
            } as Partial<PedidoModel>).then((x) => x.data)
            notify.success('Pedido realizado com sucesso')
            dispatch(Store.actions.createPedidoSuccess(vendaCriada))
          } catch (e: any) {
            dispatch(Store.actions.createPedidoError())
            notify.error(e.response?.data?.message ?? 'Ocorreu um erro ao criar o pedido')
          }
        }
      },
    },
  }
)

function _getProdutos(produtos: ProdutoModel[], lotes: LoteModel[]) {
  const produtosLotes = lotes.reduce<ProdutoModel[]>(
    (prev, curr) => [...prev, ...curr.produtos],
    []
  )
  return [...produtos, ...produtosLotes]
}

export const VendaProdutos = () => {
  usePermissaoUsuario([PERMISSOES.VENDER_PRODUTOS], { changeRoute: true })

  const { Card, Botoes } = VendaProdutos

  const handleChangeTabs = (e) => {
    requestAnimationFrame(() => {
      if (e === '1') document?.getElementById('searchLotesScanner')?.focus()
      if (e === '2') document?.getElementById('searchBlanksScanner')?.focus()
    })
  }

  return (
    <Store.Provider>
      <Store.GetState>
        {({ loading }) => (
          <Spin spinning={loading} tip="Cadastrando venda e enviando produtos junto ao SERPRO">
            <Card title="Venda">
              <Cliente>
                <SelecionaCliente />
                <InformacoesCliente />
              </Cliente>
              <Produtos>
                <Tabs onChange={handleChangeTabs}>
                  <Tabs.TabPane tab="Lote" key="1">
                    <InsereLote />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Produto" key="2">
                    <InsereProduto />
                  </Tabs.TabPane>
                </Tabs>
                <ListaLotes>{(lote) => <Lote key={lote.id} lote={lote} />}</ListaLotes>
                <ListaProdutos>
                  {(produto) => <Produto key={produto.id} produto={produto} />}
                </ListaProdutos>
              </Produtos>
              <Botoes>
                <Cancelar />
                <CriarPedido />
              </Botoes>
            </Card>
          </Spin>
        )}
      </Store.GetState>
      <Comprovante />
    </Store.Provider>
  )
}
VendaProdutos.Card = styled(Card)`
  margin: 12px auto;
  max-width: 1024px;
`
VendaProdutos.Botoes = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  gap: 8px;
`

const Cliente = ({ children }) => {
  return (
    <Card>
      <h3>Cliente</h3>
      {children}
    </Card>
  )
}

const SelecionaCliente = () => {
  const dispatch = Store.useDispatch()

  function setEmpresaCliente(cliente: EmpresaModel) {
    dispatch(Store.actions.setCliente(cliente))
  }

  return (
    <SelectEmpresa
      onChangeEmpresa={setEmpresaCliente}
      style={{ width: 500 }}
      showSearch
      placeholder="Escolha o emplacador"
      tipoEmpresa="EMPLACADOR"
    />
  )
}

const InformacoesCliente = () => {
  const { cliente } = Store.useState()
  if (!cliente) return null
  return (
    <Descriptions layout="vertical">
      <Descriptions.Item label="Id">{cliente?.id}</Descriptions.Item>
      <Descriptions.Item label="Descrição">{cliente?.descricao}</Descriptions.Item>
      <Descriptions.Item label="CNPJ">{cliente?.cnpj}</Descriptions.Item>
    </Descriptions>
  )
}

const Produtos = ({ children }) => {
  return (
    <Card style={{ marginTop: 12 }}>
      <h3>Lotes e Produtos</h3>
      {children}
    </Card>
  )
}

const InsereLote = () => {
  const dispatch = Store.useDispatch()

  function handleScan(lote: LoteModel) {
    if (lote) dispatch(Store.actions.insertLote(lote))
  }

  return (
    <div>
      <LotesScanner onScan={handleScan} showProdutos={true} style={{ width: 500 }} />
    </div>
  )
}

const InsereProduto = () => {
  const dispatch = Store.useDispatch()

  function handleScan(produto: ProdutoModel) {
    dispatch(Store.actions.insertProduto(produto))
  }

  return (
    <div>
      <BlanksScanner onScan={handleScan} showProduto={false} style={{ width: 500 }} />
    </div>
  )
}

const ListaLotes = ({ children }: { children: (lote: LoteModel) => any }) => {
  const { lotes } = Store.useState()
  if (!lotes.length) return null
  return <>{lotes.map((l) => children(l))}</>
}

const ListaProdutos = ({ children }: { children: (produto: ProdutoModel) => any }) => {
  const { produtos } = Store.useState()
  if (!produtos.length) return null
  return <div style={{ marginTop: 12 }}>{produtos.map((p) => children(p))}</div>
}

const Lote = ({ lote }: { lote: LoteModel }) => {
  const { Layout, ProdutosContainer } = Lote
  const dispatch = Store.useDispatch()
  const [showProdutos, setShowProdutos] = React.useState(false)

  function handleClickDelete(e) {
    e.stopPropagation()
    dispatch(Store.actions.removeLote(lote))
  }

  return (
    <>
      <Layout onClick={() => setShowProdutos(!showProdutos)}>
        <div>
          <strong>Lote</strong>
        </div>
        <div>Id: {lote.id}</div>
        <div>Número: {lote.numero}</div>
        <div>Criado em: {format(parseISO(lote.createdAt), 'dd/MM/yyyy HH:mm')}</div>
        <div>Qtd. Blanks: {lote.qtdBlanks}</div>
        <div>
          {' '}
          <DownOutlined />{' '}
        </div>
        <div>
          <Tooltip title="Remover Lote da lista de envio">
            <Button shape="circle" icon={<DeleteOutlined />} onClick={handleClickDelete} />
          </Tooltip>
        </div>
      </Layout>
      <AnimateHeight height={showProdutos ? 'auto' : 0}>
        <ProdutosContainer>
          {lote.produtos.map((p) => (
            <ul key={p.id}>
              <li>
                <DisplayFlex spaceBetween={12}>
                  <div>Serial: {p.serial}</div>
                  <div>Tipo: {p.tipoProduto.descricao}</div>
                </DisplayFlex>
              </li>
            </ul>
          ))}
        </ProdutosContainer>
      </AnimateHeight>
    </>
  )
}
Lote.Layout = styled.div`
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
Lote.ProdutosContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ul {
    margin: 0;
  }
`

const Produto = ({ produto }: { produto: ProdutoModel }) => {
  const { Layout } = Produto
  const dispatch = Store.useDispatch()

  function handleClickDelete() {
    dispatch(Store.actions.removeProduto(produto))
  }

  return (
    <Layout>
      <strong>Produto</strong>
      <div>Serial: {produto.serial}</div>
      <div>Tipo: {produto.tipoProduto.descricao}</div>
      <div>Criado em: {format(parseISO(produto.createdAt), 'dd/MM/yyyy HH:mm')}</div>
      <div>Pertencente ao lote: {produto.loteId}</div>
      <div>
        <Tooltip title="Remover produto da lista de envio">
          <Button shape="circle" icon={<DeleteOutlined />} onClick={handleClickDelete} />
        </Tooltip>
      </div>
    </Layout>
  )
}
Produto.Layout = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
`

const Cancelar = () => {
  const history = useHistory()

  function handleClick() {
    history.goBack()
  }

  return (
    <Button onClick={handleClick} type="default" color="danger">
      Cancelar
    </Button>
  )
}

const CriarPedido = () => {
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.thunks.createPedido())
  }

  return (
    <Button onClick={handleClick} type="primary">
      Criar novo pedido
    </Button>
  )
}

const Comprovante = () => {
  const dispatch = Store.useDispatch()
  const { pedidoCriado } = Store.useState()

  function handleClose() {
    dispatch(Store.actions.resetPedidoCriado())
  }

  return <ComprovanteVenda pedidoId={pedidoCriado?.id} onClose={handleClose} />
}

const notify = {
  error: (message) => {
    notification.error({
      key: 'erroSubmit',
      message: message,
      onClick: () => notification.close('erroSubmit'),
    })
  },
  success: (message) => {
    notification.success({
      key: 'succesSubmit',
      message: message,
      onClick: () => notification.close('succesSubmit'),
    })
  },
}
