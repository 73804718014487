import React, { useState, useEffect } from 'react'
import { Tabs, Table, Input, Button, Modal, Form, notification } from 'antd'
import { createSimpleStore } from 'react-simple-reducer'
import useAxios from 'axios-hooks'
import { ColumnsType } from 'antd/lib/table'
import { EmpresaModel, PERMISSOES } from 'common/Models'
import { TextField, TextFieldInput } from '../common/TextField'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { format as formatCnpj } from '@fnando/cnpj'
import Axios from 'axios'
import { DisplayFlex } from '../common/DisplayFlex'
import { confirmPromise } from '../common/confirmPromise'
import { useHistory } from 'react-router-dom'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { CheckOutlined } from '@ant-design/icons'
import { parseISO, formatDistance } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Show } from '../common/Show'
import { format } from 'date-fns'
import styled from '@emotion/styled'

const Store = createSimpleStore(
  {
    empresas: [] as EmpresaModel[],
    empresa: null as EmpresaModel | null,
    loading: false,
  },
  {
    iniciaCriacaoOuEdicaoEmpresa(state, empresa: EmpresaModel) {
      state.empresa = empresa
    },
    interrompeCriacaoOuEdicaoEmpresa(state) {
      state.empresa = null
    },
    getEmpresasStarted(state) {
      state.loading = true
    },
    getEmpresasSuccess(state, empresas) {
      state.empresas = empresas
      state.loading = false
    },
    getEmpresasError(state) {
      state.loading = false
    },
    alteraEmpresaSuccess(state) {
      state.empresa = null
    },
    deleteEmpresaSuccess(state, empresa: EmpresaModel) {
      state.empresas = state.empresas.filter((e) => e.id !== empresa.id)
    },
  },
  {
    thunks: {
      getEmpresas() {
        return async (dispatch) => {
          try {
            dispatch(Store.actions.getEmpresasStarted())
            const empresas = await Axios.get('/empresas', {
              params: {
                tipoEmpresa: 'EMPLACADOR',
                emAuditoria: 'true',
              },
            }).then((x) => x?.data)
            dispatch(Store.actions.getEmpresasSuccess(empresas))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao buscar as empresas'
            notification.error({ message })
            dispatch(Store.actions.getEmpresasError())
          }
        }
      },
      deleteEmpresa(empresa) {
        return async (dispatch) => {
          const confirmation = await confirmPromise({
            content:
              'Empresa será excluída. Todos os serviços relacionados à empresa serão mantidos. Deseja continuar?',
          })
          if (!confirmation) return
          try {
            await Axios.delete(`/empresas/${empresa.id}`).then((x) => x?.data)
            dispatch(Store.actions.deleteEmpresaSuccess(empresa))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao excluir a empresa'
            notification.error({ message })
          }
        }
      },
    },
  }
)

export const GerenciaEmpresas = () => {
  usePermissaoUsuario([PERMISSOES.GERENCIAR_EMPRESAS], { changeRoute: true })

  const handleChangeTabs = (e) => {
    requestAnimationFrame(() => {
      document?.getElementById('nomeEmpresaSearch')?.focus()
    })
  }

  return (
    <Store.Provider>
      <Tabs onChange={handleChangeTabs}>
        <Tabs.TabPane key="CADASTRADAS" tab="Estampadoras cadastradas">
          <EstampadorasCadastradas />
        </Tabs.TabPane>
        <Tabs.TabPane key="NOVA" tab="Cadastrar nova estampadora">
          <CadastrarNovaEstampadora />
        </Tabs.TabPane>
      </Tabs>
      <EditarEmpresa />
      <Init />
    </Store.Provider>
  )
}

const Init = () => {
  const dispatch = Store.useDispatch()
  useEffect(() => {
    dispatch(Store.thunks.getEmpresas())
  }, [])
  return null
}

const EstampadorasCadastradas = () => {
  const { empresas, loading } = Store.useState()
  const dispatch = Store.useDispatch()
  const history = useHistory()

  function handleClickEditar(empresa) {
    // dispatch(Store.actions.iniciaCriacaoOuEdicaoEmpresa(empresa))
    history.push('/empresas/' + empresa.id)
  }

  function handleClickExcluir(empresa) {
    dispatch(Store.thunks.deleteEmpresa(empresa))
  }

  const columns: ColumnsType<any> = [
    { title: 'Id', render: (record: EmpresaModel) => record.id },
    { title: 'CNPJ', render: (record: EmpresaModel) => record.cnpj, width: 160 },
    {
      title: 'Empresa',
      render: (record: EmpresaModel) => (
        <>
          {record.descricao}
          <br />
          <small>{record.municipio?.nome}</small>
          <br />
          <small style={{ color: 'red' }}>{record.auditoriaPendente && 'Em Auditoria'}</small>
        </>
      ),
    },
    {
      title: 'Método de cobrança',
      render: (record: EmpresaModel) => record.infoCobranca?.sistema,
    },
    {
      title: 'Emite Nota Fiscal via seplac.net',
      render: (record: EmpresaModel) => (record.emiteNotaFiscal ? <CheckOutlined /> : null),
    },
    {
      title: '',
      render: (record: EmpresaModel) => (
        <DisplayFlex spaceBetween={12}>
          <Button onClick={() => handleClickEditar(record)}>Editar</Button>
          <Button onClick={() => handleClickExcluir(record)}>Excluir</Button>
        </DisplayFlex>
      ),
    },
  ]

  return (
    <div>
      <Table columns={columns} dataSource={empresas} loading={loading} rowKey="id" />
    </div>
  )
}

const CadastrarNovaEstampadora = () => {
  const { Fieldset } = CadastrarNovaEstampadora
  const [nome, setNome] = useState('')
  const [{ data, loading }, refetch] = useAxios(
    { url: '/empresas/estampadoras/denatran', params: { nome } },
    { manual: true }
  )
  const dispatch = Store.useDispatch()

  function handleSearch() {
    void refetch()
  }

  function handleClickSalvar(empresa) {
    dispatch(
      Store.actions.iniciaCriacaoOuEdicaoEmpresa({
        ...empresa,
        descricao: empresa.nomeFantasia || empresa.nome,
        cnpj: formatCnpj(empresa.cnpj),
        tipoEmpresa: 'EMPLACADOR',
        municipioId: empresa.endereco.municipio.id,
      })
    )
  }

  const columns: ColumnsType<any> = [
    { title: 'Id', render: (record) => record.id },
    { title: 'CNPJ', render: (record) => record.cnpj },
    { title: 'Nome Fantasia', render: (record) => record.nomeFantasia },
    { title: 'Nome', render: (record) => record.nome },
    { title: 'Município', render: (record) => record.endereco.municipio.nome },
    {
      title: '',
      render: (record) => (
        <Button onClick={() => handleClickSalvar(record)} size="small">
          Salvar
        </Button>
      ),
    },
  ]

  return (
    <div>
      <Fieldset>
        <EnviarNovoCertificado />
      </Fieldset>
      <Fieldset>
        <label>
          Nome da empresa
          <Input.Search
            id="nomeEmpresaSearch"
            autoFocus
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            onSearch={handleSearch}
          />
        </label>
        {data && (
          <Table columns={columns} dataSource={data} loading={loading} size="small" rowKey="id" />
        )}
      </Fieldset>
    </div>
  )
}
CadastrarNovaEstampadora.Fieldset = styled.div`
  margin-top: 12px;
  padding: 12px;
  border: 1px solid #e4e4e4;
`

const EditarEmpresa = () => {
  const { empresa } = Store.useState()
  const dispatch = Store.useDispatch()
  const isModalAtivo = !!empresa
  const formularioSchema = yup.object().shape({
    descricao: yup.string().required('Campo obrigatório'),
  })

  const formik = useFormik({
    initialValues: { ...empresa },
    validationSchema: formularioSchema,
    onSubmit: () => {
      return undefined
    },
    enableReinitialize: true,
  })
  const [{ loading }, post] = useAxios(
    { url: '/empresas', method: 'POST', data: formik.values },
    { manual: true }
  )

  function handleCancel() {
    dispatch(Store.actions.interrompeCriacaoOuEdicaoEmpresa())
  }

  async function handleOk() {
    try {
      await post()
      dispatch(Store.thunks.getEmpresas())
      dispatch(Store.actions.alteraEmpresaSuccess())
      notification.success({ message: 'Empresa criada/alterada com sucesso' })
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Erro ao criar/alterar empresa'
      notification.error({ message })
    }
  }

  return (
    <Modal
      visible={isModalAtivo}
      onCancel={handleCancel}
      title="Criar ou Editar Estampadora"
      onOk={handleOk}
      okText="Salvar"
      confirmLoading={loading}
    >
      <FormikProvider value={formik}>
        <Form>
          <label>
            Id
            <TextField name="id" disabled />
          </label>
          <label>
            Nome da Empresa
            <TextField name="descricao" />
          </label>
          <label>
            CNPJ
            <TextField name="cnpj" disabled />
          </label>
        </Form>
      </FormikProvider>
    </Modal>
  )
}

const EnviarNovoCertificado = () => {
  const [senha, setSenha] = React.useState('')
  const [validade, setValidade] = React.useState('')
  const ref = React.useRef<HTMLInputElement>(null)
  const dispatch = Store.useDispatch()
  const { empresa } = Store.useState()

  const [{ loading }, reqEnviaCertificado] = useAxios(
    {
      url: `/empresas/cria-via-certificado`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    {
      manual: true,
    }
  )

  function handleChangeSenha(e) {
    setSenha(e.target.value)
  }

  function handleChangeValidade(e) {
    setValidade(e.target.value)
  }

  async function enviaCertificado() {
    const data = new FormData()
    const file = ref.current?.files?.[0]
    if (!file) return notification.error({ message: 'Selecione o certificado' })
    if (!senha) return notification.error({ message: 'Informe a senha' })
    if (!validade) return notification.error({ message: 'Informe a validade' })
    data.append('cert', file)
    data.append('validade', validade.split('/').reverse().join('-') + 'T12:00:00.000Z')
    data.append('senha', senha)
    try {
      const responseEnviaCertificado = await reqEnviaCertificado({ data }).then((x) => x.data)
      notification.success({
        message: `Empresa criada com sucesso. Id: ${responseEnviaCertificado.id}`,
      })
      setSenha('')
      setValidade('')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao criar a empresa'
      notification.error({ message })
    }
  }

  return (
    <div>
      <InfoValidadeCertificado empresa={empresa} />

      <label>
        Selecione o certificado para criar empresa
        <br />
        <input ref={ref} type="file" accept=".pfx" />
      </label>
      <br />
      <br />

      <label>
        Senha do certificado
        <br />
        <Input value={senha} onChange={handleChangeSenha} style={{ width: 400 }} />
      </label>
      <br />
      <br />

      <label>
        Data de expiração
        <br />
        <TextFieldInput
          mask="99/99/9999"
          value={validade}
          onChange={handleChangeValidade}
          style={{ width: 400 }}
        />
      </label>
      <br />
      <br />

      <Button loading={loading} onClick={enviaCertificado}>
        Enviar
      </Button>
    </div>
  )
}

const InfoValidadeCertificado = ({ empresa }: { empresa: EmpresaModel | null }) => {
  const dataValidadeCertificado = empresa?.dataValidadeCertificado
    ? parseISO(empresa.dataValidadeCertificado)
    : null

  const certVenceEm = dataValidadeCertificado
    ? formatDistance(dataValidadeCertificado, new Date(), { locale: ptBR })
    : null

  const dataExpiracao = dataValidadeCertificado
    ? format(dataValidadeCertificado, 'dd/MM/yyyy HH:mm')
    : null

  if (!dataValidadeCertificado) return null
  return (
    <>
      <Show condition={dataValidadeCertificado > new Date()}>
        <span>
          Certificado Expira:{' '}
          <strong>
            {dataExpiracao} ({certVenceEm})
          </strong>
        </span>
      </Show>
      <Show condition={dataValidadeCertificado < new Date()}>
        <span>
          Certificado Expirado: <strong>{dataExpiracao}</strong>
        </span>
      </Show>
      <br />
    </>
  )
}
