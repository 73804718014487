import { Link, useHistory } from 'react-router-dom'
import { Select, Menu, Dropdown } from 'antd'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from '@emotion/styled'
import { AppRoutes } from './AppRoutes'
import { getUsuarioLogado, getEmpresaAtiva, AuthStore } from './auth/AuthStore'
import MenuContainer from './Menu'
import { ptBR } from 'date-fns/locale'
import { format, parseISO, formatDistance } from 'date-fns'
import { LockOutlined, EllipsisOutlined, WarningOutlined } from '@ant-design/icons'
import { PERMISSOES, EmpresaModel } from './common/Models'
import usePermissaoUsuario from './common/hooks/usePermissaoUsuario'
import useWindowDimensions from 'common/getWindowDimensions'
import { Show } from 'common/Show'

export const AppContainer = (props) => {
  const { token } = AuthStore.useState()

  if (!token) props.history.push('/login')

  const { MenuUsuarioLayout } = AppContainer

  const { width } = useWindowDimensions()

  const [visible, setVisible] = useState(false)
  return (
    <Styles.AppContainer>
      <Nav toggleSideMenu={() => setVisible(!visible)} />
      <Show condition={width < 600}>
        <MenuUsuarioLayout>
          <strong>Empresa: &nbsp; </strong>
          <SelecaoEmpresa />
        </MenuUsuarioLayout>
      </Show>

      <Styles.ContentContainer>
        <MenuContainer visible={visible} setVisible={setVisible} />
        <Styles.Container>
          <AppRoutes {...props} />
        </Styles.Container>
      </Styles.ContentContainer>
    </Styles.AppContainer>
  )
}

AppContainer.MenuUsuarioLayout = styled.div`
  display: flex;
  place-content: flex-end;
  margin-top: 7px;

  strong {
    align-self: center;
  }

  @media (max-width: 800px) {
    place-content: flex-start;
    margin: 9px 15px;
    width: 100%;
  }
`

const AlertaCertificado = () => {
  const { ContainerMenu } = AlertaCertificado
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const certVenceEm = !empresaAtiva?.dataValidadeCertificado
    ? null
    : formatDistance(parseISO(empresaAtiva.dataValidadeCertificado), new Date(), { locale: ptBR })

  const dataExpiracao = !empresaAtiva?.dataValidadeCertificado
    ? null
    : format(parseISO(empresaAtiva?.dataValidadeCertificado), 'dd/MM/yyyy')

  const regexNum = /^\d/
  let diasParaVencimento
  if (certVenceEm) {
    const num = certVenceEm.match(regexNum)
    if (num) diasParaVencimento = num[0]
  }

  if (!empresaAtiva) return null

  return (
    <Menu>
      <Styles.ExpirationLayout>
        <Show
          condition={
            parseISO(empresaAtiva.dataValidadeCertificado) > new Date() && diasParaVencimento <= 7
          }
        >
          <ContainerMenu>
            <span style={{ lineHeight: 'normal', marginLeft: '15px' }}>
              <WarningOutlined style={{ color: '#ff9900' }} />
              Certificado Expira:{' '}
              <strong>
                {dataExpiracao} ({certVenceEm})
              </strong>
            </span>
          </ContainerMenu>
        </Show>
        <Show condition={parseISO(empresaAtiva.dataValidadeCertificado) < new Date()}>
          <ContainerMenu>
            <span style={{ lineHeight: 'normal', marginLeft: '15px' }}>
              <WarningOutlined style={{ color: '#ff3333' }} />
              Certificado Expirado: <strong>{dataExpiracao}</strong>
            </span>
          </ContainerMenu>
        </Show>
      </Styles.ExpirationLayout>
    </Menu>
  )
}

AlertaCertificado.ContainerMenu = styled.div`
  width: 304px;
  box-shadow: 1px 1px 4px #b3b3b3;
  margin-top: -5px;
  padding: 7px;
  margin-left: 7px;
`

function Nav({ toggleSideMenu }) {
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const { width } = useWindowDimensions()

  const MenuHamburguer = ({ onClick }) => (
    <div onClick={onClick} className="hamburguer" style={{ cursor: 'pointer' }}>
      <i style={{ fontSize: '2em' }} className="fas fa-bars"></i>
    </div>
  )

  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const certVenceEm = !empresaAtiva?.dataValidadeCertificado
    ? null
    : formatDistance(parseISO(empresaAtiva.dataValidadeCertificado), new Date(), { locale: ptBR })

  const dataExpiracao = !empresaAtiva?.dataValidadeCertificado
    ? null
    : format(parseISO(empresaAtiva?.dataValidadeCertificado), 'dd/MM/yyyy')

  const regexNum = /^\d/
  let diasParaVencimento
  if (certVenceEm) {
    const num = certVenceEm.match(regexNum)
    if (num) diasParaVencimento = num[0]
  }

  if (!usuarioLogado) {
    return (
      <Styles.Nav>
        <MenuHamburguer onClick={toggleSideMenu} />
        <div className="menu">SEPLAC.NET</div>
        <div className="superior-direito">
          <Link to="/login">
            Efetuar Login <LockOutlined />
          </Link>
        </div>
      </Styles.Nav>
    )
  }

  const dataValidadeCertificado = parseISO(empresaAtiva?.dataValidadeCertificado ?? '3000-01-01')

  return (
    <Styles.Nav>
      <MenuHamburguer onClick={toggleSideMenu} />

      <div className="menu">
        SEPLAC.NET {empresaAtiva?.auditoriaPendente && ' - Auditoria em Andamento'}
      </div>
      <div className="superior-direito">
        <Show condition={width < 600}>
          <Show condition={dataValidadeCertificado > new Date() && diasParaVencimento <= 7}>
            <Dropdown overlay={<AlertaCertificado />} placement="bottomLeft" arrow>
              <Styles.InfoNav>
                {' '}
                <WarningOutlined style={{ fontSize: '19px', color: '#ff9900' }} />{' '}
              </Styles.InfoNav>
            </Dropdown>
          </Show>
          <Show condition={dataValidadeCertificado < new Date()}>
            <Dropdown overlay={<AlertaCertificado />} placement="bottomLeft" arrow>
              <Styles.InfoNav>
                {' '}
                <WarningOutlined style={{ fontSize: '19px', color: '#ff3333' }} />{' '}
              </Styles.InfoNav>
            </Dropdown>
          </Show>
        </Show>

        <Show condition={width >= 600}>
          <Styles.ExpirationLayout>
            <Show condition={dataValidadeCertificado > new Date() && diasParaVencimento <= 7}>
              <div>
                <span style={{ marginLeft: '25px' }}>
                  <WarningOutlined style={{ color: '#ff9900' }} />
                  Certificado Expira:{' '}
                  <strong>
                    {dataExpiracao} ({certVenceEm})
                  </strong>
                </span>
              </div>
            </Show>
            <Show condition={dataValidadeCertificado < new Date()}>
              <span style={{ marginLeft: '25px' }}>
                <WarningOutlined style={{ color: '#ff3333' }} />
                Certificado Expirado: <strong>{dataExpiracao}</strong>
              </span>
            </Show>
          </Styles.ExpirationLayout>

          <SelecaoEmpresa />

          <Styles.InfoNav> {usuarioLogado.nome} </Styles.InfoNav>
        </Show>

        <Dropdown overlay={<MenuUsuario />} placement="bottomLeft" arrow>
          <Styles.InfoNav>
            {' '}
            <EllipsisOutlined />{' '}
          </Styles.InfoNav>
        </Dropdown>
        {/* <Button title={'Sair'} icon={<PoweroffOutlined />} onClick={handleClickLogout}>
          <strong>Sair</strong>
        </Button> */}
      </div>
    </Styles.Nav>
  )
}

const MenuUsuario = () => {
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const usuarioPossuiPermissaoVisualizarTelaEmpresa = usePermissaoUsuario([
    PERMISSOES.GERENCIAR_USUARIOS,
    PERMISSOES.ATUALIZAR_CERTIFICADO,
  ])

  const dispatch = AuthStore.useDispatch()

  function handleClickLogout() {
    dispatch(AuthStore.actions.logout())
  }

  return (
    <Menu>
      <Menu.Item>
        <Link to="/usuarios/altera-senha">Alterar senha</Link>
      </Menu.Item>
      {!usuarioPossuiPermissaoVisualizarTelaEmpresa ? null : (
        <Menu.Item>
          <Link to={`/empresas/${empresaAtiva?.id}`}>Gerenciar empresa</Link>
        </Menu.Item>
      )}
      <Menu.Item onClick={handleClickLogout}>Deslogar</Menu.Item>
    </Menu>
  )
}

const SelecaoEmpresa = () => {
  const dispatch = AuthStore.useDispatch()
  const { empresas, idEmpresaAtiva } = AuthStore.useState()
  const admin = AuthStore.useSelector(getUsuarioLogado)?.admin
  const history = useHistory()

  useEffect(() => {
    if (empresas.length && !idEmpresaAtiva) {
      dispatch(AuthStore.actions.setEmpresaAtiva(empresas[0].id))
    }
  }, [empresas, idEmpresaAtiva])

  useEffect(() => {
    if (idEmpresaAtiva) axios.defaults.headers.common.Empresa = idEmpresaAtiva
  }, [idEmpresaAtiva])

  const optDefault = [{ id: 0, descricao: 'Escolha uma empresa' }]

  const onChangeEmpresaAtiva = (idEmpresaAtiva) => {
    if (idEmpresaAtiva === optDefault[0].id) return
    dispatch(AuthStore.actions.setEmpresaAtiva(idEmpresaAtiva))
    if (admin) {
      history.push('')
    } else {
      history.push('/emplacamentos/estampagem')
    }
  }

  const filterEmpresas = (inputValue, option) => {
    if (!isNaN(inputValue)) return option.value === +inputValue
    return option.children[2].toUpperCase().includes(inputValue.toUpperCase())
  }

  return (
    <div>
      <Select
        showSearch
        filterOption={filterEmpresas}
        style={{ width: 218 }}
        placeholder="Escolha uma cidade"
        notFoundContent="Não encontrado"
        value={idEmpresaAtiva || optDefault[0].id}
        onChange={onChangeEmpresaAtiva}
      >
        {optDefault.concat(empresas).map((e) => (
          <Select.Option disabled={e.id === 0} key={e.id} value={e.id}>
            {e.id} - {e.descricao}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

const SIZE_NAV = '48px'
const Styles = {
  AppContainer: styled('div')`
    height: 100%;
  `,
  ExpirationLayout: styled('div')`
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-right: 40px;

    .anticon {
      // color:
      font-size: 20px;
      margin-right: 6px;
      vertical-align: top;
    }

    .containerMenu {
      padding-bottom: 7px;
    }
  `,
  ContentContainer: styled('div')`
    min-height: calc(100% - ${SIZE_NAV});
    position: relative;
  `,
  Nav: styled('div')`
    width: 100%;
    height: ${SIZE_NAV};
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    .superior-direito,
    .hamburguer,
    .menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & > * {
        margin-right: 12px;
      }
    }
    .hamburguer {
      margin-left: 5px;
    }
    .menu {
      margin-left: 24px;
      font-size: 1.3em;
      font-weight: bold;
      a {
        text-decoration: underline;
      }
    }
  `,
  InfoNav: styled('div')`
    padding: 4px 12px;
    /* margin-left: 12px; */
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: white;
  `,
  Container: styled('div')`
    padding: 15px;

    @media (max-width: 800px) {
      padding: 13px;
    }
  `,
}
