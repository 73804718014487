import React, { useState } from 'react'
import { Card, Button, Select, notification } from 'antd'
import { BlanksScanner } from '../common/seplac'
import { Show } from '../common/Show'
import useAxios from 'axios-hooks'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { PERMISSOES, ProdutoModel } from '../common/Models'

const Inutilizacao = () => {
  usePermissaoUsuario([PERMISSOES.INUTILIZAR_BLANKS], { changeRoute: true })

  const [serial, setSerial] = useState<ProdutoModel | null>(null)
  const [motivo, setMotivo] = useState('')
  const [{ loading }, reqInutilizacao] = useAxios(
    {
      method: 'POST',
      url: '/produtos/inutilizacao',
      data: { serial: serial?.serial, motivo },
    },
    { manual: true }
  )

  function handleScan(value) {
    setSerial(value)
  }

  async function inutilizarBlank() {
    try {
      if (!motivo) return notification.error({ message: 'Informe o motivo' })
      if (!serial) return notification.error({ message: 'Informe o blank' })

      await reqInutilizacao()

      notification.success({ message: 'Inutilização informada ao detran com sucesso' })
      setSerial(null)
      setMotivo('')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao inutilizar o blank'
      notification.error({ message })
    }
  }

  return (
    <Card>
      <label>
        Blank
        <BlanksScanner onScan={handleScan} showProduto={false} />
      </label>
      <Show condition={serial}>
        <Card>
          Serial do produto: <strong>{serial?.serial}</strong>
          <br />
        </Card>
        <br />
      </Show>
      <label>
        Motivo da inutilização
        <br />
        <Select value={motivo} onChange={setMotivo} style={{ width: '100%' }} size="large">
          <Select.Option value="">
            <em>Selecione o motivo</em>
          </Select.Option>
          <Select.Option value="BLANK_DANIFICADO">Blank Danificado</Select.Option>
          <Select.Option value="BLANK_EXTRAVIADO">Blank Extraviado</Select.Option>
          <Select.Option value="BLANK_ROUBADO_FURTADO">Blank Roubado/Furtado</Select.Option>
          <Select.Option value="ERRO_OPERACIONAL">Erro Operacional</Select.Option>
        </Select>
      </label>
      <Button onClick={inutilizarBlank} loading={loading}>
        Inutilizar produto
      </Button>
    </Card>
  )
}

export { Inutilizacao }
