import React from 'react'
import * as qrcode from 'qrcode'

export const Qr = ({ qrCodeBase64 }) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  React.useEffect(() => {
    if (!canvasRef.current || !qrCodeBase64) return
    const qr = qrCodeBase64
    // 'NWJhY2Y1ZWMwMgALAAxFhBlBFBBBNNZVBIAo0umJVwKjr2BwgvPLtNhJZiOdDivIx3KJPnkPF1lB9xaLtCzsUlqIPMQUwU2JERj17HoJAPmHe72Xu3gsSeE+eUGwvng7ENFaVT1TD+J3DpYqcgKCJrB6yLqx16FPww0UB5jorey2RBT95yjGBRxMqK7rxpkEivWz7jhKvTfg5jcbkm5/HvvqR2arZP6WrGEkD0quEJr4u3OeC6Spw/Xmv2ZjDB7pFBj35VMvfYoggSYVa1Q6iRBREkm87E/oLPm3BPEY4WGk7cKLVRVOHLwVUaRlDNVb0LoGXvQEGhPO6boPmEwVTnn9GwfruBWsk7wCfqRppG9JZYpNAeKhgdKU'
    qrcode.toCanvas(canvasRef.current, [{ data: _base64ToArrayBuffer(qr), mode: 'byte' }], {
      errorCorrectionLevel: 'L',
      width: 205,
      margin: 0,
    })
  }, [qrCodeBase64])

  return <canvas ref={canvasRef} />
}

function _base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}
