import { Input, notification } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { ProdutoModel } from '../Models'
import { Show } from '../Show'
import useAxios from 'axios-hooks'
import Modal from 'antd/lib/modal/Modal'

interface BlanksScannerType extends React.HTMLAttributes<HTMLDivElement> {
  onScan: (produto: ProdutoModel) => any
  showProduto?: boolean
  showEmpresa?: boolean
  showLote?: boolean
  empresaId?: number
  disabled?: boolean
}
const BlanksScanner = ({
  onScan,
  showProduto = true,
  showEmpresa = false,
  showLote = false,
  empresaId,
  disabled = false,
  ...props
}: BlanksScannerType) => {
  const { getEncodedValue, Container, Table } = BlanksScanner
  const [valorLeituraQr, setValorLeituraQr] = React.useState('')
  const [isModalEnviarSerialVioVisible, setIsModalEnviarSerialVioVisible] = React.useState(false)
  const [serialVio, setSerialVio] = React.useState('')
  const [{ data: produto, loading }, reqProduto] = useAxios<ProdutoModel>('produtos/codigo', {
    manual: true,
  })

  const ref = React.useRef(null as any)
  const amountOfKeyStrokes = React.useRef(0)
  const timeoutResetKeyStrokes = React.useRef(null as any)

  const handleSubmit = async (value) => {
    if (!value) return
    try {
      const produto = await reqProduto({
        params: {
          valor: getEncodedValue(value),
          serial: serialVio,
          showEmpresa,
          showLote,
          empresaId,
        },
      }).then((x) => x?.data)
      setValorLeituraQr('')
      setSerialVio('')
      setIsModalEnviarSerialVioVisible(false)
      onScan(produto)
    } catch (error: any) {
      if (isModalEnviarSerialVioVisible) {
        setSerialVio('')
        setValorLeituraQr('')
        setIsModalEnviarSerialVioVisible(false)
        const message = error.response?.data?.message ?? 'Ocorreu um erro ao buscar o blank'
        return notification.error({ message })
      }
      setSerialVio('')
      setIsModalEnviarSerialVioVisible(true)
    }
  }

  const handleKeyDown = () => {
    amountOfKeyStrokes.current++
    clearTimeout(timeoutResetKeyStrokes.current)
    timeoutResetKeyStrokes.current = setTimeout(() => {
      if (amountOfKeyStrokes.current > 10) void handleSubmit(ref.current.input.value)
      amountOfKeyStrokes.current = 0
    }, 300)
  }

  return (
    <Container {...props}>
      <Input.Search
        id="searchBlanksScanner"
        autoFocus
        enterButton="Consultar"
        onChange={(e) => setValorLeituraQr(e.target.value)}
        value={valorLeituraQr}
        size="large"
        placeholder="Serial do Blank"
        onSearch={(value, event: any) => {
          if (event.keyCode !== 13) void handleSubmit(value)
        }}
        style={{ margin: '0 0 20px 0' }}
        loading={loading}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        ref={ref}
        className="input-blank-scanner"
      />
      <Show condition={showProduto && produto}>
        <Table cellpadding={4}>
          <thead>
            <tr>
              <td>
                <div>Serial</div>
              </td>
              <td>
                <div>Tipo de Produto</div>
              </td>
              <Show condition={showEmpresa}>
                <td>
                  <div>Empresa</div>
                </td>
              </Show>
              <Show condition={showLote}>
                <td>
                  <div>Lote</div>
                </td>
              </Show>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{produto?.serial}</td>
              <td>{produto?.tipoProduto.descricao}</td>
              <Show condition={showEmpresa}>
                <td>{produto?.empresaDetentora?.descricao}</td>
              </Show>
              <Show condition={showLote}>
                <td>{produto?.lote?.id}</td>
              </Show>
            </tr>
          </tbody>
        </Table>
      </Show>
      <Modal
        visible={isModalEnviarSerialVioVisible}
        title="Informe o serial lido pelo VIO"
        onCancel={() => setIsModalEnviarSerialVioVisible(false)}
        footer={null}
      >
        <p>Estamos aperfeiçoando o leitor de QR!</p>
        <Input.Search
          size="large"
          value={serialVio}
          onChange={(e) => setSerialVio(e.target.value)}
          enterButton="Consultar"
          loading={loading}
          onSearch={async () => await handleSubmit(valorLeituraQr)}
        />
      </Modal>
    </Container>
  )
}
BlanksScanner.getEncodedValue = (value: string) => {
  const valueSanitized = value
    .split('')
    .filter((x) => x.charCodeAt(0) <= 255)
    .join('')
  return btoa(valueSanitized)
}
BlanksScanner.getHashAndSerialFromValue = (value: string): { hash; serial } => {
  if (!isNaN(+value) && value.length === 15) {
    return { hash: null, serial: value }
  }
  const hash = BlanksScanner.getHashFromQrCodeData(value)
  return { hash, serial: null }
}
BlanksScanner.getHashFromQrCodeData = (qrCodeBase64) => {
  // prettier-ignore
  const bytesToIgnore = [0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 39, 47, 58, 59, 63, 73, 79, 85, 89, 91, 92, 93, 94, 96, 97, 110, 121, 123, 124, 125, 126, 128, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 142, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 158, 159, 160, 168, 180, 184, 199, 204, 205, 211, 213, 220, 221, 228, 231, 240, 241, 253]
  return qrCodeBase64
    .split('')
    .map((x) => x.charCodeAt(0))
    .filter((x) => x <= 255)
    .filter((x) => !bytesToIgnore.includes(x))
    .map((x) => BlanksScanner.replaceBytes(x))
    .join(',')
}
BlanksScanner.replaceBytes = (byte) => {
  const str = String.fromCharCode(byte)
  const specialChars = 'ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïñòóôõöùúûüý'
  if (!specialChars.includes(str)) return byte
  const newStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  return newStr.charCodeAt(0)
}
BlanksScanner.Container = styled('div')``
BlanksScanner.Table = styled('table')`
  width: 100%;
  tbody {
    td {
      padding: 2px 8px;
    }
  }
  thead {
    td {
      font-size: 0.9em;
      div {
        background: #eaeaea;
        padding: 2px 6px;
        margin: 0 2px;
      }
    }
  }
`

export default BlanksScanner
