import { Button, Input, Modal, notification } from 'antd'
import React, { useEffect } from 'react'
import useAxios from 'axios-hooks'
import { SERVICOS, ProdutoModel, TIPOS_PRODUTOS } from '../../common/Models'
import { ListaPedidosStore } from './ListaPedidosStore'
import { Show } from '../../common/Show'
import { BlanksScanner } from '../../common/seplac'
import { DisplayFlex } from '../../common/DisplayFlex'
import { DeleteOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { RenderPlaca } from './Components/RenderPlaca'
import { AuthStore, getEmpresaAtiva } from '../../auth/AuthStore'
import { ImageIcon } from '../../assets/image-icon'

export const Estampar = () => {
  const { Layout, Info, ModalEstampar } = Estampar
  const { pedidoAtivo, modalVisible } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()
  const pedidoId = pedidoAtivo?.id
  const codigoAutorizacaoDenatran = pedidoAtivo?.emplacamento?.codigoAutorizacaoDenatran
  const { idEmpresaAtiva } = AuthStore.useState()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  const emiteNotaFiscal = AuthStore.useSelector(getEmpresaAtiva)?.emiteNotaFiscal

  const [{ loading: loadingIniciarEstampagem }, reqIniciarEstampagem] = useAxios(
    {
      method: 'POST',
      url: '/emplacamentos/iniciar-estampagem',
      data: {
        pedidoId,
        produtos: pedidoAtivo?.produtos?.map((p) => ({
          tipoProdutoId: p.tipoProdutoId,
          id: p.id,
        })),
      },
    },
    { manual: true }
  )

  const [{ loading: loadingEncerrarEstampagem }, reqEncerrarEstampagem] = useAxios(
    {
      method: 'POST',
      url: '/emplacamentos/encerrar-estampagem',
      data: { pedidoId, notaFiscal: pedidoAtivo?.emplacamento?.numeroNF },
    },
    { manual: true }
  )

  function closeModal() {
    dispatch(ListaPedidosStore.actions.closeModal())
  }

  async function iniciarEstampagem() {
    if (pedidoAtivo?.servicoId === SERVICOS.PAR_DE_PLACAS && pedidoAtivo.produtos.length !== 2) {
      return notification.error({ message: 'Informe os blanks antes de iniciar a estampagem' })
    }
    if (!pedidoAtivo?.produtos.length)
      return notification.error({ message: 'Informe o blank antes de iniciar a estampagem' })
    try {
      await reqIniciarEstampagem()
      dispatch(ListaPedidosStore.thunks.getPedidos(idEmpresaAtiva))
      closeModal()
      notification.success({ message: 'Placa estampada e informado ao DENATRAN com sucesso' })
    } catch (e: any) {
      const message = e.response?.data?.message ?? 'Ocorreu um erro inesperado'
      notification.error({ message })
    }
  }

  useEffect(() => {
    if (!codigoAutorizacaoDenatran) return
    dispatch(ListaPedidosStore.thunks.getImagensInstalacao(codigoAutorizacaoDenatran))
  }, [codigoAutorizacaoDenatran])

  const handleImagensInstalacao = () => {
    if (!codigoAutorizacaoDenatran) {
      notification.error({
        message: 'Emplacamento não possui o código de autorização do Denatran!',
      })
      return
    }
    dispatch(ListaPedidosStore.actions.openModalEncerrarEstampagemImagensInstalacao())
  }

  async function encerrarEstampagem() {
    if (empresaAtiva?.municipio?.uf !== 'SP') {
      const imagensInstalacao = pedidoAtivo?.emplacamento?.imagensInstalacao
      if (!imagensInstalacao || imagensInstalacao?.length === 0) {
        notification.error({ message: 'Estampagem não possui as imagens da instalação!' })
        return
      }
    }

    try {
      await reqEncerrarEstampagem()
      dispatch(ListaPedidosStore.thunks.getPedidos(idEmpresaAtiva))
      closeModal()
      notification.success({ message: 'Placa finalizada e informado ao DENATRAN com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro inesperado'
      notification.error({ message })
    }
  }

  const parPlaca = pedidoAtivo?.servicoId === SERVICOS.PAR_DE_PLACAS
  const tipoPlacaIndividual = _getTipoPlacaIndividual(pedidoAtivo?.servicoId)

  function _getTipoPlacaIndividual(servicoId: SERVICOS | undefined) {
    if (servicoId === SERVICOS.PLACA_DE_MOTO) return TIPOS_PRODUTOS.PLACA_MOTO
    if (servicoId === SERVICOS.PLACA_TRASEIRA) return TIPOS_PRODUTOS.PLACA_TRASEIRA_CARRO
    if (servicoId === SERVICOS.PLACA_DIANTEIRA) return TIPOS_PRODUTOS.PLACA_DIANTEIRA_CARRO
    if (servicoId === SERVICOS.SEGUNDA_PLACA_TRASEIRA)
      return TIPOS_PRODUTOS.SEGUNDA_PLACA_TRASEIRA_CARRO
    return null
  }

  const title = {
    INICIAR_ESTAMPAGEM: 'Iniciar Estampagem',
    ENCERRAR_ESTAMPAGEM: 'Encerrar Estampagem',
  }

  useEffect(() => {
    setTimeout(() => {
      document.querySelector<any>('.input-blank-scanner input')?.focus?.()
    })
  }, [modalVisible, pedidoAtivo?.produtos?.length])

  function handleClickConfirmar() {
    if (modalVisible === 'INICIAR_ESTAMPAGEM') void iniciarEstampagem()
    if (modalVisible === 'ENCERRAR_ESTAMPAGEM') void encerrarEstampagem()
  }

  return (
    <ModalEstampar
      transitionName=""
      visible={modalVisible === 'INICIAR_ESTAMPAGEM' || modalVisible === 'ENCERRAR_ESTAMPAGEM'}
      title={title[modalVisible]}
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button
          type="primary"
          size="large"
          loading={loadingEncerrarEstampagem || loadingIniciarEstampagem}
          key="ok"
          onClick={handleClickConfirmar}
        >
          {title[modalVisible]}
        </Button>,
        <Button key="cancel" size="large" onClick={closeModal}>
          Fechar
        </Button>,
      ]}
    >
      <Layout>
        <Placa />
        <Info>
          <Show condition={parPlaca}>
            <Produto tipoProduto={TIPOS_PRODUTOS.PLACA_DIANTEIRA_CARRO} />
            <Produto tipoProduto={TIPOS_PRODUTOS.PLACA_TRASEIRA_CARRO} />
          </Show>
          <Show condition={!parPlaca}>
            {!!tipoPlacaIndividual && <Produto tipoProduto={tipoPlacaIndividual} />}
          </Show>
          <Show
            condition={
              modalVisible === 'ENCERRAR_ESTAMPAGEM' &&
              !emiteNotaFiscal &&
              empresaAtiva?.municipio?.uf !== 'SP'
            }
          >
            <NotaFiscal />
          </Show>
          <Chassi />

          <Show
            condition={
              modalVisible === 'ENCERRAR_ESTAMPAGEM' && empresaAtiva?.municipio?.uf !== 'SP'
            }
          >
            <div style={{ marginTop: '40px' }}>
              <Button
                style={{ width: '100%', height: '40px' }}
                type="primary"
                onClick={handleImagensInstalacao}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',
                    fontSize: '16px',
                  }}
                >
                  <ImageIcon fill="#dfdfdf" /> Imagens da Instalação
                </div>
              </Button>
            </div>
          </Show>
        </Info>
      </Layout>
    </ModalEstampar>
  )
}

Estampar.ModalEstampar = styled(Modal)`
  width: 90vw !important;
  top: 50% !important;
  transform: translate(0, -50%) !important;
  .ant-modal-body {
    max-height: calc(90vh - 112px);
    overflow: auto;
  }
  @media (max-width: 600px) {
    .ant-modal-footer {
      display: flex;
      justify-content: space-space-around;
    }

    button {
      width: 100%;
      margin: 0 5px 10px 5px !important;
      padding: 9px;
    }

    .ant-modal-close-x {
      margin-top: -12px;
      margin-left: 233px;
    }
  }
`

Estampar.Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 260px;

  @media (max-width: 600px) {
    display: block;
  }
`
Estampar.Info = styled.div``

const Placa = () => {
  const { Layout } = Placa
  const { pedidoAtivo } = ListaPedidosStore.useState()
  if (!pedidoAtivo) return null
  return (
    <Layout>
      <RenderPlaca pedido={pedidoAtivo} />
      <span>{pedidoAtivo.servico?.descricao}</span>
    </Layout>
  )
}
Placa.Layout = styled.div`
  padding: 24px;
  font-size: 2em;
  text-align: center;

  @media (max-width: 600px) {
    padding: 3px;
    font-size: 20px;
  }
`

const Chassi = () => {
  const { pedidoAtivo } = ListaPedidosStore.useState()

  function validaESalvaChassi() {
    const message = _validaChassi(pedidoAtivo?.emplacamento.chassi)
    if (message) return notification.error({ message })

    notification.success({ message: 'Chassi Válido' })

    // salvar
  }

  return (
    <div style={{ marginTop: 16 }}>
      <label>
        Chassi
        <Input.Search
          placeholder="Informe o chassi"
          enterButton="Validar"
          value={pedidoAtivo?.emplacamento.chassi}
          onSearch={validaESalvaChassi}
        />
      </label>
    </div>
  )
}

const labelTipoProduto = {
  [TIPOS_PRODUTOS.PLACA_DIANTEIRA_CARRO]: (
    <>
      Serial da placa <strong>DIANTEIRA</strong>
    </>
  ),
  [TIPOS_PRODUTOS.PLACA_TRASEIRA_CARRO]: (
    <>
      Serial da placa <strong>TRASEIRA</strong>
    </>
  ),
  [TIPOS_PRODUTOS.PLACA_MOTO]: (
    <>
      Serial da placa de <strong>MOTO</strong>
    </>
  ),
  [TIPOS_PRODUTOS.SEGUNDA_PLACA_TRASEIRA_CARRO]: (
    <>
      Serial da <strong>SEGUNDA</strong> placa <strong>TRASEIRA</strong>
    </>
  ),
}

const Produto = ({ tipoProduto }: { tipoProduto: TIPOS_PRODUTOS }) => {
  const { Layout, Label, Serial } = Produto
  const { pedidoAtivo, modalVisible } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()
  const produto = pedidoAtivo?.produtos.find((p) => p.tipoProdutoId === tipoProduto)

  function removeProduto() {
    dispatch(ListaPedidosStore.actions.removeProduto(tipoProduto))
  }

  function setSerial(produto: ProdutoModel) {
    dispatch(ListaPedidosStore.actions.setProduto({ tipoProduto, produto }))
  }

  if (produto) {
    return (
      <Layout>
        <Label>{labelTipoProduto[tipoProduto]}</Label>

        <DisplayFlex style={{ alignItems: 'center' }}>
          <Serial>{produto.serial}</Serial>
          <Show condition={modalVisible === 'INICIAR_ESTAMPAGEM'}>
            <Button icon={<DeleteOutlined />} onClick={removeProduto} />
          </Show>
        </DisplayFlex>
      </Layout>
    )
  }
  return (
    <Layout>
      <Label>{labelTipoProduto[tipoProduto]}</Label>
      <BlanksScanner onScan={setSerial} showProduto={false} />
    </Layout>
  )
}
Produto.Layout = styled.div`
  margin-top: 16px;
`
Produto.Label = styled.div``
Produto.Serial = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  margin-right: 8px;
`

const NotaFiscal = () => {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()
  const notaFiscal = pedidoAtivo?.emplacamento?.numeroNF ?? ''

  function setNotaFiscal(nf) {
    dispatch(ListaPedidosStore.actions.setNotaFiscal({ notaFiscal: nf }))
  }

  const [{ loading }, req] = useAxios(
    {
      url: '/emplacamentos/nota-fiscal',
      method: 'PUT',
      data: { emplacamentoId: pedidoAtivo?.emplacamento?.id, numeroNotaFiscal: notaFiscal },
    },
    {
      manual: true,
    }
  )

  async function handleConfirm() {
    try {
      await req()
      dispatch(ListaPedidosStore.actions.setNotaFiscal({ notaFiscal, persist: true }))
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao salvar a nota fiscal'
      notification.error({ message })
    }
  }

  return (
    <div style={{ marginTop: 16 }}>
      <label>
        Nota Fiscal
        <Input.Search
          size="large"
          placeholder="Informe a nota fiscal"
          enterButton="Salvar"
          onSearch={handleConfirm}
          value={notaFiscal}
          loading={loading}
          onChange={(e) => setNotaFiscal(e.target.value)}
        />
      </label>
    </div>
  )
}

// 9 BW SU19F0 8 B302158
function _validaChassi(chassi) {
  const _chassi = chassi.replace(/ /g, '')
  // 1 - Se possuir número de dígitos diferente de 17 (alfanuméricos).
  if (_chassi.length !== 17) {
    return 'Chassi deve ter obrigatoriamente 17 caractéres'
  }
  // 2 - Possuir o número "0" (ZERO) como 1º dígito.
  const zeroNoPrimeiroDigito = /^0/
  if (zeroNoPrimeiroDigito.test(_chassi)) {
    return 'O primeiro dígito não pode começar com 0'
  }
  // 3 - Se, a partir do 4º dígito, houver uma repetição consecutiva, por mais de seis vezes, do mesmo dígito
  // (alfabético ou numérico). Exemplos: 9BW11111119452687 e 9BWZZZ5268AAAAAAA.
  const repeticaoMaisDe6Vezes = /^.{4,}([0-9A-Z])\1{5,}/
  if (repeticaoMaisDe6Vezes.test(_chassi)) {
    return 'Não é permitido uma repetição por mais de 6 caractéres do mesmo dígito a partir do 4º dígito'
  }
  // 4 - Apresente os caracteres "i", "I", "o", "O", "q", "Q".
  if (/[iI]/.test(_chassi)) {
    return 'O caractére I não é permitido pois se confunde com o número 1(um)'
  }
  if (/[oO]/.test(_chassi)) {
    return 'O caractére O não é permitido pois se confunde com o número 0(zero)'
  }
  if (/[qQ]/.test(_chassi)) {
    return 'O caractére Q não é permitido pois se confunde com o número 0(zero)'
  }
  // 5 - Os quatro últimos caracteres devem ser obrigatoriamente numéricos
  const ultimos4Numericos = /[0-9]{4}$/
  if (!ultimos4Numericos.test(_chassi)) return 'Os últimos caractéres devem ser numéricos'

  // 6 - Décimo caractére não pode ser U
  if (_chassi.charAt(9).toUpperCase() === 'U') return 'Décimo caractére não pode ser a letra U'

  if (getCheckDigit(_chassi) !== _chassi.charAt(8)) {
    return 'Dígito verificador na posição 9 inválido'
  }
  return ''
}

function getCheckDigit(vin) {
  const transliterate = (c) => '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10

  const map = '0123456789X'
  const weights = '8765432X098765432'
  let sum = 0
  for (let i = 0; i < 17; i++) {
    sum += transliterate(vin.charAt(i)) * map.indexOf(weights.charAt(i))
  }
  return map.charAt(sum % 11)
}

// 93XJRKB8TAB914797
// 9BWAA05U4CT177172
// 9BWAA45Z294084088
// JMIEC4322P0210420 - ERRADO - I
// 9BWCA15X41T030906
// 9BWCA05Y02T123DB2 - ULTIMOS CARACTERES NAO NUMERICOS
// 9BWCA45X13P010786
// 9BWCA45X23T046901
// 9A9V1133OR2AD9084 — LETRA O
