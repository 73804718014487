import React, { useEffect } from 'react'
import { Button, Card, notification, Table } from 'antd'
import { createSimpleStore } from 'react-simple-reducer'
import { DisplayFlex } from '../common/DisplayFlex'
import styled from '@emotion/styled'
import { BlanksScanner } from '../common/seplac'
import { PERMISSOES, ProdutoModel, TIPOS_PRODUTOS } from '../common/Models'
import Axios from 'axios'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { formatDistance } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const ValidarQrCode = () => {
  usePermissaoUsuario([PERMISSOES.VALIDAR_QR_IMPRESSO], { changeRoute: true })

  const { Card } = ValidarQrCode
  return (
    <Store.Provider>
      <Card title="Validação da impressão de Qr Codes">
        <p>Selecione o tipo de placa </p>
        <SelecaoTipoImpressao />
        <p>Escaneie o Qr Code para validar</p>
        <SelecaoBlank />
        <ProdutosValidados />
      </Card>
    </Store.Provider>
  )
}
ValidarQrCode.Card = styled(Card)`
  margin: 24px auto;
  max-width: 800px;
  p {
    margin: 8px 0 0 0;
  }
`

const SelecaoBlank = () => {
  const { tipo } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleScan(produto: ProdutoModel) {
    if (!tipo) {
      return notification.error({ message: 'Informe o tipo de placa antes de efetuar a validação' })
    }
    if (produto.tipoProdutoId !== TIPOS_PRODUTOS.BLANK) {
      return notification.error({
        message: `Esse produto já foi validado como sendo ${produto.tipoProduto.descricao}`,
      })
    }
    dispatch(Store.thunks.printQrCode(produto))
  }

  useEffect(() => {
    document?.getElementById('searchBlanksScanner')?.focus()
  }, [tipo])

  return <BlanksScanner onScan={handleScan} showLote={true} disabled={!tipo} showProduto={false} />
}

const SelecaoTipoImpressao = () => {
  const { tipo } = Store.useState()
  const dispatch = Store.useDispatch()
  return (
    <DisplayFlex spaceBetween={12}>
      <Button
        size="large"
        type={tipo === 'CARRO' ? 'primary' : 'default'}
        onClick={() => dispatch(Store.actions.changeTipo('CARRO'))}
        style={{ flex: 1 }}
      >
        Placa de Carro
      </Button>
      <Button
        size="large"
        type={tipo === 'MOTO' ? 'primary' : 'default'}
        onClick={() => dispatch(Store.actions.changeTipo('MOTO'))}
        style={{ flex: 1 }}
      >
        Placa de Moto
      </Button>
    </DisplayFlex>
  )
}

const ProdutosValidados = () => {
  const { produtosValidados } = Store.useState()
  const produtosSorted = [...produtosValidados].sort((a, b) =>
    a.dataValidacao > b.dataValidacao ? -1 : 1
  )
  return (
    <div>
      <h3>Blanks validados</h3>
      <Table
        dataSource={produtosSorted}
        columns={[
          { title: 'Serial', render: (row) => row.serial },
          { title: 'Lote', render: (row) => row.loteId },
          {
            title: 'Data',
            render: (row) => formatDistance(row.dataValidacao, new Date(), { locale: ptBR }),
          },
        ]}
      />
    </div>
  )
}

const Store = createSimpleStore(
  {
    tipo: '' as 'CARRO' | 'MOTO' | '',
    produto: null as ProdutoModel | null,
    produtosValidados: [] as Array<ProdutoModel & { dataValidacao }>,
  },
  {
    changeTipo(state, tipo) {
      state.tipo = tipo
    },
    changeProduto(state, produto: ProdutoModel) {
      state.produto = produto
    },
    printQrCodeSuccess(state, produto: ProdutoModel) {
      state.produtosValidados = [
        ...state.produtosValidados,
        { ...produto, dataValidacao: new Date() },
      ]
    },
  },
  {
    thunks: {
      printQrCode(produto: ProdutoModel) {
        return async (dispatch, getState) => {
          const { tipo } = getState()
          dispatch(Store.actions.changeProduto(produto))
          if (!tipo) {
            return console.warn(
              '%cprintQrCode' + '%c foi chamado sem que o tipo estivesse preenchido',
              'font-weight: bold; font-size: 1.2em;',
              ''
            )
          }
          if (produto.tipoProdutoId !== TIPOS_PRODUTOS.BLANK) {
            return console.warn(
              '%cprintQrCode' + '%c foi chamado com um produto que não é um BLANK',
              'font-weight: bold; font-size: 1.2em;',
              ''
            )
          }
          try {
            await Axios.post('/produtos/imprimir-qr-code', {
              tipo,
              produtosIds: [produto.id],
            }).then((x) => x?.data)
            notification.success({ message: 'Blank validado com sucesso' })
            dispatch(Store.actions.printQrCodeSuccess(produto))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao validar Qr Code'
            notification.error({ message })
          }
        }
      },
    },
  }
)

export { ValidarQrCode }
