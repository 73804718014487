import { Card, Input, Table } from 'antd'
import React, { useEffect } from 'react'
import useAxios from 'axios-hooks'
import { parseISO, format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { PERMISSOES } from '../common/Models'

export const Lista = () => {
  usePermissaoUsuario([PERMISSOES.BAIXAR_BLANKS, PERMISSOES.SOLICITAR_BLANKS], {
    changeRoute: true,
  })

  const [{ data: lotes }, fetchLotes] = useAxios({
    url: '/lotes',
    method: 'GET',
  })

  useEffect(() => {
    void fetchLotes()
  }, [])

  return (
    <Card title="Lotes">
      <Busca fetchLotes={fetchLotes} />
      <ListaLotes lotes={lotes} />
    </Card>
  )
}

const Busca = ({ fetchLotes }) => {
  return (
    <Input.Search
      placeholder="Buscar por Código ou Id"
      style={{ marginBottom: 25 }}
      onSearch={(value) => {
        fetchLotes(value && { params: { numeroLote: value } })
      }}
      autoFocus
    />
  )
}

const ListaLotes = ({ lotes }) => {
  const history = useHistory()
  const columns = [
    { title: 'Id', dataIndex: 'id', key: 'id' },
    { title: 'Qtd Blanks', dataIndex: 'qtdBlanks', key: 'qtdBlanks' },
    {
      title: 'Solicitado em',
      render: (value) => format(parseISO(value.createdAt), 'dd/MM/yyyy HH:mm'),
      key: 'createdAt',
    },
    { title: 'Solicitado por', render: (value) => <>{value.usuario?.nome}</>, key: 'usuario' },
  ]

  function handleClick(lote) {
    history.push(`/lotes/detalhe/${lote.id}`)
  }

  return (
    <Table
      dataSource={lotes?.rows}
      columns={columns}
      onRow={(record) => ({
        onClick: () => {
          handleClick(record)
        },
      })}
      rowKey="id"
    />
  )
}
