import React, { useEffect, useLayoutEffect } from 'react'
import { Card, Button, notification, Tabs, Tooltip } from 'antd'
import { BlanksScanner, LotesScanner } from '../common/seplac'
import useAxios from 'axios-hooks'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { LoteModel, PERMISSOES, ProdutoModel } from '../common/Models'
import { createSimpleStore } from 'react-simple-reducer'
import { DeleteOutlined } from '@ant-design/icons'
import { format, parseISO } from 'date-fns'
import styled from '@emotion/styled'
import { AuthStore } from '../auth/AuthStore'
import { ComprovanteDevolucao } from 'pedidos/devolucoes/ComprovanteDevolucao'

const Store = createSimpleStore(
  {
    produtos: [] as ProdutoModel[],
    devolucao: null as any,
  },
  {
    insertProdutos(state, produtos: ProdutoModel[]) {
      state.produtos = [...state.produtos, ...produtos]
    },
    removeProduto(state, produto) {
      state.produtos = state.produtos.filter((p) => p.id !== produto.id)
    },
    devolucaoSuccess(state, devolucao) {
      state.produtos = []
      state.devolucao = devolucao
    },
    clearDevolucao(state) {
      state.devolucao = null
    },
  }
)

export const Devolucao = () => {
  usePermissaoUsuario([PERMISSOES.DEVOLVER_BLANK], { changeRoute: true })

  const handleChangeTabs = (e) => {
    requestAnimationFrame(() => {
      if (e === '1') document?.getElementById('searchLotesScanner')?.focus()
      if (e === '2') document?.getElementById('searchBlanksScanner')?.focus()
    })
  }

  return (
    <Store.Provider>
      <Card title="Devolução de blanks">
        <Tabs onChange={handleChangeTabs}>
          <Tabs.TabPane tab="Lote" key="1">
            <InsereLote />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Produto" key="2">
            <InsereProduto />
          </Tabs.TabPane>
        </Tabs>
        <ListaProdutos>{(produto) => <Produto key={produto.id} produto={produto} />}</ListaProdutos>
        <Confirmar />
        <Comprovante />
      </Card>
      <Comprovante />
    </Store.Provider>
  )
}

const Comprovante = () => {
  const { devolucao } = Store.useState()
  const dispatch = Store.useDispatch()
  if (!devolucao) return null

  function handleClose() {
    dispatch(Store.actions.clearDevolucao())
  }

  return <ComprovanteDevolucao pedidoId={devolucao.id} onClose={handleClose} />
}

const ListaProdutos = ({ children }) => {
  const { produtos } = Store.useState()
  return <>{produtos.map((p) => children(p))}</>
}

const Produto = ({ produto }: { produto: ProdutoModel }) => {
  const { Layout } = Produto
  const dispatch = Store.useDispatch()

  function handleClickDelete() {
    dispatch(Store.actions.removeProduto(produto))
  }

  return (
    <Layout>
      <strong>Produto</strong>
      <div>Serial: {produto.serial}</div>
      <div>Tipo: {produto.tipoProduto.descricao}</div>
      <div>Criado em: {format(parseISO(produto.createdAt), 'dd/MM/yyyy HH:mm')}</div>
      <div>Pertencente ao lote: {produto.loteId}</div>
      <div>
        <Tooltip title="Remover produto da lista de envio">
          <Button shape="circle" icon={<DeleteOutlined />} onClick={handleClickDelete} />
        </Tooltip>
      </div>
    </Layout>
  )
}
Produto.Layout = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
`

const Confirmar = () => {
  const { produtos } = Store.useState()
  const dispatch = Store.useDispatch()

  const [{ data, loading }, req] = useAxios(
    {
      url: '/pedidos/devolucao',
      method: 'POST',
      data: { empresaDestinoId: 323, produtos },
    },
    { manual: true }
  )

  useEffect(() => dispatch(Store.actions.devolucaoSuccess(data)), [data])

  async function handleClick() {
    try {
      const devolucao = await req().then((x) => x.data)
      dispatch(Store.actions.devolucaoSuccess(devolucao))
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao efetuar a devolução'
      notification.error({ message })
    }
  }

  return (
    <Button loading={loading} onClick={handleClick}>
      Confirmar
    </Button>
  )
}

const InsereLote = () => {
  const dispatch = Store.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()

  function handleScan(lote: LoteModel) {
    if (lote.produtos.some((p) => p.empresaDetentoraId !== idEmpresaAtiva)) {
      return notification.info({ message: 'Lote não pertence à empresa atual' })
    }

    if (lote) dispatch(Store.actions.insertProdutos(lote.produtos))
  }

  return (
    <div>
      <LotesScanner onScan={handleScan} showProdutos={true} style={{ width: 500 }} />
    </div>
  )
}

const InsereProduto = () => {
  const dispatch = Store.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()

  function handleScan(produto: ProdutoModel) {
    if (produto.empresaDetentoraId !== idEmpresaAtiva) {
      return notification.info({ message: 'Blank não pertence à empresa atual' })
    }

    dispatch(Store.actions.insertProdutos([produto]))
  }

  return (
    <div>
      <BlanksScanner onScan={handleScan} showProduto={false} style={{ width: 500 }} />
    </div>
  )
}
