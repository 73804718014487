import { Button, Card, notification, Input } from 'antd'
import React, { useState } from 'react'
import useAxios from 'axios-hooks'
import { Link } from 'react-router-dom'

import { Show } from 'common/Show'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { PERMISSOES } from '../common/Models'

export const Cadastro: React.FC = () => {
  usePermissaoUsuario([PERMISSOES.SOLICITAR_BLANKS], { changeRoute: true })

  const [quantidade, setQuantidade] = useState('')

  const [request, criarLoteProdutos] = useAxios(
    {
      url: '/lotes',
      method: 'POST',
      data: { quantidade },
    },
    {
      manual: true,
    }
  )

  async function solicitarBlanks() {
    if (!quantidade) return notify.error('Informe a quantidade de blanks')

    try {
      await criarLoteProdutos()
    } catch (error: any) {
      const msg = error.response?.data?.message ?? 'Ocorreu um erro ao criar os produtos'
      notify.error(msg)
    }
  }

  return (
    <Card title="Solicitação de novos Blanks">
      <QuantidadeBlanks quantidade={quantidade} setQuantidade={setQuantidade} />
      <Button loading={request.loading} onClick={solicitarBlanks}>
        Solicitar Blanks
      </Button>

      <Show condition={!request.error && request.data}>
        <InfoLoteCadastrado lote={request?.data?.lote} />
      </Show>
    </Card>
  )
}

const InfoLoteCadastrado = ({ lote }) => {
  return (
    <Link to={`/lotes/detalhe/${lote.id}`}>
      <Card title="Blanks recebidos com sucesso">
        <div>
          Número do lote:{' '}
          <big>
            <strong>{lote.id}</strong>
          </big>
          <br />
          Código de identificação do lote no DENATRAN: <strong>{lote.numero}</strong> <br />
          Quantidade de blanks: <strong>{lote.qtdBlanks}</strong>
        </div>
      </Card>
    </Link>
  )
}

const QuantidadeBlanks = ({ quantidade, setQuantidade }) => {
  return (
    <label>
      Blanks
      <Input
        value={quantidade}
        onChange={(e) => setQuantidade(e.target.value)}
        placeholder="Informe a quantidade de blanks"
        autoFocus
      />
    </label>
  )
}

const notify = {
  error: (message) => {
    notification.error({
      key: 'erroSubmit',
      message: message,
      onClick: () => notification.close('erroSubmit'),
    })
  },
  success: (message) => {
    notification.success({
      key: 'succesSubmit',
      message: message,
      onClick: () => notification.close('succesSubmit'),
    })
  },
}
