import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { AlteraSenha } from './AlteraSenha'

const Usuarios = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/altera-senha`} exact component={AlteraSenha} />
    </Switch>
  )
}

export default Usuarios
