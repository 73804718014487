import React from 'react'
import { AuthStore, getEmpresaAtiva, getUsuarioLogado } from 'auth/AuthStore'
import { PERMISSOES } from '../Models'
import { checkPermissao } from '../checkPermissao'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'
import { getPermissoesEmpresa } from '../../auth/AuthStore'

export default function usePermissaoUsuario(
  permissoesNecessarias: PERMISSOES[],
  opts?: { changeRoute: boolean }
) {
  const changeRoute = opts?.changeRoute || false
  const [permissao, setPermissao] = React.useState(true)
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const permissoes = AuthStore.useSelector(getPermissoesEmpresa)
  const history = useHistory()

  if (!permissao && changeRoute) {
    notification.warn({ message: 'Você não tem permissão para visualizar essa tela' })
    history.replace('/')
  }

  React.useEffect(() => {
    setPermissao(true)
    if (!permissoesNecessarias?.length || !empresaAtiva) return
    if (!checkPermissao(permissoesNecessarias, permissoes, usuarioLogado?.admin)) {
      setPermissao(false)
    }
  }, [permissoes, usuarioLogado, permissao, permissoesNecessarias])

  return permissao
}
