import React from 'react'
import { useFormik, FormikProvider } from 'formik'
import styled from '@emotion/styled'
import { TextField } from 'common/TextField'
import { Redirect } from 'react-router-dom'
import { AuthStore, getUsuarioLogado } from './AuthStore'
import useAxios from 'axios-hooks'
import Button from 'antd/es/button'
import notification from 'antd/es/notification'
import { Modal } from 'antd'
import { AlteraSenha } from '../usuarios/AlteraSenha'

export const Login: React.FC = () => {
  const dispatch = AuthStore.useDispatch()

  const [showModal, setShowModal] = React.useState(false)
  const [modalInfo, setModalInfo] = React.useState<any>({})

  const [, executeLogin] = useAxios(
    {
      url: '/usuarios/login',
      method: 'POST',
    },
    { manual: true }
  )

  const { fetching } = AuthStore.useState()
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)

  const formik = useFormik({
    initialValues: { usuario: '', senha: '' },
    onSubmit: async (values) => {
      const cpf = values.usuario
      const pwd = values.senha
      try {
        const loginData = await executeLogin({
          data: {
            login: cpf,
            senha: pwd,
          },
        })
        if (loginData.data.isSenhaPadrao) {
          setShowModal(true)
          setModalInfo({
            senhaAtual: values.senha,
            token: loginData.data.token,
            usuarioId: loginData.data.usuario.id,
          })
          return
        }
        const payload = {
          token: loginData.data.token,
          empresas: loginData.data.empresas,
          usuario: loginData.data.usuario,
        }
        dispatch(AuthStore.actions.loginSuccess(payload))
      } catch (e: any) {
        notification.error({ message: 'Falha ao efetuar o login' })
      }
    },
  })

  function closeModal() {
    setShowModal(false)
  }

  if (usuarioLogado) return <Redirect to={'/'} />

  return (
    <Styles.Container>
      <Styles.ContainerMaster>
        <Styles.ContainerPadrao>
          <img alt="Seplac Logo" src="seplac.jpeg" style={{ paddingBottom: '11px', width: 170 }} />
          <h1 style={{ fontWeight: 'bold' }}>SEPLAC.NET</h1>
          <Styles.TextoTitle>Informe seu login e senha para continuar</Styles.TextoTitle>
        </Styles.ContainerPadrao>
        <Styles.ContainerInner>
          <FormikProvider value={formik}>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                mask="cpf"
                name="usuario"
                type="cel"
                label="Usuário"
                placeholder="Informe o CPF"
              />
              <TextField name="senha" type="password" label="Senha" placeholder="Informa a senha" />
              <Styles.ContainerButton>
                <Button loading={fetching} htmlType="submit">
                  Entrar
                </Button>
              </Styles.ContainerButton>
            </form>
          </FormikProvider>
        </Styles.ContainerInner>
        {/*
         <Styles.LinkRecuperarSenha href='https://rep.net.br/#/recuperasenha'>
           <Styles.EsqueceuSenha>Esqueci minha senha</Styles.EsqueceuSenha>
         </Styles.LinkRecuperarSenha>
        */}
        <ModalNovaSenha showModal={showModal} closeModal={closeModal} {...modalInfo} />
      </Styles.ContainerMaster>
    </Styles.Container>
  )
}

const ModalNovaSenha = ({ showModal, senhaAtual, closeModal, token, usuarioId }) => {
  const dispatch = AuthStore.useDispatch()
  const [{ loading }, reqUpdateSenha] = useAxios(
    {
      url: '/usuarios/senha',
      method: 'PUT',
      headers: {
        Authorization: token,
        usuarioId,
      },
    },
    { manual: true }
  )
  async function handleSubmit(values, { resetForm }) {
    try {
      const dadosLogin = await reqUpdateSenha({ data: { ...values, senhaAtual } }).then(
        (x) => x.data
      )
      dispatch(AuthStore.actions.loginSuccess(dadosLogin))
      resetForm()
      notification.success({ message: 'Senha alterada com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao alterar a senha'
      notification.error({ message })
    }
  }
  return (
    <Modal visible={showModal} onCancel={closeModal} footer={null}>
      <h2>Você precisa alterar sua senha.</h2>
      <AlteraSenha.Form senhaAtual={senhaAtual} onSubmit={handleSubmit} loading={loading} />
    </Modal>
  )
}

const Styles = {
  Container: styled('div', { label: 'Container' })`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-around;
    background: none;
    box-shadow: none;
  `,
  ContainerInner: styled('div', { label: 'ContainerInner' })`
    background: #f4f4f4;
    border-radius: 10px;
    padding: 1em;
    margin: 1em;
    width: 500px;
    @media (min-width: 425px) {
      padding: 2em;
      margin: 0 auto;
    }
  `,
  EsqueceuSenha: styled('div', { label: 'EsqueceuSenha' })`
    text-align: center;
    margin-bottom: 2em;
    margin-top: 23px;
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
    color: black;
  `,
  ContainerButton: styled('div', { label: 'ContainerButton' })`
    margin-top: 12px;
    text-align: right;
  `,
  EsqueciSenha: styled('p', { label: 'EsqueciSenha' })`
    text-align: center;
  `,
  ContainerMaster: styled('div', { label: 'ContainerMaster' })`
    margin-top: 2px;
  `,
  TextoTitle: styled('h1', { label: 'TextoTitle' })`
    text-align: center;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.68);
  `,
  LinkRecuperarSenha: styled('a', { label: 'LinkRecuperarSenha' })`
    :link {
      color: black;
      text-decoration: none;
    }
    :hover {
      text-decoration: underline;
    }
  `,
  ContainerPadrao: styled('div', { label: 'ContainerPadrao' })`
    text-align: center;
  `,
}
