import React, { useEffect } from 'react'
import { Card, Button, Tooltip, Table } from 'antd'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { PERMISSOES } from '../common/Models'
import { CheckOutlined, EyeOutlined } from '@ant-design/icons'
import { format } from 'date-fns'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'
import { AuthStore, getEmpresaAtiva } from 'auth/AuthStore'
import useAxios from 'axios-hooks'

export const ListaAuditorias = () => {
  usePermissaoUsuario([PERMISSOES.AUDITAR], { changeRoute: true })

  const { Layout } = ListaAuditorias
  const history = useHistory()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const [{ data: auditorias }, getAuditorias] = useAxios(
    {
      url: '/auditorias',
      params: {
        empresaId: empresaAtiva?.id,
      },
    },
    { manual: true }
  )

  function auditar(id, data) {
    history.push(`/produtos/auditorias/${id}`, { data })
  }

  useEffect(() => {
    getAuditorias()
  }, [])

  return (
    <Layout>
      <Card title="Auditorias">
        <Table
          columns={[
            { title: 'Id', dataIndex: 'id' },
            {
              title: 'Data Início',
              render: (row) => <>{format(new Date(row.dataInicio), 'dd/MM/yyyy')}</>,
            },
            {
              title: 'Data Fim',
              render: (row) => <>{format(new Date(row.dataFim), 'dd/MM/yyyy')}</>,
            },
            {
              title: 'Data Conclusão',
              render: (row) => (
                <>{row.dataConclusao && format(new Date(row.dataConclusao), 'dd/MM/yyyy')}</>
              ),
            },
            { title: 'Usuário', render: (row) => <>{row.usuario.nome}</> },
            {
              title: 'Pendências',
              render: (row) => (
                <strong style={{ color: 'blue' }}>
                  {((row.produtosNãoAuditado / row.produtosTotal) * 100).toFixed(0)}%
                </strong>
              ),
            },
            {
              render: (row) => (
                <Tooltip key={row.id} title="Auditar">
                  {row.dataConclusao && <strong key={row.id}>Concluído</strong>}
                  <Button
                    icon={row.dataConclusao ? <EyeOutlined /> : <CheckOutlined />}
                    onClick={() => auditar(row.id, row)}
                  />
                </Tooltip>
              ),
            },
          ]}
          dataSource={auditorias || []}
        />
      </Card>
    </Layout>
  )
}
ListaAuditorias.Layout = styled.div`
  .ant-card-head-title {
    font-weight: bold;
    font-size: 18px;
    padding: 10px 0;
  }
`
