import styled from '@emotion/styled'
import {
  Button,
  Card,
  Checkbox,
  Input,
  notification,
  Radio,
  Table,
  Tabs,
  Tooltip,
  DatePicker,
  Modal,
  Spin,
  Select,
} from 'antd'
import useAxios from 'axios-hooks'
import { format, formatDistance, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { AuthStore, getEmpresaAtiva, getUsuarioLogado } from '../auth/AuthStore'
import { DisplayFlex } from '../common/DisplayFlex'
import {
  EmpresaModel,
  IAuditoria,
  PERMISSOES,
  TIPOS_PRODUTOS,
  UsuarioEmpresaModel,
} from '../common/Models'
import { Show } from '../common/Show'
import { createSimpleStore } from 'react-simple-reducer'
import { TextField, TextFieldInput } from '../common/TextField'
import {
  CheckOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  RightCircleOutlined,
} from '@ant-design/icons'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { RestrictedResource } from '../common/RestrictedResource'
import { createSelector } from 'reselect'
import axios, { Axios } from 'axios'
import moment from 'moment'
import { saveAs } from 'file-saver'
import Progress from 'antd/es/progress'

const Store = createSimpleStore(
  {
    empresa: null as EmpresaModel | null,
    usuariosEmpresas: [] as UsuarioEmpresaModel[],
    usuarioEmpresaAtual: {} as UsuarioEmpresaModel & { permissoes?: PERMISSOES[] },
    permissoes: [] as PERMISSOES[],
  },
  {
    initSuccess(state, payload: { empresa; usuarios; permissoes }) {
      state.empresa = payload.empresa
      state.usuariosEmpresas = payload.usuarios
      state.permissoes = payload.permissoes
    },
    updateDataCertificado(state, novaData) {
      if (!state.empresa) return
      state.empresa.dataValidadeCertificado = novaData
    },
    editUsuario(state, usuarioEmpresa: UsuarioEmpresaModel) {
      state.usuarioEmpresaAtual = usuarioEmpresa
    },
    saveUsuarioSuccess(state, usuarioEmpresa: UsuarioEmpresaModel) {
      state.usuarioEmpresaAtual = {} as UsuarioEmpresaModel
      const usuarioExistente = state.usuariosEmpresas.some(
        (u) => u.usuarioId === usuarioEmpresa.usuarioId
      )
      if (usuarioExistente)
        state.usuariosEmpresas = state.usuariosEmpresas.map((u) =>
          u.usuarioId === usuarioEmpresa.usuarioId ? usuarioEmpresa : u
        )
      else state.usuariosEmpresas = [...state.usuariosEmpresas, usuarioEmpresa]
    },
    togglePermissao(state, permissao: PERMISSOES) {
      if (!state.usuarioEmpresaAtual) return
      if (state.usuarioEmpresaAtual.permissoes?.includes(permissao)) {
        state.usuarioEmpresaAtual.permissoes =
          state.usuarioEmpresaAtual.permissoes?.filter((p) => p !== permissao) ?? []
      } else {
        state.usuarioEmpresaAtual.permissoes = [
          ...(state.usuarioEmpresaAtual?.permissoes ?? []),
          permissao,
        ]
      }
    },
    deleteUsuarioSuccess(state, usuarioId: number) {
      state.usuariosEmpresas = state.usuariosEmpresas.filter((f) => f.usuarioId !== usuarioId)
    },
    updateCodigoDetranGo(state, codigoDetranGo: number) {
      if (!state.empresa) return
      state.empresa.codigoDetranGo = codigoDetranGo
    },
    updateEmpresaSuccess(state, empresa: EmpresaModel) {
      state.empresa = empresa
    },
  },
  {
    thunks: {
      init(empresaId: number) {
        return async (dispatch) => {
          try {
            const [empresa, usuarios, permissoes] = await Promise.all([
              axios.get(`/empresas/${empresaId}`).then((x) => x.data),
              axios.get(`/usuarios-empresas?empresaId=${empresaId}`).then((x) => x.data),
              axios.get('/usuarios-empresas/permissoes').then((x) => x.data),
            ])
            dispatch(Store.actions.initSuccess({ empresa, usuarios, permissoes }))
          } catch (error: any) {
            const message =
              error.response?.data?.message ?? 'Ocorreu um erro ao buscar as informações da empresa'
            notification.error({ message })
          }
        }
      },
      updateEmpresa(empresa: Partial<EmpresaModel>) {
        return async (dispatch) => {
          try {
            const empresaEditada = await axios.post('/empresas', empresa).then((x) => x.data)
            dispatch(Store.actions.updateEmpresaSuccess(empresaEditada))
          } catch (error: any) {
            const message =
              error.response?.data?.message ?? 'Ocorreu um erro ao salvar o código do detran'
            notification.error({ message })
          }
        }
      },
    },
  }
)

type IState = ReturnType<typeof Store.useState>

const selectPermissoesPorTipo = createSelector(
  (s: IState) => s.permissoes,
  (s: IState) => s.empresa,
  (permissoes, empresa) => {
    if (!empresa || !permissoes?.[empresa.tipoEmpresa]) return []
    return permissoes[empresa.tipoEmpresa].filter((p) => p !== PERMISSOES.APAGAR_EMPLACAMENTO)
  }
)

export const DetalheEmpresa = () => {
  usePermissaoUsuario(
    [
      PERMISSOES.GERENCIAR_EMPRESAS,
      PERMISSOES.GERENCIAR_TODOS_USUARIOS,
      PERMISSOES.GERENCIAR_USUARIOS,
      PERMISSOES.ATUALIZAR_CERTIFICADO,
    ],
    { changeRoute: true }
  )

  const usuarioPodeVerTodasEmpresas = usePermissaoUsuario([
    PERMISSOES.GERENCIAR_EMPRESAS,
    PERMISSOES.GERENCIAR_TODOS_USUARIOS,
  ])

  const { Titulo } = DetalheEmpresa
  const { empresaId } = useParams<{ empresaId }>()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  const initStore = React.useCallback(
    (dispatch) => {
      dispatch(Store.thunks.init(empresaId))
    },
    [empresaId]
  )

  if (empresaAtiva?.id !== +empresaId && !usuarioPodeVerTodasEmpresas) {
    notification.warn({ message: 'Você não tem permissão para acessar os dados dessa empresa' })
    return <Redirect to="/" />
  }

  return (
    <Store.Provider init={initStore}>
      <Voltar />
      <Store.GetState>{({ empresa }) => <Titulo>{empresa?.descricao}</Titulo>}</Store.GetState>
      <Tabs>
        <Tabs.TabPane tab="Empresa" key="1">
          <Empresa />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Usuários" key="2">
          <RestrictedResource
            permissoes={[PERMISSOES.GERENCIAR_USUARIOS, PERMISSOES.GERENCIAR_TODOS_USUARIOS]}
          >
            <NovoUsuario />
            <ListaUsuarios />
          </RestrictedResource>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Produtos" key="3">
          <RelatorioProdutosPorTipo />
          <RestrictedResource
            permissoes={[PERMISSOES.GERENCIAR_EMPRESAS]}
            renderWhenForbidden={null}
          >
            <GerenciarEstoqueMinimo />
          </RestrictedResource>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Certificado" key="4">
          <RestrictedResource permissoes={[PERMISSOES.ATUALIZAR_CERTIFICADO]}>
            <EnviarNovoCertificado />
          </RestrictedResource>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Auditoria" key="5">
          <RestrictedResource permissoes={[PERMISSOES.CRIAR_AUDITORIA]}>
            <ListaAuditorias />
          </RestrictedResource>
        </Tabs.TabPane>
      </Tabs>
    </Store.Provider>
  )
}
DetalheEmpresa.Titulo = styled.div`
  font-size: 1.2em;
  margin-top: 8px;
  font-weight: bold;
`

const Voltar = () => {
  const history = useHistory()

  function handleClick() {
    history.goBack()
  }

  return (
    <Button onClick={handleClick}>
      <LeftOutlined /> Voltar
    </Button>
  )
}

const Empresa = () => {
  const { Layout } = Empresa
  const { empresa } = Store.useState()

  const admin = !!AuthStore.useSelector(getUsuarioLogado)?.admin

  if (!empresa) return null

  return (
    <Layout>
      <div>
        ID (SERPRO)
        <br />
        <strong>{empresa.id}</strong>
      </div>
      <div>
        CNPJ
        <br />
        <strong>{empresa.cnpj}</strong>
      </div>
      <div>
        Nome Empresa
        <br />
        <InlineEdit field="descricao" />
      </div>
      <div>
        Código Detran Go
        <br />
        <InlineEdit field="codigoDetranGo" />
      </div>
      <div>
        Cidade
        <br />
        <strong>{empresa.municipio?.nome}</strong>
      </div>
      <div>
        Incluída no sistema em
        <br />
        <strong>{format(parseISO(empresa.createdAt), 'dd/MM/yyyy HH:mm')}</strong>
      </div>
      <Show condition={admin}>
        <div>
          Modo de cobrança
          <ModoCobranca />
        </div>
      </Show>
      <Show condition={admin}>
        <div>
          Emite nota fiscal via seplac.net
          <EmiteNotaFiscal />
        </div>
      </Show>
    </Layout>
  )
}
Empresa.Layout = styled.div`
  & > div {
    margin-bottom: 12px;
  }
`

const ModoCobranca = () => {
  const { empresa } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleChange(sistema) {
    dispatch(
      Store.thunks.updateEmpresa({
        id: empresa?.id,
        infoCobranca: { ...empresa?.infoCobranca, sistema },
      })
    )
  }

  return (
    <div>
      <div>
        <Select
          style={{ width: 218 }}
          placeholder="Escolha um sistema de cobrança"
          value={empresa?.infoCobranca?.sistema ?? ''}
          onChange={handleChange}
        >
          <Select.Option value="">Nenhum</Select.Option>
          <Select.Option value="GOPLACAS">Go Placas</Select.Option>
          <Select.Option value="EMPLACAFACIL">Emplacafácil </Select.Option>
          <Select.Option value="EMPLACARGOIAS"> Emplacargoiás </Select.Option>
        </Select>
      </div>
    </div>
  )
}

function EmiteNotaFiscal() {
  const { empresa } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleChange(e) {
    dispatch(
      Store.thunks.updateEmpresa({
        id: empresa?.id,
        emiteNotaFiscal: !empresa?.emiteNotaFiscal,
      })
    )
  }

  return (
    <div>
      <Radio.Group
        buttonStyle="solid"
        value={empresa?.emiteNotaFiscal || false}
        onChange={handleChange}
      >
        <Radio.Button type="primary" value={true}>
          Sim
        </Radio.Button>
        <Radio.Button type="primary" value={false}>
          Não
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

const InlineEdit = ({ field }) => {
  const { empresa } = Store.useState()
  const dispatch = Store.useDispatch()

  const [isEditing, setIsEditing] = React.useState(false)
  const [value, setValue] = React.useState(empresa?.[field])

  function handleChange(e) {
    setValue(e.target.value)
  }

  async function handleClick() {
    dispatch(
      Store.thunks.updateEmpresa({
        id: empresa?.id,
        [field]: value,
      })
    )
  }

  React.useEffect(() => {
    setValue(empresa?.[field])
    setIsEditing(false)
  }, [empresa?.[field]])

  if (isEditing) {
    return (
      <>
        <TextFieldInput value={value} onChange={handleChange} />
        <br />
        <Button onClick={handleClick}>Salvar</Button>
        <Button onClick={() => setIsEditing(false)}>Cancelar</Button>
      </>
    )
  }
  return (
    <>
      <strong>{empresa?.[field] || ' - '}</strong>
      <RestrictedResource permissoes={[PERMISSOES.GERENCIAR_EMPRESAS]} renderWhenForbidden={null}>
        <Button onClick={() => setIsEditing(true)}>Editar</Button>
      </RestrictedResource>
    </>
  )
}

const NovoUsuario = () => {
  const { usuarioEmpresaAtual, empresa } = Store.useState()
  const dispatch = Store.useDispatch()

  const formik = useFormik({
    initialValues: usuarioEmpresaAtual?.usuario || {
      id: 0,
      nome: '',
      cpf: '',
    },
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        const usuarioEmpresa = await reqSalvaUsuario().then((x) => x.data)
        dispatch(Store.actions.saveUsuarioSuccess(usuarioEmpresa))
        notification.success({ message: 'Usuário salvo com sucesso' })
      } catch (error: any) {
        const message = error.response?.data?.message ?? 'Ocoreu um erro ao salvar o usuário'
        notification.error({ message })
      }
    },
  })

  const [{ loading }, reqSalvaUsuario] = useAxios(
    {
      method: 'POST',
      url: '/usuarios-empresas',
      data: {
        ...formik.values,
        usuarioId: formik.values?.id,
        empresaId: empresa?.id,
        permissoes: usuarioEmpresaAtual?.permissoes,
      },
    },
    {
      manual: true,
    }
  )

  async function handleChangeCpf(e) {
    const cpf = e.target.value
    if (cpf?.includes('_')) return
    try {
      const usuario = await axios.get(`/usuarios?cpf=${cpf}`).then((x) => x.data)
      if (usuario.length) {
        dispatch(
          Store.actions.editUsuario({
            ...(usuarioEmpresaAtual || {}),
            usuario: usuario[0],
          })
        )
      }
    } catch (error: any) {}
  }

  function handleClickCancelar() {
    dispatch(Store.actions.editUsuario({} as UsuarioEmpresaModel))
    formik.resetForm()
  }

  return (
    <Card>
      <h2>Novo Usuário</h2>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <TextField label="CPF" name="cpf" mask="cpf" onChange={handleChangeCpf} />
          <TextField label="Nome" name="nome" />
          <Permissoes />
          <DisplayFlex spaceBetween={12}>
            <Button htmlType="submit" type="primary" loading={loading}>
              Salvar
            </Button>
            <Button onClick={handleClickCancelar}> Cancelar</Button>
          </DisplayFlex>
        </Form>
      </FormikProvider>
    </Card>
  )
}

const Permissoes = () => {
  const permissoesPorTipo = Store.useSelector(selectPermissoesPorTipo)
  return (
    <div>
      {permissoesPorTipo.map((p) => (
        <Permissao key={p} permissao={p} />
      ))}
    </div>
  )
}

const Permissao = ({ permissao }: { permissao: PERMISSOES }) => {
  const { usuarioEmpresaAtual } = Store.useState()
  const dispatch = Store.useDispatch()

  const hasPermissao = usuarioEmpresaAtual?.permissoes?.includes(permissao)

  function handleChange() {
    dispatch(Store.actions.togglePermissao(permissao))
  }

  return (
    <div>
      <label>
        <Checkbox checked={hasPermissao} onChange={handleChange} /> {permissao}
      </label>
    </div>
  )
}

const ListaUsuarios = () => {
  const { usuariosEmpresas: usuarios } = Store.useState()
  const dispatch = Store.useDispatch()
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)

  const isAdmin = usuarioLogado?.admin

  function handleClickEditar(usuarioEmpresa: UsuarioEmpresaModel) {
    dispatch(Store.actions.editUsuario(usuarioEmpresa))
  }

  async function handleClickExcluir(usuarioEmpresa: UsuarioEmpresaModel) {
    try {
      await axios
        .delete(`/usuarios-empresas/${usuarioEmpresa.usuarioId}`, {
          data: { empresaId: usuarioEmpresa.empresaId },
        })
        .then((x) => x.data)
      notification.success({ message: 'Usuário excluído com sucesso' })
      dispatch(Store.actions.deleteUsuarioSuccess(usuarioEmpresa.usuarioId))
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao excluir o usuário'
      notification.error({ message })
    }
  }

  async function handleClickResetarSenha(usuarioEmpresa: UsuarioEmpresaModel) {
    try {
      await axios.put('/usuarios/reseta-senha', { usuarioId: usuarioEmpresa.usuarioId })
      notification.success({
        message: 'Senha resetada para os 6 primeiros digitos do CPF com sucesso!',
      })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao resetar a senha'
      notification.error({ message })
    }
  }

  return (
    <Table
      dataSource={usuarios}
      columns={[
        { title: 'Id', render: (row) => row.usuario.id },
        { title: 'CPF', render: (row) => row.usuario.cpf },
        { title: 'Nome', render: (row) => row.usuario.nome },
        {
          title: 'Permissões',
          render: (row) => (
            <Tooltip
              overlayClassName="tooltip-permissoes"
              title={<ListaPermissoesSimples permissoes={row.permissoes ? row.permissoes : ''} />}
            >
              <div>
                {row?.permissoes?.length ?? 0} <InfoCircleOutlined />
              </div>
            </Tooltip>
          ),
        },
        {
          title: '',
          render: (row) => (
            <DisplayFlex spaceBetween={12}>
              <Button onClick={() => handleClickEditar(row)}>Editar</Button>
              <Button onClick={async () => await handleClickExcluir(row)}>Excluir</Button>
              <Show condition={isAdmin}>
                <Button onClick={async () => await handleClickResetarSenha(row)}>
                  Resetar Senha
                </Button>
              </Show>
            </DisplayFlex>
          ),
        },
      ]}
    />
  )
}

const RelatorioProdutosPorTipo = () => {
  const { empresaId } = useParams<{ empresaId }>()
  const [{ data }] = useAxios(`/produtos/agrupado?empresaId=${empresaId}`)
  return (
    <Card>
      {data?.map((x) => {
        return (
          <div key={x.id}>
            {TIPOS_PRODUTOS[x.tipoProdutoId]}: <strong>{x.total}</strong>
          </div>
        )
      })}
    </Card>
  )
}

const useMessage = (
  data,
  error,
  msgSuccess = 'Operação bem sucedida!',
  defaultMessageError = 'Ocorreu um erro inesperado'
) => {
  React.useEffect(() => {
    if (!data) return
    notification.success({ message: msgSuccess })
  }, [data, msgSuccess])
  React.useEffect(() => {
    if (!error) return
    const message = error.response?.data?.message ?? defaultMessageError
    notification.error({ message })
  }, [error, defaultMessageError])
}

const GerenciarEstoqueMinimo = () => {
  const { empresaId } = useParams<{ empresaId }>()
  const { empresa } = Store.useState()
  const [estoqueMinimo, setEstoqueMinimo] = React.useState({
    carro: empresa?.estoqueMinimoPlacaCarro ?? '',
    moto: empresa?.estoqueMinimoPlacaMoto ?? '',
  })

  const [{ data, error, loading }, reqSalvarEstoqueMinimo] = useAxios(
    {
      url: '/empresas/estoque-minimo',
      data: {
        estoqueMinimoPlacaMoto: +estoqueMinimo.moto,
        estoqueMinimoPlacaCarro: +estoqueMinimo.carro,
        empresaId: +empresaId,
      },
      method: 'PUT',
    },
    { manual: true }
  )
  useMessage(
    data,
    error,
    'Estoque mínimo salvo com sucesso',
    'Não foi possível salvar o estoque mínimo'
  )

  return (
    <Card style={{ marginTop: 12 }}>
      <h3>Estoque Mínimo</h3>
      <label>
        Carro
        <Input
          value={estoqueMinimo.carro}
          onChange={(e) => setEstoqueMinimo({ ...estoqueMinimo, carro: e.target.value })}
        />
      </label>
      <label>
        Moto
        <Input
          value={estoqueMinimo.moto}
          onChange={(e) => setEstoqueMinimo({ ...estoqueMinimo, moto: e.target.value })}
        />
      </label>
      <Button onClick={async () => await reqSalvarEstoqueMinimo()} loading={loading}>
        Salvar
      </Button>
    </Card>
  )
}

const ListaPermissoesSimples = ({ permissoes }) => {
  if (!permissoes?.length) return null
  return (
    <ul>
      {permissoes.map((p) => (
        <li key={p}>{p}</li>
      ))}
    </ul>
  )
}

const EnviarNovoCertificado = () => {
  const { empresaId } = useParams<{ empresaId }>()
  const [senha, setSenha] = React.useState('')
  const [validade, setValidade] = React.useState('')
  const ref = React.useRef<HTMLInputElement>(null)
  const dispatch = Store.useDispatch()
  const { empresa } = Store.useState()

  const [{ loading }, reqEnviaCertificado] = useAxios(
    {
      url: `/empresas/certificado/${empresaId}`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    {
      manual: true,
    }
  )

  function handleChangeSenha(e) {
    setSenha(e.target.value)
  }

  function handleChangeValidade(e) {
    setValidade(e.target.value)
  }

  async function enviaCertificado() {
    const data = new FormData()
    const file = ref.current?.files?.[0]
    if (!file) return notification.error({ message: 'Selecione o certificado' })
    if (!senha) return notification.error({ message: 'Informe a senha' })
    if (!validade) return notification.error({ message: 'Informe a validade' })
    data.append('cert', file)
    data.append('validade', validade.split('/').reverse().join('-') + 'T12:00:00.000Z')
    data.append('senha', senha)
    try {
      const responseEnviaCertificado = await reqEnviaCertificado({ data }).then((x) => x.data)
      const { dataValidadeCertificado } = responseEnviaCertificado
      dispatch(Store.actions.updateDataCertificado(dataValidadeCertificado))
      notification.success({ message: 'Certificado atualizado com sucesso' })
      setSenha('')
      setValidade('')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao enviar o certificado'
      notification.error({ message })
    }
  }

  return (
    <div>
      <InfoValidadeCertificado empresa={empresa} />

      <label>
        Selecione o certificado
        <br />
        <input ref={ref} type="file" accept=".pfx" />
      </label>
      <br />
      <br />

      <label>
        Senha do certificado
        <br />
        <Input value={senha} onChange={handleChangeSenha} style={{ width: 400 }} />
      </label>
      <br />
      <br />

      <label>
        Data de expiração
        <br />
        <TextFieldInput
          mask="99/99/9999"
          value={validade}
          onChange={handleChangeValidade}
          style={{ width: 400 }}
        />
      </label>
      <br />
      <br />

      <Button loading={loading} onClick={enviaCertificado}>
        Enviar
      </Button>
    </div>
  )
}

const InfoValidadeCertificado = ({ empresa }: { empresa: EmpresaModel | null }) => {
  const dataValidadeCertificado = empresa?.dataValidadeCertificado
    ? parseISO(empresa.dataValidadeCertificado)
    : null

  const certVenceEm = dataValidadeCertificado
    ? formatDistance(dataValidadeCertificado, new Date(), { locale: ptBR })
    : null

  const dataExpiracao = dataValidadeCertificado
    ? format(dataValidadeCertificado, 'dd/MM/yyyy HH:mm')
    : null

  if (!dataValidadeCertificado) return null
  return (
    <>
      <Show condition={dataValidadeCertificado > new Date()}>
        <span>
          Certificado Expira:{' '}
          <strong>
            {dataExpiracao} ({certVenceEm})
          </strong>
        </span>
      </Show>
      <Show condition={dataValidadeCertificado < new Date()}>
        <span>
          Certificado Expirado: <strong>{dataExpiracao}</strong>
        </span>
      </Show>
      <br />
    </>
  )
}

export const ListaAuditorias = () => {
  const history = useHistory()
  const dispatch = AuthStore.useDispatch()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const { empresa } = Store.useState()
  const {
    Layout,
    ContainerNovaAuditoria,
    ContainerEditarAuditoria,
    ContainerVisualizarAuditoriaConcluida,
  } = ListaAuditorias

  const [showModalNovaAuditoria, setShowModalNovaAuditoria] = React.useState(false)
  const closeModalNovaAuditoria = () => setShowModalNovaAuditoria(false)

  const [showModalEditarAuditoria, setShowModalEditarAuditoria] = React.useState(false)
  const closeModalEditarAuditoria = () => setShowModalEditarAuditoria(false)

  const [showModalVisualizarAuditoria, setShowModalVisualizarAuditoria] = React.useState(false)
  const closeModalVisualizarAuditoria = () => setShowModalVisualizarAuditoria(false)

  const [auditoriaId, setAuditoriaId] = useState()

  const [{ data: auditorias }, getAuditorias] = useAxios(
    {
      url: '/auditorias',
      params: {
        empresaId: empresa?.id,
      },
    },
    { manual: true }
  )

  useEffect(() => {
    if (empresaAtiva?.id === empresa?.id) return
    getAuditorias()
  }, [])

  if (empresaAtiva?.id === empresa?.id) {
    return <Layout>Essa Empresa não pode ser auditada</Layout>
  }

  function auditar(id, data) {
    dispatch(AuthStore.actions.setEmpresaAtiva(empresa!.id))
    history.push(`/produtos/auditorias/${id}`, { data })
  }

  return (
    <Layout>
      <ContainerNovaAuditoria>
        <Button onClick={() => setShowModalNovaAuditoria(true)}>Nova Auditoria</Button>
        <ModalNovaAuditoria
          showModal={showModalNovaAuditoria}
          closeModal={closeModalNovaAuditoria}
          getAuditorias={getAuditorias}
        />
      </ContainerNovaAuditoria>

      <ContainerEditarAuditoria>
        <ModalEditarAuditoria
          auditoriaId={auditoriaId}
          showModal={showModalEditarAuditoria}
          closeModal={closeModalEditarAuditoria}
          getAuditorias={getAuditorias}
        />
      </ContainerEditarAuditoria>

      <ContainerVisualizarAuditoriaConcluida>
        {showModalVisualizarAuditoria && (
          <ModalVisualAuditoriaConcluida
            showModal={showModalVisualizarAuditoria}
            closeModal={closeModalVisualizarAuditoria}
            auditoriaId={auditoriaId}
          />
        )}
      </ContainerVisualizarAuditoriaConcluida>

      <Card title="Auditorias">
        <Table
          columns={[
            { title: 'Id', dataIndex: 'id' },
            {
              title: 'Data Início',
              render: (row) => <>{format(new Date(row.dataInicio), 'dd/MM/yyyy')}</>,
            },
            {
              title: 'Data Fim',
              render: (row) => (
                <div>
                  {format(new Date(row.dataFim), 'dd/MM/yyyy')}
                  {!row.dataConclusao && new Date(row.dataFim) < new Date() && (
                    <Button
                      title="ALTERAR DATA FIM"
                      type="text"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setShowModalEditarAuditoria(true)
                        setAuditoriaId(row.id)
                      }}
                    />
                  )}
                </div>
              ),
            },
            { title: 'Justificativa Prorrogação', render: (row) => <>{row.justificativa}</> },
            {
              title: 'Data Conclusão',
              render: (row) => (
                <>{row.dataConclusao && format(new Date(row.dataConclusao), 'dd/MM/yyyy')}</>
              ),
            },
            { title: 'Usuário', render: (row) => <>{row.usuario.nome}</> },
            {
              title: 'Pendências',
              render: (row) => (
                <strong style={{ color: 'blue' }}>
                  {((row.produtosNãoAuditado / row.produtosTotal) * 100).toFixed(0)}%
                </strong>
              ),
            },
            {
              render: (row) =>
                row.dataConclusao && (
                  <strong key={row.id}>
                    Concluído
                    <Button
                      icon={<EyeOutlined />}
                      onClick={() => {
                        setShowModalVisualizarAuditoria(true)
                        setAuditoriaId(row.id)
                      }}
                    />
                  </strong>
                ),
            },
            {
              render: (row) => (
                <Tooltip key={row.id} title="Auditar">
                  <Button icon={<CheckOutlined />} onClick={() => auditar(row.id, row)} />
                </Tooltip>
              ),
            },
          ]}
          dataSource={auditorias || []}
        />
      </Card>
    </Layout>
  )
}
ListaAuditorias.Layout = styled.div`
  .ant-card-head-title {
    font-weight: bold;
    font-size: 18px;
    padding: 10px 0;
  }
`
ListaAuditorias.ContainerNovaAuditoria = styled.div`
  margin-bottom: 10px;
`
ListaAuditorias.ContainerEditarAuditoria = styled.div`
  margin-bottom: 10px;
`
ListaAuditorias.ContainerVisualizarAuditoriaConcluida = styled.div`
  margin-bottom: 10px;
`

const ModalVisualAuditoriaConcluida = ({ showModal, closeModal, auditoriaId }) => {
  const [{ data: auditoria, loading }, getAuditoriaConcluida] = useAxios<IAuditoria>(
    {
      url: '/auditorias/auditoria-concluida',
      params: {
        auditoriaId,
      },
    },
    { manual: true }
  )
  const { Container, ProdutosAuditados } = ModalVisualAuditoriaConcluida

  const dataInicio = auditoria?.dataInicio
    ? format(new Date(auditoria.dataInicio), 'dd/MM/yyyy')
    : null
  const dataFim = auditoria?.dataFim ? format(new Date(auditoria.dataFim), 'dd/MM/yyyy') : null

  useEffect(() => {
    if (!auditoriaId) return
    getAuditoriaConcluida()
  }, [auditoriaId])

  return (
    <Modal width={'80%'} visible={showModal} onCancel={closeModal} footer={null}>
      <Spin spinning={loading} tip="Salvando...">
        <h2>
          Auditoria Concluída - {dataInicio} à {dataFim}
        </h2>

        <Container>
          <Tabs style={{ width: '100%' }}>
            <Tabs.TabPane tab="Produtos" key="1">
              <ProdutosAuditados auditoria={auditoria} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Boletim de Ocorrência" key="2" style={{ width: '100%' }}>
              <BoletimOcorrencia auditoria={auditoria} auditoriaId={auditoriaId} />
            </Tabs.TabPane>
          </Tabs>
        </Container>
      </Spin>
    </Modal>
  )
}
ModalVisualAuditoriaConcluida.Container = styled.div`
  display: flex;
  justify-content: space-between;
`
ModalVisualAuditoriaConcluida.ProdutosAuditados = ({ auditoria }: { auditoria?: IAuditoria }) => {
  return (
    <>
      {auditoria?.auditoriasProdutos?.map((auditoriasProduto, key) => (
        <div key={key}>
          <div>
            {auditoriasProduto?.produto?.serial} - Lote: {auditoriasProduto?.produto?.loteId} -
            Status: {auditoriasProduto?.produto?.status}
          </div>
        </div>
      ))}
    </>
  )
}
export const BoletimOcorrencia = ({
  auditoria,
  auditoriaId,
}: {
  auditoria?: { fileNames?: string[] }
  auditoriaId: number
}) => {
  const { Container, ListarDocumentos, VisualizarDocumentos } = BoletimOcorrencia
  const [files, setFiles] = useState<string[]>([])
  const [indexFile, setIndexFile] = useState<number>(0)
  const [downloadProgress, setDownloadProgress] = useState<{
    progress?: number
    success?: boolean
    loading?: boolean
  }>()

  useEffect(() => {
    setFiles([])
    setIndexFile(0)
  }, [auditoriaId])

  useEffect(() => {
    ;(async () => {
      downloadFile({ indexFile, saveFile: false })
    })()
  }, [indexFile, auditoria])

  const downloadFile = async ({
    indexFile,
    saveFile,
  }: {
    indexFile: number
    saveFile: boolean
  }) => {
    if (!auditoria) return
    if (!auditoria?.fileNames) return
    if (!auditoria?.fileNames[indexFile]) return
    if (files[indexFile]) {
      if (saveFile) saveAsFile({ blob: files[indexFile], indexFile })
      return
    }

    try {
      const linkdownload = await axios
        .get('/download-files/url', {
          params: {
            containerName: 'boletim-ocorrencia',
            blobName: auditoria.fileNames[indexFile],
          },
        })
        .then((res) => res.data)

      const newAxios = new Axios({ responseType: 'blob' })

      const blob = await newAxios
        .get(linkdownload, {
          onDownloadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)

            setDownloadProgress((previous) => {
              return {
                ...previous,
                progress,
                loading: true,
                success: false,
              }
            })
          },
        })
        .then((res) => res.data)

      setFiles((prev) => {
        prev[indexFile] = URL.createObjectURL(blob)
        return [...prev]
      })

      setDownloadProgress((previous) => {
        return {
          ...previous,
          progress: 0,
          loading: false,
          success: true,
        }
      })

      if (saveFile) saveAsFile({ blob, indexFile })
    } catch (error) {
      console.log(error)
      setDownloadProgress((previous) => {
        return {
          ...previous,
          progress: 0,
          loading: false,
          success: false,
        }
      })
    }
  }

  const saveAsFile = ({ blob, indexFile }: { blob: string; indexFile: number }) => {
    saveAs(blob, `Auditoria ${auditoriaId} - Documento ${indexFile + 1}`)
  }

  return (
    <Container>
      <ListarDocumentos>
        {auditoria?.fileNames?.map((file, key) => (
          <div key={key} style={{ display: 'flex', marginBottom: 5, alignItems: 'center' }}>
            <div style={{ marginRight: 5 }}>{`Documento ${key + 1}`}</div>
            <Button
              title="Visualizar"
              disabled={downloadProgress?.loading}
              icon={<EyeOutlined />}
              onClick={() => {
                setIndexFile(key)
              }}
            />
            <Button
              title="Baixar"
              disabled={!auditoria?.fileNames?.length || downloadProgress?.loading}
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                downloadFile({ indexFile: key, saveFile: true })
              }}
            />
          </div>
        ))}
      </ListarDocumentos>

      <VisualizarDocumentos>
        <Button
          title="Voltar"
          disabled={indexFile - 1 < 0 || downloadProgress?.loading}
          icon={<LeftCircleOutlined />}
          onClick={() => {
            setIndexFile((prev) => {
              if (prev - 1 < 0) return prev
              return prev - 1
            })
          }}
        />
        <Button
          title="Avançar"
          disabled={
            (auditoria?.fileNames && auditoria?.fileNames.length - 1) === indexFile ||
            downloadProgress?.loading
          }
          icon={<RightCircleOutlined />}
          onClick={() => {
            if (!auditoria?.fileNames) return
            setIndexFile((prev) => {
              if (auditoria?.fileNames && auditoria?.fileNames.length - 1 === prev) return prev
              return prev + 1
            })
          }}
        />
        {downloadProgress?.loading && (
          <Progress width={100} type="circle" percent={downloadProgress.progress} />
        )}
        {files[indexFile] && (
          <>
            <div>Documento {indexFile + 1}</div>
            <iframe src={files[indexFile]} width={'100%'} height={600}>
              Selecione o arquivo para visualizar.
            </iframe>
          </>
        )}
      </VisualizarDocumentos>
    </Container>
  )
}
BoletimOcorrencia.Container = styled.div``
BoletimOcorrencia.ListarDocumentos = styled.div`
  padding: 5px;
  margin-bottom: 5px;
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #cbcbcb;
`
BoletimOcorrencia.VisualizarDocumentos = styled.div`
  padding: 5px;
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #cbcbcb;
`

const ModalEditarAuditoria = ({ auditoriaId, showModal, closeModal, getAuditorias }) => {
  const { Container, Label } = ModalEditarAuditoria
  const { TextArea } = Input
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const [isoDataFim, setIsoDataFim] = useState('')
  const [justificativa, setJustificativa] = useState('')
  const [{ loading }, reqUpdateAuditoria] = useAxios(
    {
      url: '/auditorias',
      method: 'PUT',
    },
    { manual: true }
  )

  function setDataFim(dataFim) {
    const dt = new Date(dataFim._d)
    setIsoDataFim(dt.toISOString())
  }

  function disabledDate(current) {
    return current && current < moment().add(-1, 'days')
  }

  async function handleSubmit() {
    const auditoriaObj = {
      id: auditoriaId,
      dataFim: isoDataFim,
      justificativa,
    }

    if (!usuarioLogado?.admin) {
      return notification.error({
        message: 'Para criar uma auditoria é preciso ser um administrador',
      })
    }

    if (!auditoriaObj.dataFim) {
      return notification.error({ message: 'Data fim é obrigatória' })
    }
    if (!auditoriaObj.justificativa) {
      return notification.error({ message: 'Justificativa é obrigatória' })
    }

    try {
      await reqUpdateAuditoria({ data: auditoriaObj })
      getAuditorias()
      notification.success({ message: 'Auditoria atualizado com sucesso' })
      closeModal()
    } catch (error: any) {
      const message = error.response?.data?.message
      notification.error({ message })
      //notification.error({ message: 'Ocorreu um erro ao criar a auditoria' })
    }
  }

  return (
    <Modal width={450} visible={showModal} onCancel={closeModal} footer={null}>
      <Spin spinning={loading} tip="Salvando...">
        <h2>Informe a data final para a auditoria</h2>
        <Container>
          <div>
            <DatePicker format="DD/MM/YYYY" onChange={setDataFim} disabledDate={disabledDate} />
          </div>
          <Label>
            Justificativa
            <TextArea
              style={{ height: 120, resize: 'none' }}
              showCount={false}
              onChange={(e) => setJustificativa(e.target.value)}
            />
          </Label>
          <Button onClick={handleSubmit}>Salvar</Button>
        </Container>
      </Spin>
    </Modal>
  )
}
ModalEditarAuditoria.Container = styled.div`
  display: flex;
  flex-direction: column;
`
ModalEditarAuditoria.Label = styled.label`
  margin-top: 5px;
  margin-bottom: 5px;
`

const ModalNovaAuditoria = ({ showModal, closeModal, getAuditorias }) => {
  const { empresa } = Store.useState()
  const { Container } = ModalNovaAuditoria
  const [{ loading }, reqCreateAuditoria] = useAxios(
    {
      url: '/auditorias',
      method: 'POST',
    },
    { manual: true }
  )

  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const [isoDataFim, setIsoDataFim] = useState('')

  async function handleSubmit() {
    if (!usuarioLogado?.admin) {
      return notification.error({
        message: 'Para criar uma auditoria é preciso ser um administrador',
      })
    }

    const auditoriaObj = {
      dataInicio: new Date().toISOString(),
      dataFim: isoDataFim,
      empresaId: empresa!.id,
    }

    try {
      await reqCreateAuditoria({ data: auditoriaObj })
      getAuditorias()
      notification.success({ message: 'Auditoria criada com sucesso' })
      closeModal()
    } catch (error: any) {
      const message = error.response?.data?.message
      notification.error({ message })
      //notification.error({ message: 'Ocorreu um erro ao criar a auditoria' })
    }
  }

  function setDataFim(dataFim) {
    const dt = new Date(dataFim._d)
    setIsoDataFim(dt.toISOString())
  }

  function disabledDate(current) {
    return current && current < moment().add(-1, 'days')
  }

  return (
    <Modal width={450} visible={showModal} onCancel={closeModal} footer={null}>
      <Spin spinning={loading} tip="Salvando...">
        <h2>Informe a data final para a auditoria</h2>
        <Container>
          <DatePicker format="DD/MM/YYYY" onChange={setDataFim} disabledDate={disabledDate} />
          <Button onClick={handleSubmit}>Salvar</Button>
        </Container>
      </Spin>
    </Modal>
  )
}
ModalNovaAuditoria.Container = styled.div`
  display: flex;
  justify-content: space-between;
`
