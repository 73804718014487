export function CloseCircle() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.69168 0C6.17041 0 4.6833 0.451109 3.41841 1.29628C2.15352 2.14145 1.16766 3.34273 0.585497 4.7482C0.00333261 6.15367 -0.148988 7.70021 0.147797 9.19225C0.444582 10.6843 1.17714 12.0548 2.25284 13.1305C3.32854 14.2062 4.69907 14.9388 6.19111 15.2356C7.68315 15.5323 9.22969 15.38 10.6352 14.7979C12.0406 14.2157 13.2419 13.2298 14.0871 11.9649C14.9323 10.7001 15.3834 9.21295 15.3834 7.69168C15.3834 5.65172 14.573 3.69531 13.1305 2.25284C11.688 0.810371 9.73164 0 7.69168 0ZM11.5375 10.6241C11.665 10.7516 11.7366 10.9246 11.7366 11.1049C11.7366 11.2852 11.665 11.4581 11.5375 11.5856C11.41 11.7131 11.2371 11.7847 11.0568 11.7847C10.8765 11.7847 10.7036 11.7131 10.5761 11.5856L7.69168 8.70121L4.8073 11.5952C4.74417 11.6583 4.66923 11.7084 4.58674 11.7426C4.50426 11.7767 4.41585 11.7943 4.32657 11.7943C4.23729 11.7943 4.14889 11.7767 4.0664 11.7426C3.98392 11.7084 3.90897 11.6583 3.84584 11.5952C3.78271 11.5321 3.73263 11.4571 3.69847 11.3746C3.6643 11.2922 3.64672 11.2038 3.64672 11.1145C3.64672 11.0252 3.6643 10.9368 3.69847 10.8543C3.73263 10.7718 3.78271 10.6969 3.84584 10.6337L6.73022 7.73014L3.76412 4.74C3.63662 4.6125 3.56499 4.43958 3.56499 4.25927C3.56499 4.07896 3.63662 3.90603 3.76412 3.77854C3.89162 3.65104 4.06454 3.57941 4.24485 3.57941C4.42516 3.57941 4.59808 3.65104 4.72558 3.77854L7.69168 6.77829L10.6578 3.81219C10.7209 3.74906 10.7959 3.69898 10.8783 3.66481C10.9608 3.63065 11.0492 3.61306 11.1385 3.61306C11.2278 3.61306 11.3162 3.63065 11.3987 3.66481C11.4812 3.69898 11.5561 3.74906 11.6192 3.81219C11.6824 3.87532 11.7325 3.95026 11.7666 4.03275C11.8008 4.11523 11.8184 4.20364 11.8184 4.29292C11.8184 4.3822 11.8008 4.4706 11.7666 4.55309C11.7325 4.63557 11.6824 4.71052 11.6192 4.77365L8.65314 7.73014L11.5375 10.6241Z"
        fill="#FF5555"
      />
    </svg>
  )
}
