import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { notification, Button } from 'antd'
import useAxios from 'axios-hooks'
import { formatISO, parseISO, format } from 'date-fns'
import { EmplacamentoModel, PedidoModel, PERMISSOES } from '../Models'
import { checkPermissao } from '../checkPermissao'
import { AuthStore, getPermissoesEmpresa } from '../../auth/AuthStore'

interface IProps {
  pedido: PedidoModel
  onUpdate?: (pedido: PedidoModel) => any
  onOpenModalNotaFiscal?: () => any
}

export function StatusEmplacamentoDetran(props: IProps) {
  return (
    <div>
      <StatusNotaFiscal {...props} />
      <StatusImagensInstalacao {...props} />
      <StatusEnvioInformacaoInstalacao {...props} />
    </div>
  )
}

function StatusNotaFiscal({ onOpenModalNotaFiscal, onUpdate, pedido }: IProps) {
  const permissoes = AuthStore.useSelector(getPermissoesEmpresa)

  const [{ loading: loadingReenviar }, req] = useAxios(
    {
      url: '/emplacamentos/reenvia-nota-fiscal-detran-go',
      method: 'post',
      data: { pedidoId: pedido?.id },
    },
    { manual: true }
  )

  async function handleClickInformar() {
    if (!checkPermissao([PERMISSOES.INFORMAR_NF], permissoes)) {
      return notification.warn({ message: 'Você não tem permissão para informar a nota fiscal' })
    }
    onOpenModalNotaFiscal?.()
  }

  async function handleClickReenviar() {
    try {
      const responseReenvio = await req().then((x) => x.data)
      if (responseReenvio.status === 'INFORMADO_DETRAN') {
        const novoPedido = getNovoPedido(pedido, { dataEnvioNfeDetranGo: formatISO(new Date()) })
        onUpdate?.(novoPedido)
        return notification.success({ message: 'Reenviado ao detran com sucesso' })
      }
      return notification.error({ message: 'Ocorreu um erro ao enviar a NF ao detran' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao reenviar a NF'
      notification.error(message)
    }
  }

  if (!pedido?.emplacamento.numeroNF) {
    return (
      <StatusBlockLayout style={{ marginTop: 0 }}>
        <InfoCircleOutlined style={{ color: 'red' }} /> Nota Fiscal
        <div className="flex-1" />
        <Button size="small" onClick={handleClickInformar}>
          Informar
        </Button>
      </StatusBlockLayout>
    )
  }
  if (!pedido?.emplacamento.dataEnvioNfeDetranGo) {
    return (
      <StatusBlockLayout style={{ marginTop: 0 }}>
        <InfoCircleOutlined style={{ color: 'orange' }} /> Nota Fiscal
        <div className="flex-1" />
        <Button size="small" onClick={handleClickReenviar} loading={loadingReenviar}>
          Reenviar
        </Button>
      </StatusBlockLayout>
    )
  }
  return (
    <StatusBlockLayout style={{ marginTop: 0 }}>
      <CheckCircleOutlined style={{ color: 'green' }} /> Nota Fiscal
      <div className="flex-1" />
      {format(parseISO(pedido.emplacamento.dataEnvioNfeDetranGo), 'dd/MM HH:mm')}
    </StatusBlockLayout>
  )
}

function StatusImagensInstalacao({ pedido, onUpdate }: IProps) {
  const [{ loading: loadingReenviar }, req] = useAxios(
    {
      url: '/emplacamentos/reenvia-imagens-detran-go',
      method: 'post',
      data: { pedidoId: pedido?.id },
    },
    { manual: true }
  )

  async function handleClickReenviar() {
    try {
      const responseReenvio = await req().then((x) => x.data)
      if (responseReenvio.status === 'INFORMADO_DETRAN') {
        const novoPedido = getNovoPedido(pedido, {
          dataEnvioImagensInstalacaoDetranGo: formatISO(new Date()),
        })
        onUpdate?.(novoPedido)
        return notification.success({ message: 'Reenviado ao detran com sucesso' })
      }
      if (responseReenvio.reason === 'Não existem imagens para efetuar o envio') {
        return notification.error({ message: responseReenvio.reason })
      }
      return notification.error({ message: 'Ocorreu um erro ao enviar as imagens ao detran' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao reenviar as imagens'
      notification.error(message)
    }
  }

  if (!pedido?.emplacamento.dataEnvioImagensInstalacaoDetranGo) {
    return (
      <StatusBlockLayout>
        <InfoCircleOutlined style={{ color: 'orange' }} /> Imagens
        <div className="flex-1" />
        <Button size="small" loading={loadingReenviar} onClick={handleClickReenviar}>
          Reenviar
        </Button>
      </StatusBlockLayout>
    )
  }
  return (
    <StatusBlockLayout>
      <CheckCircleOutlined style={{ color: 'green' }} /> Imagens
      <div className="flex-1" />
      {format(parseISO(pedido?.emplacamento.dataEnvioImagensInstalacaoDetranGo), 'dd/MM HH:mm')}
    </StatusBlockLayout>
  )
}

function StatusEnvioInformacaoInstalacao({ pedido, onUpdate }: IProps) {
  const [{ loading: loadingReenviar }, req] = useAxios(
    {
      url: '/emplacamentos/reenvia-finalizacao-detran-go',
      method: 'post',
      data: { pedidoId: pedido?.id },
    },
    { manual: true }
  )

  async function handleClickReenviar() {
    try {
      const responseReenvio = await req().then((x) => x.data)
      if (responseReenvio.status === 'INFORMADO_DETRAN') {
        const novoPedido = getNovoPedido(pedido, {
          dataEnvioEmplacamentoDetranGo: formatISO(new Date()),
        })
        onUpdate?.(novoPedido)
        return notification.success({ message: 'Reenviado ao detran com sucesso' })
      }
      return notification.error({ message: 'Ocorreu um erro ao enviar instalação ao detran' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao reenviar instalação'
      notification.error(message)
    }
  }

  if (!pedido?.emplacamento.dataEnvioEmplacamentoDetranGo) {
    return (
      <StatusBlockLayout>
        <InfoCircleOutlined style={{ color: 'orange' }} /> Instalação
        <div className="flex-1" />
        <Button size="small" loading={loadingReenviar} onClick={handleClickReenviar}>
          Reenviar
        </Button>
      </StatusBlockLayout>
    )
  }
  return (
    <StatusBlockLayout>
      <CheckCircleOutlined style={{ color: 'green' }} /> Instalação
      <div className="flex-1" />
      {format(parseISO(pedido?.emplacamento.dataEnvioEmplacamentoDetranGo), 'dd/MM HH:mm')}
    </StatusBlockLayout>
  )
}

const StatusBlockLayout = styled.div`
  border: 1px solid #e7e7e7;
  padding: 0 12px;
  height: 35px;
  font-size: 0.9em;
  display: flex;
  margin-top: 4px;
  align-items: center;
  background: white;
  svg {
    margin-right: 4px;
  }

  .flex-1 {
    flex: 1;
    min-width: 12px;
  }
  button {
    margin-left: 4px;
  }
`

function getNovoPedido(
  pedido: PedidoModel | null,
  emplacamento: Partial<EmplacamentoModel>
): PedidoModel {
  if (!pedido) return null as unknown as PedidoModel
  return {
    ...pedido,
    emplacamento: {
      ...pedido.emplacamento,
      ...emplacamento,
    },
  }
}
