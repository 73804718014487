import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RelatorioCobrancas } from './RelatorioCobrancas'
import { RelatorioPeriodo } from './RelatorioPeriodo'

const Relatorios = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/periodo`} exact component={RelatorioPeriodo} />
      <Route path={`${match.path}/cobrancas`} component={RelatorioCobrancas} />
    </Switch>
  )
}

export default Relatorios
