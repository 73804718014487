import React from 'react'
import styled from '@emotion/styled'
import usePermissaoUsuario from './hooks/usePermissaoUsuario'
import { PERMISSOES } from './Models'

export const RestrictedResource = ({
  permissoes,
  children,
  renderWhenForbidden,
}: {
  permissoes: PERMISSOES[]
  children
  renderWhenForbidden?
}) => {
  const { ForbiddenComponent } = RestrictedResource
  const hasPermissao = usePermissaoUsuario(permissoes)

  if (hasPermissao) return children

  console.log({ renderWhenForbidden })
  if (!hasPermissao && renderWhenForbidden !== undefined) return renderWhenForbidden

  return (
    <ForbiddenComponent>Você não tem permissão para visualizar esse recurso</ForbiddenComponent>
  )
}
RestrictedResource.ForbiddenComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`
