import styled from '@emotion/styled'

interface IProps {
  even?: boolean
  spaceBetween?: number
  centered?: boolean
}

export const DisplayFlex = styled('div')<IProps>(
  (props) => `
  display: flex;
  &>* {
    ${
      props.even
        ? `
    flex: 1;
    `
        : ''
    }
  }
  ${
    props.spaceBetween
      ? `
    &>*:not(:last-child) {
      margin-right: ${props.spaceBetween}px;
    }
  `
      : ''
  }
  ${
    props.centered
      ? `
    justify-content: center;
    align-items: center;
  `
      : ''
  }
`
)
