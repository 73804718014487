export function CheckIcon() {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04639 6.09188L2.64847 4.4898L5.85264 7.69396L12.261 1.28563L13.8631 2.88771L5.85264 10.8981L1.04639 6.09188Z"
        fill="#A0C8FF"
        stroke="#0A4EFF"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
