import { CheckOutlined } from '@ant-design/icons'
import { Button, DatePicker, notification, Radio, Table } from 'antd'
import useAxios from 'axios-hooks'
import { format } from 'date-fns'
import { useState } from 'react'
import { EmplacamentoModel } from '../common/Models'
import moment from 'moment'
import Axios from 'axios'
import saveAs from 'file-saver'
import { AuthStore } from '../auth/AuthStore'

export function RelatorioCobrancas() {
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState('')
  const [statusPagamento, setStatusPagamento] = useState('')

  const { idEmpresaAtiva } = AuthStore.useState()

  const [{ data, loading }] = useAxios<EmplacamentoModel[]>(
    {
      url: '/emplacamentos/relatorio-cobrancas',
      params: { dataInicio, dataFim, statusPagamento },
    },
    {
      useCache: false,
    }
  )
  const VALOR_ABATE_PAR_PLACA = 8 + 32 + 50
  const VALOR_ABATE_PLACA_INDIVIDUAL = 8 + 32 + 25
  const emplacamentos: (EmplacamentoModel & { valorRepasse: number })[] = data?.length
    ? data.map((x) => ({
        ...x,
        valorRepasse:
          x.valorTotalEstampagem -
          (x.pedido.servicoId === 1 ? VALOR_ABATE_PAR_PLACA : VALOR_ABATE_PLACA_INDIVIDUAL),
      }))
    : []

  const valorTotal = emplacamentos?.reduce((prev, curr) => prev + curr.valorRepasse, 0) ?? 0
  const valorPago =
    emplacamentos
      ?.filter((x) => x.dataPagamento)
      .reduce((prev, curr) => prev + curr.valorRepasse, 0) ?? 0

  function setDatas(_, [dataInicio, dataFim]) {
    const parseData = (data, hora) => {
      if (!data) return data
      const strData = data.split('/').reverse().join('-')
      return new Date(`${strData} ${hora}`).toISOString()
    }
    setDataInicio(parseData(dataInicio, '00:00:00'))
    setDataFim(parseData(dataFim, '23:59:59'))
  }

  async function getExcel() {
    try {
      const { data, headers } = await Axios.get('/emplacamentos/relatorio-cobrancas', {
        responseType: 'blob',
        params: { dataInicio, dataFim, statusPagamento, exportTo: 'EXCEL' },
      })

      saveAs(data, headers.filename)
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao gerar o excel'
      notification.error({ message })
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', gap: 18, marginBottom: 24, alignItems: 'flex-end' }}>
        <div style={{ width: 400 }}>
          <label>
            Período
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              onChange={setDatas}
              value={[dataInicio ? moment(dataInicio) : null, dataFim ? moment(dataFim) : null]}
              allowEmpty={[true, true]}
              style={{ width: '100%' }}
            />
          </label>
        </div>
        <div>
          <label>
            Status de Pagamento
            <br />
            <Radio.Group
              onChange={(e) => setStatusPagamento(e.target.value)}
              value={statusPagamento}
            >
              <Radio value={''}>Todos</Radio>
              <Radio value={'PENDENTES'}>Pendentes</Radio>
              <Radio value={'PAGAS'}>Pagos</Radio>
            </Radio.Group>
          </label>
        </div>
        <div style={{ flex: 1 }} />
        <div
          style={{
            fontSize: '1.3em',
            textAlign: 'center',
            background: '#efefef',
            padding: '4px 12px',
            border: '1px solid #e9e9e9',
            borderRadius: '8px',
          }}
        >
          <div>
            <strong>
              {formatValor(valorPago)} /{' '}
              <span style={{ color: '#444' }}>{formatValor(valorTotal)}</span>
            </strong>
          </div>
          <div>Valor</div>
        </div>
      </div>
      {/*
      <Button onClick={getExcel}>Exportar para Excel</Button>
      <Button>Exportar para PDF</Button>
      */}
      <Table
        dataSource={emplacamentos}
        loading={loading}
        columns={[
          { title: 'Placa', render: (row: EmplacamentoModel) => row.placa },
          {
            title: 'Código de autorização',
            render: (row: EmplacamentoModel) => (
              <>
                {row.codigoAutorizacaoDetran}
                <br />
                <small>{row.codigoAutorizacaoDenatran}</small>
              </>
            ),
          },
          {
            title: 'Cliente',
            render: (row: EmplacamentoModel) => (
              <>
                {row.cliente.nome}
                <br />
                <small>{row.cliente.cpfCnpj}</small>
              </>
            ),
          },
          {
            align: 'right',
            title: 'Valor',
            render: (row) => (
              <>
                {formatValor(row.valorRepasse)}
                <br />
                <small>{formatValor(row.valorTotalEstampagem)}</small>
              </>
            ),
          },
          {
            align: 'center',
            title: 'Pagamento',
            render: (row: EmplacamentoModel) =>
              row.dataPagamento ? (
                <>
                  <CheckOutlined />
                  <br />
                  {format(new Date(row.dataPagamento), 'dd/MM/yyyy HH:mm')}
                </>
              ) : null,
          },
          // { render: (row: EmplacamentoModel) => row },
          // { render: (row: EmplacamentoModel) => row },
          // { render: (row: EmplacamentoModel) => row },
        ]}
      />
    </div>
  )
}

function formatValor(valor: number) {
  return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
}
