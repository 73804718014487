import React from 'react'

export const ProcessoFabricacao = () => {
  return (
    <div>
      <h2>Fabricação</h2>
      <ul>
        <li>
          <a href="lotes/cadastro">Solicitar blanks</a>
        </li>
        <li> Aguardar a criação dos QRs no SERPRO </li>
        <li> Consultar os QRs para armazenamento interno </li>
        <li> Baixar os QRs </li>
        <li> Imprimir os QRs na impressora a laser </li>
        <li> Ler cada QR indivualmente estampado em um BLANK </li>
      </ul>

      <h2>Estampagem</h2>
      <ul>
        <li> Enviar o lote ao estampador </li>
        <li> Estampador recebe o lote </li>
        <li> Estampador cria nova estampagem </li>
        <li> Estampador informa o QR que será a placa (traseira, dianteira ou terceira) </li>
        <li> Estampador instala a placa no veículo </li>
        <li> Estampador finaliza a estampagem da placa </li>
        <li> Sistema informa ao SERPRO e ao DETRAN-GO </li>
      </ul>
    </div>
  )
}
