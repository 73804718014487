import { Button, notification, Table } from 'antd'
import axios from 'axios'
import useAxios from 'axios-hooks'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { DisplayFlex } from '../common/DisplayFlex'
import { Show } from '../common/Show'
import { TextFieldInput } from '../common/TextField'

export const IniciosEstampagem = () => {
  const [placa, setPlaca] = React.useState('')
  const [{ data: iniciosEstampagem, loading: loadingIniciosEstampagem }, fetchIniciosEstampagem] =
    useAxios(`/emplacamentos/inicios-estampagem/${placa}`, { manual: true })

  function changePlaca(e) {
    setPlaca(e.target.value)
  }

  async function handleClickBuscar() {
    try {
      await fetchIniciosEstampagem()
    } catch (e: any) {
      const message =
        e.response?.data?.message ?? 'Ocorreu um erro ao buscar os inícios de estampagem'
      notification.error({ message })
    }
  }

  async function handleClickCancelar(id) {
    try {
      const empresaId = iniciosEstampagem.empresa.id
      await axios.post('/emplacamentos/cancela-inicio-estampagem', {
        idInicioEstampagem: id,
        empresaId,
      })
      notification.success({ message: 'Início de estampagem cancelado com sucesso' })
    } catch (e: any) {
      const message =
        e.response?.data?.message ?? 'Ocorreu um erro ao cancelar o início de estampagem'
      notification.error({ message })
    }
  }

  return (
    <div>
      <div>
        Placa
        <DisplayFlex spaceBetween={12}>
          <TextFieldInput mask="AAA9A99" onChange={changePlaca} value={placa} />
          <Button loading={loadingIniciosEstampagem} onClick={handleClickBuscar}>
            Buscar
          </Button>
        </DisplayFlex>
      </div>

      <Show condition={iniciosEstampagem?.empresa}>
        <div>Empresa: {iniciosEstampagem?.empresa?.descricao}</div>
      </Show>

      <div>
        <Table
          dataSource={iniciosEstampagem?.iniciosEstampagem ?? []}
          loading={loadingIniciosEstampagem}
          columns={[
            { title: 'Código de Autorização', render: (row) => row.numeroAutorizacaoEstampagem },
            { title: 'Serial', render: (row) => row.blank.serial },
            { title: 'Tipo', render: (row) => row.tipoPlaca },
            {
              title: 'Data',
              render: (row) => format(parseISO(row.dataHoraRegistro), 'dd/MM/yyyy HH:mm'),
            },
            {
              title: '',
              render: (row) => (
                <Button onClick={async () => await handleClickCancelar(row.id)}>
                  Cancelar início de estampagem
                </Button>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}
