import { Drawer, Menu } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

import { AuthStore, getEmpresaAtiva, getUsuarioLogado } from 'auth/AuthStore'
import { PERMISSOES, TIPOS_EMPRESAS } from './common/Models'
import { checkPermissao } from './common/checkPermissao'
import { getPermissoesEmpresa } from './auth/AuthStore'
import { checkTipoEmpresas } from 'common/checkTipoEmpresas'

export const permissoesEmplacamento = [
  PERMISSOES.CRIAR_PEDIDO_ESTAMPAGEM,
  PERMISSOES.ENVIAR_PEDIDO_PARA_ESTAMPAGEM,
  PERMISSOES.ESTAMPAR,
  PERMISSOES.INSTALAR,
  PERMISSOES.INFORMAR_NF,
  PERMISSOES.APAGAR_EMPLACAMENTO,
]

const MENU_EMPLACAMENTO = {
  key: 'emplacamento',
  label: 'Emplacamento',
  subItens: [
    {
      key: 'estampagem',
      link: '/emplacamentos/estampagem',
      label: 'Estampagem',
      permissao: permissoesEmplacamento,
    },
    {
      key: 'pendentes-finalizacao',
      link: '/emplacamentos/pendentes-finalizacao',
      label: 'Pendentes Finalização',
      permissao: [PERMISSOES.REENVIAR_FINALIZACAO_DETRAN],
    },
    {
      key: 'pendencias-detran',
      link: '/emplacamentos/pendencias-detran',
      label: 'Pendencias no Detran',
      permissao: [PERMISSOES.REENVIAR_PENDENCIAS_DETRAN],
    },
  ],
}

const MENU_PEDIDOS = {
  key: 'pedidos',
  label: 'Pedidos',
  subItens: [
    {
      key: 'lista-vendas',
      link: '/pedidos/lista-vendas',
      label: 'Lista de Vendas',
      permissao: [PERMISSOES.VENDER_PRODUTOS],
    },
    {
      key: 'lista-devolucoes',
      link: '/pedidos/lista-devolucoes',
      label: 'Lista de Devoluções',
      permissao: [PERMISSOES.DEVOLVER_BLANK, PERMISSOES.RECEBER_BLANKS_DEVOLVIDOS],
    },
    {
      key: 'venda',
      link: '/pedidos/venda',
      label: 'Venda',
      permissao: [PERMISSOES.VENDER_PRODUTOS],
    },
  ],
}

const MENU_PRODUTOS = {
  key: 'produtos',
  label: 'Produtos',
  subItens: [
    {
      key: 'criar-lote',
      link: '/lotes/cadastro',
      label: 'Solicitar Lote de Blanks',
      permissao: [PERMISSOES.SOLICITAR_BLANKS],
    },
    {
      key: 'validar-qr-code',
      link: '/produtos/qr-code/validar',
      label: 'Validar QrCode',
      permissao: [PERMISSOES.VALIDAR_QR_IMPRESSO],
    },
    {
      key: 'lista-lotes',
      link: '/lotes',
      label: 'Lista de Lotes',
      permissao: [PERMISSOES.BAIXAR_BLANKS],
    },
    {
      key: 'estoque',
      link: '/produtos/estoque',
      label: 'Estoque',
      permissao: [PERMISSOES.VISUALIZAR_ESTOQUE],
    },
    {
      key: 'rastrear',
      link: '/produtos/rastreio',
      label: 'Rastreio',
      permissao: [PERMISSOES.VISUALIZAR_ESTOQUE],
    },
    {
      key: 'devolucao',
      link: '/produtos/devolucao',
      label: 'Devolução de Blanks',
      permissao: [PERMISSOES.DEVOLVER_BLANK],
    },
    {
      key: 'Auditorias',
      link: '/produtos/auditorias',
      label: 'Auditoria',
      permissao: [PERMISSOES.AUDITAR],
      tipoEmpresa: [TIPOS_EMPRESAS.EMPLACADOR],
    },
    {
      key: 'inutilizacao',
      link: '/produtos/inutilizar',
      label: 'Inutilização de Blanks',
      permissao: [PERMISSOES.INUTILIZAR_BLANKS],
    },

    {
      key: 'Receber',
      link: '/lotes/recebimento',
      label: 'Receber Lote',
      permissao: [PERMISSOES.RECEBER_BLANKS],
    },

    {
      key: 'receber-devolucoes',
      link: '/produtos/recebimento/devolucao',
      label: 'Receber Blanks Devolvidos',
      permissao: [PERMISSOES.RECEBER_BLANKS_DEVOLVIDOS],
    },
  ],
}

const MENU_EMPRESAS = {
  key: 'empresas',
  label: 'Empresas',
  subItens: [
    {
      key: 'gerenciar-empresas',
      link: '/empresas',
      label: 'Gerenciar empresas',
      permissao: [PERMISSOES.GERENCIAR_EMPRESAS, PERMISSOES.GERENCIAR_TODOS_USUARIOS],
    },
  ],
}

const MENU_RELATORIOS = {
  key: 'relatorios',
  label: 'Relatórios',
  subItens: [
    {
      key: 'relatorio-emplacamentos',
      link: '/relatorios/periodo',
      label: 'Relatório de Emplacamentos',
      permissao: [PERMISSOES.RELATORIOS],
    },
    {
      key: 'relatorio-cobrancas',
      link: '/relatorios/cobrancas',
      label: 'Relatório de Cobranças',
      permissao: [PERMISSOES.RELATORIOS],
    },
  ],
}
export const MENUS = [
  MENU_PRODUTOS,
  MENU_EMPLACAMENTO,
  MENU_PEDIDOS,
  MENU_EMPRESAS,
  MENU_RELATORIOS,
]

const MenuContainer = ({ visible, setVisible }) => {
  const tipoEmpresa = AuthStore.useSelector(getEmpresaAtiva)?.tipoEmpresa

  function handleClickMenu() {
    setVisible(false)
  }

  return (
    <Drawer
      placement="left"
      visible={visible}
      getContainer={false}
      onClose={() => setVisible(false)}
    >
      <Menu mode="inline" style={{ marginTop: 24 }} onClick={handleClickMenu}>
        <Menu.Item>
          <Link to="/"> Home </Link>
        </Menu.Item>
        <MenuDinamico menu={MENU_EMPLACAMENTO} tipoEmpresaAtiva={tipoEmpresa} />
        <MenuDinamico menu={MENU_PEDIDOS} tipoEmpresaAtiva={tipoEmpresa} />
        <MenuDinamico menu={MENU_PRODUTOS} tipoEmpresaAtiva={tipoEmpresa} />
        <MenuDinamico menu={MENU_EMPRESAS} tipoEmpresaAtiva={tipoEmpresa} />
        <MenuDinamico menu={MENU_RELATORIOS} tipoEmpresaAtiva={tipoEmpresa} />
      </Menu>
    </Drawer>
  )
}

const MenuDinamico = (props) => {
  // _eslint-disable-next-line_ @typescript-eslint/no-unused-vars
  const { menu, tipoEmpresaAtiva, ...restProps } = props
  const permissoes = AuthStore.useSelector(getPermissoesEmpresa)
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  const usuario = AuthStore.useSelector(getUsuarioLogado)
  let permissoesNecessarias = menu.subItens.flatMap((s) => s.permissao)
  permissoesNecessarias = [...new Set(permissoesNecessarias)]
  if (!checkPermissao(permissoesNecessarias, permissoes, usuario?.admin)) return null
  return (
    <Menu.SubMenu
      {...restProps}
      key={menu.key}
      title={
        <span>
          <span>{menu.label}</span>
        </span>
      }
    >
      {menu.subItens.map((subItem, key) => {
        if (!checkPermissao(subItem.permissao, permissoes, usuario?.admin)) return null
        if (
          subItem?.tipoEmpresa &&
          empresaAtiva?.tipoEmpresa &&
          !checkTipoEmpresas(subItem.tipoEmpresa, [empresaAtiva.tipoEmpresa])
        )
          return null
        return (
          <Menu.Item key={subItem.key}>
            <Link to={subItem.link}> {subItem.label} </Link>
          </Menu.Item>
        )
      })}
    </Menu.SubMenu>
  )
}

export default MenuContainer
