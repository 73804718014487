interface Model {
  id: number
  createdAt: string
  updatedAt: string
}
export interface EmpresaModel extends Model {
  cnpj: string
  descricao: string
  tipoEmpresa: TIPO_EMPRESA
  codigoDetranGo: number
  municipioId: number
  municipio: MunicipioModel
  estoqueMinimoPlacaCarro: number
  estoqueMinimoPlacaMoto: number
  pedidos: PedidoModel[]
  usuarios: UsuarioModel[]
  precosProdutos: TipoProdutoModel[]
  dataValidadeCertificado: string
  infoCobranca?: IInfoCobranca
  emiteNotaFiscal: boolean
  deletedAt?: Date
  auditoriaPendente?: {}
}
export interface ProdutoModel extends Model {
  qrCodeBase64: string
  hashQrCode: string
  serial: string
  status: STATUS_PRODUTO
  loteId: number
  lote: LoteModel
  empresaConsignadoraId: number
  empresaConsignadora: EmpresaModel
  empresaDetentoraId: number
  empresaDetentora: EmpresaModel
  pedidos: PedidoModel[]
  pedidosProdutos: PedidoProdutoModel[]
  tipoProdutoId: number
  tipoProduto: TipoProdutoModel
  produtoEventos: ProdutoEventoModel[]
  destino: string
}
export interface PedidoProdutoModel extends Model {
  pedidoId: number
  pedido: PedidoModel
  produtoId: number
  produto: ProdutoModel
  valor: number
}
export interface ProdutoEventoModel extends Model {
  produtoId: number
  produto: ProdutoModel
  usuarioId: number
  usuario: UsuarioModel
  tipo: TIPO_PRODUTO_EVENTO
  descricao: string
}
export interface ServicoModel extends Model {
  descricao: string
}

export interface TImage {
  name?: string
  imageBase64: string
  localization: {
    latitude: number
    longitude: number
  }
}

export interface EmplacamentoModel extends Model {
  placa: string
  codigoAutorizacaoDenatran: string
  codigoAutorizacaoDetran: string
  codDespachante: string
  nomeDespachante: string
  moto: boolean
  status: 'AGUARDANDO_PAGAMENTO' | 'AGUARDANDO_ESTAMPAGEM' | 'ESTAMPADA' | 'INSTALADA'
  categoria: number
  categoriaVeiculo: CategoriaVeiculo
  marcaModelo: string
  chassi: string
  retornoDetran: string
  municipioId: number
  municipio: MunicipioModel
  informadoDetranGo: boolean
  clienteId: number
  cliente: ClienteModel
  pedidoId: number
  pedido: PedidoModel
  especificacaoProdutoId: number
  especificacaoProduto: EspecificacaoProdutoModel
  especieVeiculo: string
  numeroNF: string
  imagensInstalacao: TImage[]
  dataPagamento: string
  dataEnvioNfeDetranGo: string
  dataEnvioEmplacamentoDetranGo: string
  dataEnvioImagensInstalacaoDetranGo: string
  valorTotalEstampagem: number
  valorNotaFiscal: number
  cobrancaId: string
  coordenadasImagensInstalacao
  linkCobranca: string
  sistemaCobranca: string
}
export interface EspecificacaoProdutoModel extends Model {
  descricao: string
  corTexto: string
  nomeCor: string
  corProdutoId: number
  corProduto: CorProdutoModel
}
export interface CorProdutoModel extends Model {
  descricao: string
}
export interface MunicipioModel extends Model {
  nome: string
  uf: string
}
export interface ClienteModel extends Model {
  cpfCnpj: string
  nome: string
  email: string
  telefone: string
}
export interface TipoProdutoModel extends Model {
  descricao: string
  servicos: ServicoModel[]
  empresas: EmpresaModel[]
  produtos: ProdutoModel[]
}
export interface PedidoModel extends Model {
  boletoId: number
  valor: number
  empresaOrigemId: number
  empresaOrigem: EmpresaModel
  empresaDestinoId: number
  empresaDestino: EmpresaModel
  produtos: ProdutoModel[]
  servicoId: number
  servico: ServicoModel
  emplacamento: EmplacamentoModel
}
export interface UsuarioModel extends Model {
  authUsuarioId: string
  nome: string
  cpf: string
  admin: boolean
  empresas: EmpresaModel[]
  usuariosEmpresas: UsuarioEmpresaModel[]
  lotes: LoteModel
  produtosEventos: ProdutoEventoModel[]
}
export interface UsuarioEmpresaModel {
  usuarioId: number
  usuario: UsuarioModel
  permissoes: PERMISSOES[]
  empresaId: number
  empresa: EmpresaModel
  createdAt: string
  updatedAt: string
}
export interface LoteModel extends Model {
  numero: string
  qtdBlanks: number
  usuarioId: number
  usuario: UsuarioModel
  produtos: ProdutoModel[]
}

export interface VisualPlacaModel extends Model {
  cores: {
    bordaMunicipio
    fundo
    texto
  }
  nomeCor
  tipo
}
export interface PedidoWithVisualPlacaModel extends PedidoModel {
  visualPlaca: VisualPlacaModel
}

export enum SERVICOS {
  PAR_DE_PLACAS = 1,
  PLACA_DE_MOTO = 2,
  PLACA_DIANTEIRA = 3,
  PLACA_TRASEIRA = 4,
  SEGUNDA_PLACA_TRASEIRA = 5,
  VENDA = 6,
  DEVOLUCAO = 7,
}

export enum TIPOS_EMPRESAS {
  FABRICANTE = 'FABRICANTE',
  EMPLACADOR = 'EMPLACADOR',
}

export enum TIPOS_PRODUTOS {
  BLANK = 1,
  BLANK_CARRO,
  BLANK_MOTO,
  PLACA_DIANTEIRA_CARRO,
  PLACA_TRASEIRA_CARRO,
  SEGUNDA_PLACA_TRASEIRA_CARRO,
  PLACA_MOTO,
}

export enum TIPO_PRODUTO_EVENTO {
  CRIACAO,
  IMPRESSAO_QRCODE,
  ENVIO,
  RECEBIMENTO,
  ESTAMPAGEM,
}

export enum STATUS_PRODUTO {
  EM_BRANCO = 'EM_BRANCO',
  QR_IMPRESSO = 'QR_IMPRESSO',
  ESTAMPADO = 'ESTAMPADO',
  INUTILIZADO = 'INUTILIZADO',
}

export enum TIPO_EMPRESA {
  FABRICANTE = 'FABRICANTE',
  EMPLACADOR = 'EMPLACADOR',
}

export const SEPLAC_FABRICANTE_ID = 323

export enum PERMISSOES {
  CRIAR_PEDIDO_ESTAMPAGEM = 'CRIAR_PEDIDO_ESTAMPAGEM',
  ENVIAR_PEDIDO_PARA_ESTAMPAGEM = 'ENVIAR_PEDIDO_PARA_ESTAMPAGEM',
  ESTAMPAR = 'ESTAMPAR',
  INSTALAR = 'INSTALAR',
  INFORMAR_NF = 'INFORMAR_NF',
  GERENCIAR_USUARIOS = 'GERENCIAR_USUARIOS',
  RECEBER_BLANKS = 'RECEBER_BLANKS',
  CRIAR_AUDITORIA = 'CRIAR_AUDITORIA',
  AUDITAR = 'AUDITAR',
  SUBSTITUIR_BLANKS = 'SUBSTITUIR_BLANKS',
  CANCELAR_INICIO_ESTAMPAGEM = 'CANCELAR_INICIO_ESTAMPAGEM',
  SOLICITAR_BLANKS = 'SOLICITAR_BLANKS',
  VALIDAR_QR_IMPRESSO = 'VALIDAR_QR_IMPRESSO',
  VENDER_PRODUTOS = 'VENDER_PRODUTOS',
  GERENCIAR_EMPRESAS = 'GERENCIAR_EMPRESAS',
  GERENCIAR_TODOS_USUARIOS = 'GERENCIAR_TODOS_USUARIOS',
  INUTILIZAR_BLANKS = 'INUTILIZAR_BLANKS',
  BAIXAR_BLANKS = 'BAIXAR_BLANKS',
  VISUALIZAR_ESTOQUE = 'VISUALIZAR_ESTOQUE',
  ATUALIZAR_CERTIFICADO = 'ATUALIZAR_CERTIFICADO',
  REENVIAR_FINALIZACAO_DETRAN = 'REENVIAR_FINALIZACAO_DETRAN',
  REENVIAR_PENDENCIAS_DETRAN = 'REENVIAR_PENDENCIAS_DETRAN',
  APAGAR_EMPLACAMENTO = 'APAGAR_EMPLACAMENTO',
  DEVOLVER_BLANK = 'DEVOLVER_BLANK',
  APAGAR_PEDIDO_VENDA = 'APAGAR_PEDIDO_VENDA',
  RECEBER_BLANKS_DEVOLVIDOS = 'RECEBER_BLANKS_DEVOLVIDOS',
  RELATORIOS = 'RELATORIOS',
  SUBSTITUIR_IMAGENS = 'SUBSTITUIR_IMAGENS',
}

export type CategoriaVeiculo =
  | 'PARTICULAR'
  | 'ALUGUEL'
  | 'OFICIAL'
  | 'EXPERIENCIA'
  | 'APRENDIZAGEM'
  | 'FABRICANTE'
  | 'CORPO_CONSULAR'
  | 'ORGANISMOS_INTERNACIONAIS'
  | 'CHEFE_DE_MISSAO_DIPLOMATICA'
  | 'CORPO_DIPLOMATICO'
  | 'MISSAO_REPARTICAO_REPRESENTACAO'
  | 'AC_DE_COOPERACAO_INTERNACIONAL'

export interface IAuditoria extends Model {
  dataInicio: Date
  dataFim: Date
  usuarioId: number
  empresaId: number
  auditoriasProdutos: IProdutosAuditoria[]
  fileNames?: []
}

export interface IProdutosAuditoria extends Model {
  auditoriaId: number
  produtoId: number
  status: 'AUDITADO' | 'NAO_AUDITADO'
  produto: ProdutoModel
}
export interface IInfoCobranca {
  sistema: 'EMPLACAFACIL' | 'GOPLACAS' | null
  permiteVoucher?: boolean
  permiteAlterarValor?: boolean
}
