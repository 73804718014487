import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { Estoque } from './Estoque'
import { Rastreio } from './Rastreio'
import { Inutilizacao } from './Inutilizacao'
import { ProcessoFabricacao } from './ProcessoFabricacao'
import { ValidarQrCode } from './ValidarQrCode'
import { DetalheProduto } from './DetalheProduto'
import { Devolucao } from './Devolucao'
import { RecebimentoDevolucao } from '../lotes/RecebimentoDevolucao'
import { ListaAuditorias } from './ListaAuditorias'
import { Auditoria } from './Auditoria'

const Produtos = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/estoque`} exact component={Estoque} />
      <Route path={`${match.path}/rastreio`} exact component={Rastreio} />
      <Route path={`${match.path}/qr-code/validar`} exact component={ValidarQrCode} />
      <Route path={`${match.path}/inutilizar`} exact component={Inutilizacao} />
      <Route path={`${match.path}/processo`} exact component={ProcessoFabricacao} />
      <Route path={`${match.path}/devolucao`} exact component={Devolucao} />
      <Route path={`${match.path}/recebimento/devolucao`} exact component={RecebimentoDevolucao} />
      <Route path={`${match.path}/auditorias/:id`} exact component={Auditoria} />
      <Route path={`${match.path}/auditorias`} exact component={ListaAuditorias} />
      <Route path={`${match.path}/:produtoId`} exact component={DetalheProduto} />
    </Switch>
  )
}

export default Produtos
