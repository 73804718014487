import React from 'react'
import { Table, Tabs } from 'antd'
import useAxios from 'axios-hooks'
import { useParams, Link } from 'react-router-dom'
import { PERMISSOES, ProdutoModel, TIPO_PRODUTO_EVENTO } from '../common/Models'
import { format, parseISO } from 'date-fns/esm'
import { Qr } from '../common/Qr'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'

const DetalheProduto = () => {
  usePermissaoUsuario([PERMISSOES.VISUALIZAR_ESTOQUE, PERMISSOES.BAIXAR_BLANKS], {
    changeRoute: true,
  })
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane key="1" tab="Detalhes">
        <Detalhe />
      </Tabs.TabPane>
      <Tabs.TabPane key="2" tab="Rastreio">
        <Rastreio />
      </Tabs.TabPane>
      <Tabs.TabPane key="3" tab="Consulta Situação Denatran">
        <iframe
          title="Portal de serviços do Denatran"
          src="https://portalservicos.denatran.serpro.gov.br/?_ga=2.3902196.536723499.1607435255-1624365310.1607010703#/placaVeicular"
          style={{ width: '100%', minHeight: 800 }}
        />
      </Tabs.TabPane>
    </Tabs>
  )
}

const Detalhe = () => {
  const { produtoId } = useParams<{ produtoId }>()
  const [{ data, loading }] = useAxios<ProdutoModel>(`/produtos/${produtoId}`)

  if (loading) {
    return <div>carregando ...</div>
  }

  if (!data) return null

  return (
    <div>
      <Qr qrCodeBase64={data.qrCodeBase64} />
      <br />
      Tipo de Produto: <strong>{data.tipoProduto.descricao}</strong>
      <br />
      Serial: <strong>{data.serial}</strong>
      <br />
      Lote:{' '}
      <Link to={`/lotes/detalhe/${data.loteId}`}>
        <strong>{data.loteId}</strong>
      </Link>
    </div>
  )
}

const Rastreio = () => {
  const { produtoId } = useParams<{ produtoId }>()
  const [{ data, loading }] = useAxios(`/produtos-eventos/${produtoId}`)
  return (
    <div>
      <Table
        dataSource={data}
        loading={loading}
        columns={[
          { title: 'Tipo de evento', key: 'tipo', render: _renderTipoEvento },
          { title: 'Descrição', key: 'descricao', dataIndex: 'descricao' },
          { title: 'Data', key: 'createdAt', render: _renderData },
          { title: 'Usuário', key: 'usuario', render: _renderUsuario },
        ]}
      />
    </div>
  )
}

function _renderTipoEvento(row) {
  return <>{TIPO_PRODUTO_EVENTO[row.tipo]}</>
}

function _renderData(row) {
  return format(parseISO(row.createdAt), 'dd/MM/yyyy HH:mm')
}

function _renderUsuario(row) {
  return <>{row.usuario.nome}</>
}

export { DetalheProduto }
