import { Modal } from 'antd'
import { ModalFuncProps } from 'antd/lib/modal'

export const confirmPromise = async (props: ModalFuncProps) => {
  return await new Promise((resolve) => {
    Modal.confirm({
      ...props,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    })
  })
}
