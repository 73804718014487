import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import { notification, Input, Card } from 'antd'
import { BlanksScanner } from '../common/seplac'
import styled from '@emotion/styled'
import { PERMISSOES, ProdutoModel, TIPOS_PRODUTOS } from '../common/Models'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'

const Rastreio = () => {
  usePermissaoUsuario([PERMISSOES.VISUALIZAR_ESTOQUE], { changeRoute: true })

  const history = useHistory()
  async function handleScan(produto: ProdutoModel) {
    const produtos = await Axios.get(`/produtos?serial=${produto.serial}`).then((x) => x?.data)
    if (!produtos?.length) return notification.error({ message: 'Placa não encontrada' })
    history.push(`/produtos/${produtos[0].id}`)
  }

  return (
    <Styles.Card title="Pesquisa de produto para rastreio">
      Pesquisar pelo serial
      <BlanksScanner onScan={handleScan} />
      <Styles.Separador>-- OU --</Styles.Separador>
      <PesquisarPlaca />
    </Styles.Card>
  )
}

const PesquisarPlaca = () => {
  const history = useHistory()
  const [placa, setPlaca] = useState('')
  const [{ data }, reqBuscaPlaca] = useAxios(
    {
      url: '/emplacamentos/produtos-utilizados',
      params: { placa },
    },
    { manual: true }
  )

  function handleSearch() {
    void reqBuscaPlaca()
  }

  useEffect(() => {
    if (data?.length === 1) history.push(`/produtos/rastreio/${data[0].id}`)
  }, [data])

  return (
    <div>
      <label>
        Pesquisar pela placa
        <Input.Search
          placeholder="Informe a placa"
          size="large"
          value={placa}
          onChange={(e) => setPlaca(e.target.value)}
          enterButton="Pesquisar"
          onSearch={handleSearch}
        />
      </label>
      {data?.length > 1 ? <Placas placas={data} /> : null}
    </div>
  )
}

const Placas = ({ placas }) => {
  return (
    <Card title="Placas" style={{ marginTop: 12 }}>
      {placas.map((placa) => (
        <Placa key={placa.id} placa={placa} />
      ))}
    </Card>
  )
}

const Placa = ({ placa }) => {
  const history = useHistory()

  function handleClick() {
    history.push(`/produtos/${placa.id}`)
  }

  return (
    <Styles.ContainerPlaca onClick={handleClick}>
      Placa: <strong>{placa.serial}</strong>
      <br />
      Tipo: <strong>{_getTipoPlaca(placa.tipoProdutoId)}</strong>
    </Styles.ContainerPlaca>
  )
}

const _getTipoPlaca = (tipoPlacaId) => {
  if (tipoPlacaId === TIPOS_PRODUTOS.PLACA_DIANTEIRA_CARRO) return 'Dianteira'
  if (tipoPlacaId === TIPOS_PRODUTOS.PLACA_TRASEIRA_CARRO) return 'Traseira'
  if (tipoPlacaId === TIPOS_PRODUTOS.SEGUNDA_PLACA_TRASEIRA_CARRO) return 'Segunda Traseira'
}

const Styles = {
  Separador: styled('div')`
    font-style: italic;
    margin-bottom: 12px;
    text-align: center;
  `,
  Card: styled(Card)`
    max-width: 800px;
    margin: 0 auto;
  `,
  ContainerPlaca: styled('div')`
    padding: 12px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 3px;
    &:hover {
      background: #f2f2f2;
    }
  `,
}

export { Rastreio }
