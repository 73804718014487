import { PedidoModel, PERMISSOES, ProdutoModel, TImage, TIPOS_PRODUTOS } from '../../common/Models'
import { createSimpleStore } from 'react-simple-reducer'
import Axios from 'axios'
import { notification } from 'antd'
import { checkPermissao } from '../../common/checkPermissao'

export const PAGE_SIZE = 8
type TModalVisible =
  | 'ENVIAR_ESTAMPAGEM'
  | 'INICIAR_ESTAMPAGEM'
  | 'ENCERRAR_ESTAMPAGEM'
  | 'ENCERRAR_ESTAMPAGEM_IMAGENS_INSTALACAO'
  | 'SUBSTITUIR_ESTAMPAGEM'
  | 'NOVO_PEDIDO'
  | 'SUBSTITUICAO'
  | 'ORDEM_SERVICO'
  | 'VISUALIZAR_HISTORICO'
  | 'NOTA_FISCAL'
  | 'SELECIONAR_ESTAMPAGEM'
  | 'BOLETO'
  | 'MULTIPLOS_RESULTADOS_BUSCA'
  | 'IMAGENS_INSTALACAO'
  | ''

type TAbas = 'AGUARDANDO_PAGAMENTO' | 'AGUARDANDO_ESTAMPAGEM' | 'ESTAMPADA' | 'INSTALADA'

const filtrosLista = {
  dataInicio: '',
  dataFim: '',
}
export const ListaPedidosStore = createSimpleStore(
  {
    carregandoLista: false,
    modalVisible: '' as TModalVisible,
    pedidoAtivo: null as PedidoModel | null,
    produtoAntigo: {} as ProdutoModel,
    produtoNovo: {} as ProdutoModel,
    pedidos: [] as PedidoModel[],
    totalPedidos: 0,
    query: { limit: PAGE_SIZE, offset: 0 },
    abaAtual: 'AGUARDANDO_ESTAMPAGEM' as TAbas,
    pedidosFiltro: [] as PedidoModel[],
    countByStatus: [] as Array<{ status: string; count: number }>,
    permissoesUsuarioEmpresa: [] as PERMISSOES[],
    filtrosLista,
    loadingImagensInstalacao: false as boolean,
    auditoriaVencida: false as boolean,
  },
  {
    setDestinoProdutoAntigo(state, destino) {
      state.produtoAntigo.destino = destino
    },
    iniciarEstampagem(state, payload: PedidoModel) {
      state.modalVisible = 'INICIAR_ESTAMPAGEM'
      state.pedidoAtivo = payload
    },
    encerrarEstampagem(state, payload: PedidoModel) {
      state.modalVisible = 'ENCERRAR_ESTAMPAGEM'
      state.pedidoAtivo = payload
    },
    visualizarHistorico(state, payload: PedidoModel) {
      state.modalVisible = 'VISUALIZAR_HISTORICO'
      state.pedidoAtivo = payload
    },
    openModalNovoPedido(state) {
      state.modalVisible = 'NOVO_PEDIDO'
    },
    openModalSubstituicao(state, produto) {
      state.modalVisible = 'SUBSTITUICAO'
      state.produtoAntigo = produto
    },
    enviarEstampagem(state, payload: PedidoModel) {
      state.modalVisible = 'ENVIAR_ESTAMPAGEM'
      state.pedidoAtivo = payload
    },
    consultaPedidosSuccess(state, payload: { rows: PedidoModel[]; count: number; countByStatus }) {
      if (!payload?.rows.length) return state
      if (payload?.rows.length === 1) {
        state.modalVisible = 'ORDEM_SERVICO'
        state.pedidoAtivo = payload.rows[0]
      } else if (payload?.rows.length > 1) {
        state.modalVisible = 'SELECIONAR_ESTAMPAGEM'
        state.pedidosFiltro = payload.rows
      }
    },
    closeModal(state) {
      state.pedidoAtivo = null
      state.modalVisible = ''
    },
    setAbaAtual(state, payload) {
      state.abaAtual = payload
    },
    setQuery(state, payload) {
      state.query = payload
    },
    setPedidoOs(state, payload: PedidoModel) {
      state.modalVisible = 'ORDEM_SERVICO'
      state.pedidoAtivo = payload
    },

    atualizaDataPagamentoEmplacamento(state, dataPagamento) {
      if (!state.pedidoAtivo) return
      state.pedidoAtivo.emplacamento.dataPagamento = dataPagamento
    },

    getPedidosStarted(state) {
      state.carregandoLista = true
    },
    getPedidosError(state) {
      state.carregandoLista = false
    },
    getPedidosSuccess(
      state,
      payload: { rows: PedidoModel[]; count: number; countByStatus; auditoriaVencida }
    ) {
      state.pedidos = payload.rows
      state.totalPedidos = payload.count
      state.countByStatus = payload.countByStatus
      state.carregandoLista = false
      state.auditoriaVencida = payload.auditoriaVencida
    },
    setProduto(state, payload: { tipoProduto: TIPOS_PRODUTOS; produto: ProdutoModel }) {
      if (!state.pedidoAtivo) return
      state.pedidoAtivo.produtos.push({ ...payload.produto, tipoProdutoId: payload.tipoProduto })
    },

    limpaCampoNovoProduto(state) {
      state.produtoNovo = {} as any
    },

    fechaModalSubstituicao(state) {
      state.produtoNovo = {} as any
      state.produtoAntigo = {} as any
      state.modalVisible = ''
    },

    operacaoEstampagemSuccess(state) {
      state.produtoNovo = {} as any
      state.produtoAntigo = {} as any
      state.modalVisible = ''
    },

    setNovoProduto(state, payload: { tipoProduto: TIPOS_PRODUTOS; produto: ProdutoModel }) {
      if (!state.pedidoAtivo) return
      if (state.pedidoAtivo.produtos.length === 1) {
        state.pedidoAtivo.produtos = []
        state.pedidoAtivo.produtos.push({ ...payload.produto, tipoProdutoId: payload.tipoProduto })
        state.produtoNovo = { ...payload.produto }
      }
      if (state.pedidoAtivo.produtos.length > 1) {
        const newProdutosArr = state.pedidoAtivo?.produtos.filter(
          (p) => p.tipoProdutoId !== payload.tipoProduto
        )
        newProdutosArr.push({ ...payload.produto, tipoProdutoId: payload.tipoProduto })
        state.pedidoAtivo.produtos = newProdutosArr
        state.produtoNovo = { ...payload.produto }
      }
    },

    removeProduto(state, tipoProduto: TIPOS_PRODUTOS) {
      if (!state.pedidoAtivo) return
      state.pedidoAtivo.produtos = state.pedidoAtivo.produtos.filter(
        (p) => p.tipoProdutoId !== tipoProduto
      )
    },
    setNotaFiscal(state, payload: { notaFiscal; persist?: boolean }) {
      if (!state.pedidoAtivo) return
      state.pedidoAtivo.emplacamento.numeroNF = payload.notaFiscal
      if (payload.persist) {
        const i = state.pedidos.findIndex((p) => p.id === state.pedidoAtivo?.id)
        state.pedidos[i].emplacamento.numeroNF = payload.notaFiscal
        state.modalVisible = ''
      }
    },
    openModalNotaFiscal(state, payload: PedidoModel) {
      state.modalVisible = 'NOTA_FISCAL'
      state.pedidoAtivo = payload
    },
    setPermissoesUsuarioEmpresa(state, permissoes: PERMISSOES[]) {
      state.permissoesUsuarioEmpresa = permissoes
    },
    setFiltros(state, filtros: Partial<typeof filtrosLista>) {
      state.filtrosLista = filtros as any
    },
    encerraEstampagem: closeModal,
    encerraCorrecao: closeModal,
    displayBoleto(state, pedido) {
      state.modalVisible = 'BOLETO'
      state.pedidoAtivo = pedido
    },
    openModalEncerrarEstampagemImagensInstalacao(state) {
      state.modalVisible = 'ENCERRAR_ESTAMPAGEM_IMAGENS_INSTALACAO'
    },
    openModalImagensInstalacao(state, pedido: PedidoModel) {
      state.modalVisible = 'IMAGENS_INSTALACAO'
      state.pedidoAtivo = pedido
    },
    getImagensIntalacaoStarted(state) {
      state.loadingImagensInstalacao = true
    },
    getImagensIntalacaoDone(state, images: TImage[]) {
      if (!state.pedidoAtivo) return
      state.pedidoAtivo.emplacamento.imagensInstalacao = images
      state.loadingImagensInstalacao = false
    },
    addImagemInstalacao(state, image: TImage) {
      if (!state.pedidoAtivo) return
      state.pedidoAtivo.emplacamento.imagensInstalacao.push(image)
    },
    deleteImagemInstalacao(state, image: TImage) {
      if (!state.pedidoAtivo) return
      const imagensInstalacao = state.pedidoAtivo.emplacamento.imagensInstalacao
      state.pedidoAtivo.emplacamento.imagensInstalacao = imagensInstalacao.filter(
        (i) => i.name !== image.name
      )
    },
    clearImagensInstalacao(state) {
      if (!state.pedidoAtivo) return
      state.pedidoAtivo.emplacamento.imagensInstalacao = []
    },
    updateDadosCobrancaEmplacamento(
      state,
      payload: { pedidoId: number; dataPagamento?; cobrancaId?; linkCobranca? }
    ) {
      const { dataPagamento, cobrancaId, linkCobranca } = payload
      state.pedidos = state.pedidos.map((pedido) => {
        if (pedido.id !== payload.pedidoId) return pedido
        return {
          ...pedido,
          emplacamento: {
            ...pedido.emplacamento,
            ...(dataPagamento ? { dataPagamento } : {}),
            ...(cobrancaId ? { cobrancaId } : {}),
            ...(linkCobranca ? { linkCobranca } : {}),
          },
        }
      })
    },
  },
  {
    thunks: {
      getPedidos(idEmpresaAtiva) {
        return async (dispatch, getState) => {
          const {
            abaAtual,
            query,
            filtrosLista: { dataFim, dataInicio },
          } = getState()
          try {
            dispatch(ListaPedidosStore.actions.getPedidosStarted())
            const pedidos = await Axios.get('/emplacamentos', {
              params: {
                empresasIds: [idEmpresaAtiva],
                status: abaAtual,
                ...(abaAtual === 'INSTALADA' && query),
                dataInicio,
                dataFim,
              },
            }).then((x) => x.data)
            dispatch(ListaPedidosStore.actions.getPedidosSuccess(pedidos))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro inesperado'
            dispatch(ListaPedidosStore.actions.getPedidosError())
            notification.error({ message })
          }
        }
      },

      preencheOrdemPedido(pedido: PedidoModel) {
        return async (dispatch) => {
          try {
            dispatch(ListaPedidosStore.actions.setPedidoOs(pedido))
            const boleto = await Axios.get(
              `/emplacamentos/boleto-emplacafacil/${pedido.emplacamento.codigoAutorizacaoDetran}`,
              {}
            ).then((x) => x.data)

            dispatch(ListaPedidosStore.actions.atualizaDataPagamentoEmplacamento(boleto.dataPgto))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Falha ao buscar Informacao do Boleto'
            dispatch(ListaPedidosStore.actions.getPedidosError())
            notification.error({ message })
          }
        }
      },
      enviarEstampagem(pedido: PedidoModel) {
        return async (dispatch, getState) => {
          const { permissoesUsuarioEmpresa } = getState()
          if (
            !checkPermissao([PERMISSOES.ENVIAR_PEDIDO_PARA_ESTAMPAGEM], permissoesUsuarioEmpresa)
          ) {
            return notification.warn({
              message: 'Você não tem permissão para enviar pedidos para estampagem',
            })
          }
          dispatch(ListaPedidosStore.actions.enviarEstampagem(pedido))
          // try {
          //   await Axios.post('/emplacamentos/enviar-estampagem', { emplacamentoId }).then(x => x.data)
          //   this.getPedidos(idEmpresaAtiva)(dispatch, getState)
          //   dispatch(ListaPedidosStore.actions.operacaoEstampagemSuccess())
          // } catch (error: any) {
          //   const message = error.response?.data?.message ?? 'Ocorreu um erro inesperado'
          //   notification.error({ message })
          // }
        }
      },
      substituirEstampagem(objSubstituicao, idEmpresaAtiva) {
        const { pedidoId, produtoNovoId, produtoAntigoId, produtoAntigoDestino } = objSubstituicao

        return async (dispatch, getState) => {
          const { permissoesUsuarioEmpresa } = getState()
          if (!checkPermissao([PERMISSOES.SUBSTITUIR_BLANKS], permissoesUsuarioEmpresa)) {
            return notification.warn({
              message: 'Você não tem permissão para substituir estampagem',
            })
          }
          try {
            await Axios.put('/emplacamentos/substituir-estampagem', {
              pedidoId,
              produtoNovoId,
              produtoAntigoId,
              produtoAntigoDestino,
            }).then((x) => x.data)
            dispatch(ListaPedidosStore.actions.operacaoEstampagemSuccess())
            void this.getPedidos(idEmpresaAtiva)(dispatch, getState)
            notification.success({ message: 'Blank Substituído com sucesso!' })
            notification.success({ message: 'Placa estampada e informado ao DENATRAN com sucesso' })
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro inesperado'
            notification.error({ message })
          }
        }
      },
      cancelarEstampagem(idEmpresaAtiva, pedidoId) {
        return async (dispatch, getState) => {
          const { permissoesUsuarioEmpresa } = getState()
          if (!checkPermissao([PERMISSOES.CANCELAR_INICIO_ESTAMPAGEM], permissoesUsuarioEmpresa)) {
            return notification.warn({ message: 'Você não tem permissão para cancelar estampagem' })
          }
          try {
            await Axios.put('/emplacamentos/cancela-estampagem', { pedidoId }).then((x) => x.data)
            dispatch(ListaPedidosStore.actions.operacaoEstampagemSuccess())
            void this.getPedidos(idEmpresaAtiva)(dispatch, getState)
            notification.success({ message: 'Inicio de estampagem cancelado' })
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro inesperado'
            notification.error({ message })
          }
        }
      },
      excluirEstampagem(idEmpresaAtiva, emplacamentoId) {
        return async (dispatch, getState) => {
          const { permissoesUsuarioEmpresa } = getState()
          if (!checkPermissao([PERMISSOES.APAGAR_EMPLACAMENTO], permissoesUsuarioEmpresa)) {
            return notification.warn({ message: 'Você não tem permissão para excluir estampagens' })
          }
          try {
            await Axios.delete(`/emplacamentos/${emplacamentoId}`)
            void this.getPedidos(idEmpresaAtiva)(dispatch, getState)
          } catch (error: any) {
            const message =
              error.response?.data?.message ?? 'Ocorreu um erro ao excluir a estampagem'
            notification.error({ message })
          }
        }
      },
      iniciarEstampagem(pedido: PedidoModel) {
        return async (dispatch, getState) => {
          const { permissoesUsuarioEmpresa } = getState()
          if (!checkPermissao([PERMISSOES.ESTAMPAR], permissoesUsuarioEmpresa)) {
            return notification.warn({ message: 'Você não tem permissão para estampar placas' })
          }
          dispatch(ListaPedidosStore.actions.iniciarEstampagem(pedido))
        }
      },

      encerrarEstampagem(pedido: PedidoModel) {
        return async (dispatch, getState) => {
          const { permissoesUsuarioEmpresa } = getState()
          if (!checkPermissao([PERMISSOES.INSTALAR], permissoesUsuarioEmpresa)) {
            return notification.warn({ message: 'Você não tem permissão para instalar placas' })
          }
          dispatch(ListaPedidosStore.actions.encerrarEstampagem(pedido))
        }
      },
      getImagensInstalacao(codigoAutorizacao: string) {
        return async (dispatch) => {
          try {
            // dispatch(ListaPedidosStore.actions.getImagensIntalacaoStarted())

            const imagens = await Axios.get(
              `/emplacamentos/imagens-instalacao/${codigoAutorizacao}`
            ).then((rs) => rs.data)

            dispatch(ListaPedidosStore.actions.getImagensIntalacaoDone(imagens))
          } catch (e: any) {
            dispatch(ListaPedidosStore.actions.clearImagensInstalacao())
            notification.error({ message: 'Erro ao baixar imagens!' })
          }
        }
      },
      uploadImagemInstalacao(image: TImage) {
        return async (dispatch, getState) => {
          const pedidoAtivo = getState().pedidoAtivo
          if (!pedidoAtivo) return
          const { codigoAutorizacaoDenatran: codigoAutorizacao } = pedidoAtivo.emplacamento

          try {
            const newImage = await Axios.post(
              `/emplacamentos/imagens-instalacao/${codigoAutorizacao}`,
              image
            ).then((rs) => rs.data)

            dispatch(ListaPedidosStore.actions.addImagemInstalacao(newImage))
          } catch (e: any) {
            notification.error({ message: 'Erro ao fazer upload da imagem!' })
          }
        }
      },
      deleteImagemInstalacao(image: TImage) {
        return async (dispatch) => {
          if (!image.name) return

          try {
            await Axios.delete('/emplacamentos/imagens-instalacao', {
              data: { imageName: image.name },
            })

            dispatch(ListaPedidosStore.actions.deleteImagemInstalacao(image))
          } catch (e: any) {
            notification.error({ message: 'Erro ao deletar a imagem!' })
          }
        }
      },

      cobrancaPdv({
        pdvId,
        meioPagamento,
        cobrancaId,
      }: {
        pdvId?: number
        meioPagamento: 'CARTAO_CREDITO' | 'CARTAO_DEBITO'
        cobrancaId: string
      }) {
        return async (dispatch, getState) => {
          try {
            const data = {
              pdvId,
              meioPagamento,
              cobrancaId,
            }

            const cobranca = await Axios.post('/emplacamentos/pdv-cobranca', data).then(
              (res) => res.data
            )
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao gerar cobrança'
            notification.error({ message })
          }
        }
      },

      cancelarPdv({ pdvId, cobrancaId }: { pdvId?: number; cobrancaId: string }) {
        return async (dispatch, getState) => {
          try {
            const data = {
              pdvId,
              cobrancaId,
            }

            const cobranca = await Axios.post('/emplacamentos/pdv-cancelar', data).then(
              (res) => res.data
            )
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao cancelamento'
            notification.error({ message })
          }
        }
      },
    },
  }
)

function closeModal(state) {
  state.modalVisible = ''
}
