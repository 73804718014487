import { Col, Row, Table } from 'antd'
import React from 'react'

import styled from '@emotion/styled'

const TabelaProdutos = ({ produtos }) => {
  return (
    <Styles.Row>
      <Col>
        <Table columns={TABELA_PRODUTOS_COLUMNS} dataSource={produtos} rowKey="codigoBarras" />
      </Col>
    </Styles.Row>
  )
}

const TABELA_PRODUTOS_COLUMNS = [
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial',
  },
  {
    title: 'Tipo produto',
    dataIndex: 'tipoProduto.descricao',
    key: 'tipoProduto.id',
  },
  {
    title: 'Lote produto',
    dataIndex: 'loteId',
    key: 'loteId',
  },
]

const Styles = {
  Row: styled(Row)`
    margin-bottom: 10px;
  `,
}

export default TabelaProdutos
