import React, { ReactNode, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { ListaPedidosStore } from './ListaPedidosStore'
import { Button, Input, Modal, notification } from 'antd'
import { DisplayFlex } from '../../common/DisplayFlex'
import { WarningOutlined } from '@ant-design/icons'
import IntlNumberInput from 'react-intl-number-input'
import useAxios from 'axios-hooks'
import { AuthStore, getEmpresaAtiva } from '../../auth/AuthStore'
import { TextFieldInput } from '../../common/TextField'
import { Show } from '../../common/Show'
import { Form, FormikProvider, useField, useFormik, useFormikContext } from 'formik'
import confirm from 'antd/lib/modal/confirm'
import { ClienteModel, EmplacamentoModel, PedidoModel } from '../../common/Models'

export const EnvioEstampagem = () => {
  const { Layout, Flex } = EnvioEstampagem
  const dispatch = ListaPedidosStore.useDispatch()
  const { modalVisible, pedidoAtivo } = ListaPedidosStore.useState()
  const { idEmpresaAtiva } = AuthStore.useState()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      valorNotaFiscal: pedidoAtivo?.emplacamento?.valorNotaFiscal,
      cpfCnpj: pedidoAtivo?.emplacamento?.cliente.cpfCnpj,
      nome: pedidoAtivo?.emplacamento?.cliente.nome,
      email: pedidoAtivo?.emplacamento?.cliente.email,
      telefone: pedidoAtivo?.emplacamento?.cliente.telefone,
    },
    onSubmit(values) {
      console.log(values)
    },
  })

  const { valorNotaFiscal } = formik.values

  const [{ loading: loadingEnviarEstampagem }, reqEnviarEstampagem] = useAxios(
    {
      url: '/emplacamentos/enviar-estampagem',
      method: 'post',
      data: { emplacamentoId: pedidoAtivo?.emplacamento?.id },
    },
    { manual: true }
  )

  const [{ loading: loadingDadosComplementares }, reqDadosComplementares] = useAxios(
    {
      url: '/emplacamentos/dados-complementares',
      method: 'put',
      data: {
        pedidoId: pedidoAtivo?.id,
        ...formik.values,
        valorNotaFiscal: unmask(valorNotaFiscal),
      },
    },
    { manual: true }
  )

  // React.useEffect(() => {
  //   const { email, telefone } = pedidoAtivo.emplacamento?.cliente ?? {}
  //   setEmail('')
  //   setTelefone('')
  //   setValorNotaFiscal('' + (pedidoAtivo.emplacamento?.valorNotaFiscal || ''))
  // }, [pedidoAtivo.emplacamento])

  function closeModal() {
    dispatch(ListaPedidosStore.actions.closeModal())
  }

  async function enviarEstampagem() {
    const { message, valid } = validarDados()
    if (!valid) return notification.error({ message })

    try {
      await reqDadosComplementares()
      await reqEnviarEstampagem()
      notification.success({ message: 'Salvo com sucesso' })
      closeModal()
      dispatch(ListaPedidosStore.thunks.getPedidos(idEmpresaAtiva))
      if (emiteNotaFiscal) {
        confirm({
          title: 'Deseja emitir a nota fiscal agora?',
          content: 'Ela poderá ser emitida posteriormente',
          onOk: () => {
            if (!pedidoAtivo) return
            const novoPedidoAtivo = {
              ...pedidoAtivo,
              emplacamento: {
                ...pedidoAtivo.emplacamento,
                cliente: { ...pedidoAtivo.emplacamento.cliente, ...formik.values },
                valorNotaFiscal: unmask(valorNotaFiscal),
              } as Partial<EmplacamentoModel>,
            }
            dispatch(ListaPedidosStore.actions.openModalNotaFiscal(novoPedidoAtivo as PedidoModel))
          },
        })
      }
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao enviar para estampagem'
      notification.error(message)
    }
  }

  function validarDados() {
    // if (email && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)) {
    //   return { valid: false, message: 'Digite um e-mail válido' }
    // }

    // if (!telefone || telefone.replace('_', '').replace(/_/g, '').length < 14) {
    //   return { valid: false, message: 'Digite um telefone válido' }
    // }

    return { valid: true, message: undefined }
  }

  async function salvar() {
    const { message, valid } = validarDados()
    if (!valid) return notification.error({ message })
    try {
      await reqDadosComplementares()
      notification.success({ message: 'Salvo com sucesso' })
    } catch (error: any) {
      const message =
        error.response?.data?.message ?? 'Ocorreu um erro ao salvar os dados complementares'
      notification.error(message)
    }
  }

  useEffect(
    function setFocusValorCobranca() {
      if (modalVisible === 'ENVIAR_ESTAMPAGEM') {
        requestAnimationFrame(() => {
          document?.getElementById('intlNumberInput')?.focus()
        })
      }
    },
    [modalVisible]
  )

  const emiteNotaFiscal = AuthStore.useSelector(getEmpresaAtiva)?.emiteNotaFiscal

  return (
    <Modal
      visible={modalVisible === 'ENVIAR_ESTAMPAGEM'}
      title="Enviar emplacamento para estampagem"
      onCancel={closeModal}
      width={600}
      footer={[
        <Button size="large" onClick={closeModal} key="close">
          Cancelar
        </Button>,
        <Button onClick={salvar} size="large" key="ok" loading={loadingDadosComplementares}>
          Salvar
        </Button>,
        <Button
          onClick={enviarEstampagem}
          size="large"
          type="primary"
          key="ok"
          loading={loadingEnviarEstampagem || loadingDadosComplementares}
        >
          Salvar e enviar para Estampagem
        </Button>,
      ]}
    >
      <Layout>
        <FormikProvider value={formik}>
          <Form>
            <Show condition={empresaAtiva?.municipio?.uf != 'SP'}>
              <Show condition={empresaAtiva?.infoCobranca?.sistema}>
                <ValorCobranca />
              </Show>
              <ValorNotaFiscal />
              <InfoAcoesNotaFiscal />
            </Show>
            <Telefone />
          </Form>
        </FormikProvider>
      </Layout>
    </Modal>
  )
}
EnvioEstampagem.Layout = styled.div`
  & > div:not(:first-child) {
    margin-top: 18px;
  }
`
EnvioEstampagem.Flex = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 8px;
  & > div {
    flex: 1;
  }
`

function ValorNotaFiscal() {
  const [props] = useField('valorNotaFiscal')
  return (
    <label>
      Valor da Nota Fiscal
      <IntlNumberInput
        id="intlNumberInput"
        {...props}
        locale="pt-BR"
        precision={2}
        className="ant-input ant-input-lg"
      />
    </label>
  )
}

function InfoAcoesNotaFiscal() {
  return (
    <DisplayFlex centered={true}>
      <div style={{ padding: 24 }}>
        <WarningOutlined style={{ color: '#ff4d4f', fontSize: 40 }} />
      </div>
      <div>
        <small>
          O valor da nota fiscal pode ser inferior ao da cobrança, uma vez que podem ser cobrados
          serviços adicionais ao do emplacamento do cliente no mesmo pagamento!
        </small>
        <div>
          O valor da nota fiscal será informado ao DETRAN-GO no momento da{' '}
          <strong style={{ color: '#ff4d4f' }}>Reserva da Placa</strong>, que acontece durante a
          informação da estampagem
        </div>
      </div>
    </DisplayFlex>
  )
}

function Telefone() {
  const [props] = useField('telefone')
  return (
    <div>
      <label>
        Telefone
        <TextFieldInput mask="telefone" {...props} />
      </label>
    </div>
  )
}

function unmask(valor) {
  if (!valor) return valor
  return +valor.replace?.('R$ ', '').replace('.', '').replace(',', '.')
}

function ValorCobranca() {
  const { modalVisible, pedidoAtivo } = ListaPedidosStore.useState()
  return (
    <div>
      Valor da Cobrança
      <br />
      <strong style={{ fontSize: '1.3em' }}>
        R$ {pedidoAtivo?.emplacamento?.valorTotalEstampagem?.toFixed(2).replace('.', ',')}
      </strong>
    </div>
  )
}
