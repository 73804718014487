import { DeleteOutlined, PrinterOutlined } from '@ant-design/icons'
import { Table, Button, Tooltip, notification } from 'antd'
import useAxios from 'axios-hooks'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { confirmPromise } from '../../common/confirmPromise'
import usePermissaoUsuario from '../../common/hooks/usePermissaoUsuario'
import { PERMISSOES } from '../../common/Models'
import { ComprovanteVenda } from './ComprovanteVenda'

export const ListaVenda = () => {
  const [{ data }, refetch] = useAxios('/pedidos')
  const [pedidoId, setPedidoId] = React.useState<number | null>(null)

  usePermissaoUsuario([PERMISSOES.VENDER_PRODUTOS], { changeRoute: true })

  return (
    <div>
      <Table
        columns={[
          { title: 'Id', dataIndex: 'id' },
          { title: 'Empresa de Destino', render: (row) => <>{row.empresaDestino.descricao}</> },
          {
            title: 'Data',
            render: (row) => <>{format(parseISO(row.createdAt), 'dd/MM/yyyy HH:mm')}</>,
          },
          { title: 'Usuário', render: (row) => <>{row.usuario.nome}</> },
          {
            title: '',
            render: (row) => (
              <>
                <Tooltip title="Imprimir pedido">
                  <Button icon={<PrinterOutlined />} onClick={() => setPedidoId(row.id)} />
                </Tooltip>
                &nbsp;
                <ExcluirPedido pedidoId={row.id} refetch={refetch} />
              </>
            ),
          },
        ]}
        dataSource={data?.rows}
      />
      <ComprovanteVenda pedidoId={pedidoId} onClose={() => setPedidoId(null)} />
    </div>
  )
}

const ExcluirPedido = ({ pedidoId, refetch }) => {
  const possuiPermissao = usePermissaoUsuario([PERMISSOES.APAGAR_PEDIDO_VENDA])

  const [{ loading }, req] = useAxios(
    {
      method: 'POST',
      url: '/pedidos/cancelamento-venda',
      data: { pedidoId },
    },
    { manual: true }
  )

  async function handleClick() {
    if (
      !(await confirmPromise({
        content: 'Tem certeza que deseja cancelar o pedido?',
      }))
    )
      return

    try {
      await req()
      refetch()
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao cancelar o pedido!'
      notification.error({ message })
    }
  }

  if (!possuiPermissao) return null

  return (
    <Tooltip title="Cancelar pedido">
      <Button loading={loading} icon={<DeleteOutlined />} onClick={handleClick} />
    </Tooltip>
  )
}
