import '@fortawesome/fontawesome-free/js/all'
import 'moment/locale/pt-br'

import './App.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ConfigProvider from 'antd/es/config-provider'
import React, { useEffect } from 'react'
import ptBr from 'antd/es/locale/pt_BR'

import moment from 'moment'

import { AppContainer } from './AppContainer'
import { Login } from 'auth/Login'
import { AuthStore } from './auth/AuthStore'
import useAxios from 'axios-hooks'
import ConfigureAxios from './ConfigureAxios'

moment.locale('pt-br')

const App = () => {
  const handleInit = React.useCallback((dispatch) => {
    dispatch(AuthStore.thunks.init())
  }, [])
  return (
    <>
      <CheckForUpdates />
      <ConfigProvider locale={ptBr}>
        <AuthStore.Provider init={handleInit}>
          <ConfigureAxiosComponent />
          <Router basename="/">
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route path="/" component={AppContainer} />
            </Switch>
          </Router>
        </AuthStore.Provider>
      </ConfigProvider>
    </>
  )
}

const ConfigureAxiosComponent = () => {
  const dispatch = AuthStore.useDispatch()
  const { token, idEmpresaAtiva, usuarioId } = AuthStore.useState()

  useEffect(() => {
    ConfigureAxios(
      {
        token,
        empresaId: idEmpresaAtiva,
        usuarioId,
      },
      {
        forbidden: () => {
          dispatch(AuthStore.actions.logout())
        },
      }
    )
  }, [token, idEmpresaAtiva, usuarioId, dispatch])

  return null
}

const CheckForUpdates = () => {
  const [{ data }, fetch] = useAxios(`${window.location.origin}/meta.json`)
  const [previousVersion, setPreviousVersion] = React.useState(localStorage.previousVersion || '')
  useEffect(() => {
    setInterval(() => {
      void fetch()
    }, 30000)
  }, [])
  if (data?.version && !previousVersion) {
    localStorage.previousVersion = data.version
    setPreviousVersion(data.version)
  }
  if (data?.version && previousVersion && data.version !== previousVersion) {
    localStorage.previousVersion = data.version
    window.location.reload()
  }
  return null
}

export default App
