import React from 'react'
import styled from '@emotion/styled'
import { createSimpleStore } from 'react-simple-reducer'
import { Form, FormikProvider, useFormik } from 'formik'
import { PedidoModel } from 'common/Models'
import { TextField } from 'common/TextField'
import { Button, DatePicker, notification, Select, Table as TableAntd } from 'antd'
import { DisplayFlex } from 'common/DisplayFlex'
import moment from 'moment'
import { ColumnsType } from 'antd/es/table/interface'
import Axios from 'axios'
import { AuthStore } from 'auth/AuthStore'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { CheckIcon } from '../assets/CheckIcon'
import { CloseCircle } from '../assets/CloseCircle'

export enum STATUS_EMPLACAMENTO {
  AGUARDANDO_PAGAMENTO = 'AGUARDANDO_PAGAMENTO',
  AGUARDANDO_ESTAMPAGEM = 'AGUARDANDO_ESTAMPAGEM',
  ESTAMPADA = 'ESTAMPADA',
  INSTALADA = 'INSTALADA',
}

var buttonHandle: 'ButtonExportarExcel' | 'ButtonExportarPdf' | 'ButtonFiltrar'

const filtros = {
  cpf: '' as string,
  cnpj: '' as string,
  nome: '' as string,
  placa: '' as string,
  status: '' as string,
  codigoAutorizacao: '' as string,
  dataInicio: '' as string,
  dataFim: '' as string,
  imagemPendente: false as boolean,
  nfPendente: false as boolean,
}

const StoreRelatorioPeriodo = createSimpleStore(
  {
    filtros,
    carregandoLista: false as boolean,
    query: { limit: 100, offset: 0 },
    pedidos: [] as PedidoModel[],
    totalPedidos: 0,
    countByStatus: [] as Array<{ status: string; count: number }>,
  },
  {
    getPedidosSuccess(state, payload: { rows: PedidoModel[]; count: number; countByStatus }) {
      state.pedidos = payload.rows
      state.totalPedidos = payload.count
      state.countByStatus = payload.countByStatus
      state.carregandoLista = false
    },
    setFiltros(state, payload: Partial<typeof filtros>) {
      state.filtros = { ...state.filtros, ...payload }
    },
    clearFiltros(state) {
      state.filtros = filtros
    },
    getPedidosStarted(state) {
      state.carregandoLista = true
    },
    getPedidosError(state) {
      state.carregandoLista = false
    },
  },
  {
    thunks: {
      getPedidos(idEmpresaAtiva, filtro: typeof filtros) {
        return async (dispatch, getState) => {
          try {
            dispatch(StoreRelatorioPeriodo.actions.getPedidosStarted())
            const pedidos = await Axios.get('/emplacamentos', {
              params: {
                empresasIds: [idEmpresaAtiva],
                ...filtro,
                ...getState().query,
              },
            }).then((x) => x.data)
            dispatch(StoreRelatorioPeriodo.actions.getPedidosSuccess(pedidos))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro inesperado'
            dispatch(StoreRelatorioPeriodo.actions.getPedidosError())
            notification.error({ message })
          }
        }
      },
      getExcel(idEmpresaAtiva, filtro: typeof filtros) {
        return async () => {
          try {
            const { data, headers } = await Axios.get('/emplacamentos/excel', {
              responseType: 'blob',
              params: {
                empresasIds: [idEmpresaAtiva],
                ...filtro,
              },
            })

            saveAs(data, headers.filename)
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro inesperado'
            notification.error({ message })
          }
        }
      },
      getPdf(idEmpresaAtiva, filtro: typeof filtros) {
        return async () => {
          try {
            const { data, headers } = await Axios.get('/emplacamentos/pdf', {
              responseType: 'blob',
              params: {
                empresasIds: [idEmpresaAtiva],
                ...filtro,
              },
            })

            saveAs(data, headers.filename)
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro inesperado'
            notification.error({ message })
          }
        }
      },
    },
  }
)

export const RelatorioPeriodo = () => {
  const { Layout, Hr } = RelatorioPeriodo

  return (
    <StoreRelatorioPeriodo.Provider>
      <Layout>
        <h2>RELATÓRIO</h2>
        <Filtros />
        <Hr />
        <Table />
      </Layout>
    </StoreRelatorioPeriodo.Provider>
  )
}
RelatorioPeriodo.Layout = styled.div``
RelatorioPeriodo.Hr = styled.hr`
  color: #d7d7d7;
  margin-top: 20px;
  margin-bottom: 20px;
`

const Filtros = () => {
  const { Option } = Select
  const {
    ContainerSelectStatus,
    Container,
    RangePicker,
    Margin,
    TextField,
    ButtonFiltrar,
    ButtonExportar,
    ButtonLimpar,
    ContainerButton,
  } = Filtros
  const { filtros } = StoreRelatorioPeriodo.useState()
  const { idEmpresaAtiva } = AuthStore.useState()
  const {
    filtros: { dataInicio, dataFim, status, imagemPendente, nfPendente },
  } = StoreRelatorioPeriodo.useState()
  const dispatch = StoreRelatorioPeriodo.useDispatch()

  const formik = useFormik({
    initialValues: filtros,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers) => {
      if (buttonHandle === 'ButtonFiltrar')
        dispatch(StoreRelatorioPeriodo.thunks.getPedidos(idEmpresaAtiva, values))
      if (buttonHandle === 'ButtonExportarExcel')
        dispatch(StoreRelatorioPeriodo.thunks.getExcel(idEmpresaAtiva, values))
      if (buttonHandle === 'ButtonExportarPdf')
        dispatch(StoreRelatorioPeriodo.thunks.getPdf(idEmpresaAtiva, values))
    },
  })

  function setDatas(_, [dataInicio, dataFim]) {
    const parseData = (data, hora) => {
      if (!data) return data
      const strData = data.split('/').reverse().join('-')
      return new Date(`${strData} ${hora}`).toISOString()
    }
    dispatch(
      StoreRelatorioPeriodo.actions.setFiltros({
        dataInicio: parseData(dataInicio, '00:00:00'),
        dataFim: parseData(dataFim, '23:59:59'),
      })
    )
  }

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Container spaceBetween={12}>
          <TextField label="Placa" name="placa" />
          <TextField label="Autorização Denatran" name="codigoAutorizacaoDenatran" />
          <label>
            Status
            <ContainerSelectStatus>
              <Select
                placeholder="Selecione"
                style={{ width: 250 }}
                value={status}
                onChange={(e) => {
                  dispatch(
                    StoreRelatorioPeriodo.actions.setFiltros({
                      status: e,
                    })
                  )
                }}
              >
                <Option name="status" value={''}>
                  {' '}
                </Option>
                {Object.values(STATUS_EMPLACAMENTO).map((option, key) => (
                  <Option key={key} name="status" value={option}>
                    {option.replace(/_/g, ' ')}
                  </Option>
                ))}
              </Select>
            </ContainerSelectStatus>
          </label>

          <label>
            Período
            <RangePicker
              format="DD/MM/YYYY"
              onChange={setDatas}
              value={[dataInicio ? moment(dataInicio) : null, dataFim ? moment(dataFim) : null]}
              allowEmpty={[true, true]}
              style={{ width: '100%' }}
            />
          </label>
        </Container>

        <ContainerButton>
          <div>
            <label style={{ marginRight: 5 }}>
              <input
                type={'checkbox'}
                checked={!!imagemPendente}
                onChange={() => {
                  dispatch(
                    StoreRelatorioPeriodo.actions.setFiltros({ imagemPendente: !imagemPendente })
                  )
                }}
              />
              Imagem Pendente
            </label>
            <label>
              <input
                type={'checkbox'}
                checked={!!nfPendente}
                onChange={() => {
                  dispatch(StoreRelatorioPeriodo.actions.setFiltros({ nfPendente: !nfPendente }))
                }}
              />
              NF Pendente
            </label>
          </div>
          <div style={{ display: 'flex' }}>
            <button id="ButtonSubmit" type={'submit'} hidden>
              OK
            </button>
            <ButtonExportar
              htmlType="button"
              onClick={() => {
                buttonHandle = 'ButtonExportarExcel'
                const buttonSubmit = document.getElementById('ButtonSubmit')
                buttonSubmit?.click()
              }}
            >
              Exportar Excel
            </ButtonExportar>
            <Margin />
            <ButtonExportar
              htmlType="button"
              onClick={() => {
                buttonHandle = 'ButtonExportarPdf'
                const buttonSubmit = document.getElementById('ButtonSubmit')
                buttonSubmit?.click()
              }}
            >
              Exportar PDF
            </ButtonExportar>
            <Margin />
            <ButtonFiltrar
              htmlType="button"
              type="primary"
              onClick={() => {
                buttonHandle = 'ButtonFiltrar'
                const buttonSubmit = document.getElementById('ButtonSubmit')
                buttonSubmit?.click()
              }}
            >
              Filtrar
            </ButtonFiltrar>
            <Margin />
            <ButtonLimpar
              htmlType="reset"
              type="primary"
              danger
              onClick={() => {
                dispatch(StoreRelatorioPeriodo.actions.clearFiltros())
              }}
            >
              Limpar Filtro
            </ButtonLimpar>
            <Margin />
          </div>
        </ContainerButton>
      </Form>
    </FormikProvider>
  )
}
Filtros.Container = styled(DisplayFlex)`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 5px;
  label {
    font-weight: 400;
  }
`
Filtros.TextField = styled(TextField)`
  border-radius: 5px;
`
Filtros.Margin = styled.label`
  margin-right: 5px;
`
Filtros.ContainerSelectStatus = styled('div')`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
`
Filtros.RangePicker = styled(DatePicker.RangePicker)`
  padding-left: 34px;
  border-radius: 5px;

  .ant-picker-suffix {
    position: absolute;
    left: 6px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
  }
`
Filtros.ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
`
Filtros.ButtonFiltrar = styled(Button)``
Filtros.ButtonExportar = styled(Button)``
Filtros.ButtonLimpar = styled(Button)``

const Table = () => {
  const { Layout, ContainerStatus } = Table
  const { pedidos } = StoreRelatorioPeriodo.useState()
  const { carregandoLista } = StoreRelatorioPeriodo.useState()

  const STATUS_EMPLACAMENTO = {
    AGUARDANDO_PAGAMENTO: '#FE0505B2',
    AGUARDANDO_ESTAMPAGEM: '#FE7D05B2',
    ESTAMPADA: '#FE7D05B2',
    INSTALADA: '#0A4EFFB2',
  }

  const columns: ColumnsType<PedidoModel> = [
    {
      title: 'Placa',
      dataIndex: 'placa',
      render: (value, record, index) => {
        return record.emplacamento.placa
      },
    },
    {
      title: 'Pedido',
      dataIndex: 'pedido',
      render: (value, record, index) => {
        return record?.createdAt ? format(new Date(record.createdAt), 'dd/MM/yyyy') : null
      },
    },
    {
      title: 'Origem',
      dataIndex: 'origem',
      render: (value, record, index) => {
        return (
          <div key={index}>
            <div style={{ color: '#1E1E1E', fontWeight: 400 }}>
              {record.emplacamento.cliente.nome}
            </div>
            <div style={{ color: '#656565' }}>{record.emplacamento.cliente.cpfCnpj}</div>
          </div>
        )
      },
    },
    {
      title: 'Serviço',
      dataIndex: 'servico',
      render: (value, record, index) => {
        return (
          <div key={index}>
            <div style={{ color: '#1E1E1E', fontWeight: 400 }}>{record.servico.descricao}</div>
            <div style={{ color: '#656565' }}>{record.emplacamento.categoriaVeiculo}</div>
          </div>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record, index) => {
        return (
          <div
            key={index}
            style={{
              background: STATUS_EMPLACAMENTO[record?.emplacamento?.status],
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2,
              borderRadius: 4,
              color: 'white',
              fontWeight: 400,
            }}
          >
            {record?.emplacamento?.status.replace(/_/g, ' ')}
          </div>
        )
      },
    },
    {
      title: 'imagem',
      dataIndex: 'imagem',
      render: (value, record, index) => {
        if (record?.emplacamento?.dataEnvioImagensInstalacaoDetranGo) {
          return (
            <ContainerStatus
              key={index}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <CheckIcon />
            </ContainerStatus>
          )
        } else {
          return (
            <ContainerStatus
              key={index}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <CloseCircle />
            </ContainerStatus>
          )
        }
      },
    },
    {
      title: 'NF-e',
      dataIndex: 'nfe',
      render: (value, record, index) => {
        if (record?.emplacamento?.dataEnvioNfeDetranGo) {
          return (
            <ContainerStatus key={index}>
              <CheckIcon />
            </ContainerStatus>
          )
        } else {
          return (
            <ContainerStatus key={index}>
              <CloseCircle />
            </ContainerStatus>
          )
        }
      },
    },
  ]

  return (
    <Layout>
      <TableAntd loading={carregandoLista} columns={columns} dataSource={pedidos} size="small" />
    </Layout>
  )
}
Table.Layout = styled.div`
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
  }
`
Table.ContainerStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
