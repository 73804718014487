import { Route, Switch } from 'react-router-dom'
import React from 'react'

import { Emplacamentos } from './emplacamentos'
import { Pedidos } from './pedidos'
import { Produtos } from './produtos'
import { Lotes } from './lotes'
import { Home } from './Home'
import { Empresas } from './empresas'
import Relatorios from './relatorios/Relatorios'
import Usuarios from './usuarios/Usuarios'

export const AppRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path={`${match.path}emplacamentos`} component={Emplacamentos} />
      <Route path={`${match.path}empresas`} component={Empresas} />
      <Route path={`${match.path}lotes`} component={Lotes} />
      <Route path={`${match.path}produtos`} component={Produtos} />
      <Route path={`${match.path}pedidos`} component={Pedidos} />
      <Route path={`${match.path}usuarios`} component={Usuarios} />
      <Route path={`${match.path}relatorios`} component={Relatorios} />
    </Switch>
  )
}
