import axios from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { createSelector } from 'reselect'
import { EmpresaModel, PERMISSOES, SEPLAC_FABRICANTE_ID, UsuarioModel } from '../common/Models'
import { createSimpleStore } from 'react-simple-reducer'
import { notification } from 'antd'

export const AuthStore = createSimpleStore(
  {
    fetching: false,
    token: null as string | null,
    usuarioId: null,
    empresas: [] as Array<EmpresaModel & { permissoes: PERMISSOES[] }>,
    idEmpresaAtiva: null as number | null,
    nomeEmpresaAtiva: null as string | null,
  },
  {
    loginFetch(state) {
      state.fetching = true
    },
    setEmpresaAtiva(state, idEmpresaAtiva: number) {
      state.idEmpresaAtiva = idEmpresaAtiva
      localStorage.setItem('idEmpresaAtiva', '' + idEmpresaAtiva)
    },

    loginSuccess(state, payload: { token; empresas; usuario }) {
      state.fetching = false
      state.token = payload.token
      state.empresas = payload.empresas
      state.usuarioId = payload.usuario.id
      state.idEmpresaAtiva = _getEmpresaAtivaInicial(payload.empresas, payload.usuario)
    },
    loginError(state) {
      state.fetching = false
    },
    logout(state) {
      state.token = null
      state.empresas = []
    },
  },
  {
    thunks: {
      init() {
        return async (dispatch, getState) => {
          const tokenExist = getState().token
          if (!tokenExist) return
          try {
            const { token, empresas, usuario } = await axios.get('/usuarios/me').then((x) => x.data)
            dispatch(AuthStore.actions.loginSuccess({ token, empresas, usuario }))
          } catch (error: any) {
            dispatch(AuthStore.actions.logout())
          }
        }
      },
      login({ cpf, pwd }: { cpf: string; pwd: string }) {
        return async (dispatch) => {
          dispatch(AuthStore.actions.loginFetch())
          try {
            const { token, empresas, usuario } = await axios
              .post('/usuarios/login', { login: cpf, senha: pwd })
              .then((x) => x.data)
            dispatch(AuthStore.actions.loginSuccess({ token, empresas, usuario }))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Falha ao efetuar o login'
            notification.error({ message })
            dispatch(AuthStore.actions.loginError())
          }
        }
      },
    },
    options: {
      cache: {
        key: 'SEPLAC_AUTH',
        location: 'LOCALSTORAGE',
      },
    },
  }
)

type IState = ReturnType<typeof AuthStore.useState>

function _getEmpresaAtivaInicial(empresas: EmpresaModel[], usuario: UsuarioModel): number {
  const idEmpresaAtivaLocalStorage = localStorage.getItem('idEmpresaAtiva')
  if (
    idEmpresaAtivaLocalStorage &&
    idEmpresaAtivaLocalStorage !== 'undefined' &&
    empresas.some((e) => e.id === +idEmpresaAtivaLocalStorage)
  ) {
    return +idEmpresaAtivaLocalStorage
  }
  if (usuario.admin) return SEPLAC_FABRICANTE_ID
  return empresas?.[0]?.id
}

export const getUsuarioLogado = createSelector(
  (s: IState) => s.token,
  (token) => {
    if (!token) return null
    const decodedToken = jwtDecode<JwtPayload & UsuarioModel>(token)
    const now = Date.now().valueOf() / 1000
    if (!decodedToken || !decodedToken.exp || decodedToken.exp < now) return null
    return decodedToken
  }
)

export const getEmpresaAtiva = createSelector(
  (s: IState) => s.idEmpresaAtiva,
  (s: IState) => s.empresas,
  (id, empresas) => {
    return empresas.find((e) => e.id === id)
  }
)

export const getPermissoesEmpresa = createSelector(getEmpresaAtiva, (empresa) => {
  return empresa?.permissoes || []
})
