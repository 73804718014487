import { Input, notification } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { LoteModel } from '../Models'
import useAxios from 'axios-hooks'

interface BlanksScannerType extends React.HTMLAttributes<HTMLDivElement> {
  onScan: (lote: LoteModel) => any
  showProdutos?: boolean
  disabled?: boolean
}
const LotesScanner = ({ onScan, showProdutos, disabled, ...props }: BlanksScannerType) => {
  const { Container } = LotesScanner
  const [numeroLote, setNumeroLote] = useState('')
  const ref = React.useRef(null as any)
  const [{ loading }, reqLote] = useAxios<LoteModel>('lotes/codigo', {
    manual: true,
  })

  const amountOfKeyStrokes = React.useRef(0)
  const timeoutResetKeyStrokes = React.useRef(null as any)

  const handleSubmit = async (value) => {
    if (!value) return

    try {
      const lote = await reqLote({
        params: { numeroLote: value, showProdutos },
      }).then((x) => x?.data)
      if (!lote) notification.warn({ message: 'Lote não encontrato' })
      onScan(lote)
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao buscar o lote'
      notification.error({ message })
    } finally {
      setNumeroLote('')
    }
  }

  const handleKeyDown = () => {
    amountOfKeyStrokes.current++
    clearTimeout(timeoutResetKeyStrokes.current)
    timeoutResetKeyStrokes.current = setTimeout(() => {
      if (amountOfKeyStrokes.current > 20) void handleSubmit(ref.current.input.value)
      amountOfKeyStrokes.current = 0
    }, 300)
  }

  return (
    <Container {...props}>
      <Input.Search
        id="searchLotesScanner"
        autoFocus
        enterButton="Consultar"
        onChange={(e) => setNumeroLote(e.target.value)}
        value={numeroLote}
        size="large"
        placeholder="Número ou Id do lote"
        onSearch={async () => await handleSubmit(numeroLote)}
        style={{ margin: '0 0 20px 0' }}
        loading={loading}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
    </Container>
  )
}
LotesScanner.Container = styled('div')``

export default LotesScanner
