import styled from '@emotion/styled'
import { Card, notification, Spin, Tabs } from 'antd'
import useAxios from 'axios-hooks'
import React from 'react'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { LoteModel, PERMISSOES, ProdutoModel } from '../common/Models'
import { BlanksScanner, LotesScanner } from '../common/seplac'

export const Recebimento = () => {
  usePermissaoUsuario([PERMISSOES.RECEBER_BLANKS], { changeRoute: true })

  const { Card } = Recebimento

  const handleChangeTabs = (e) => {
    requestAnimationFrame(() => {
      if (e === '1') document?.getElementById('searchLotesScanner')?.focus()
      if (e === '2') document?.getElementById('searchBlanksScanner')?.focus()
    })
  }

  return (
    <Card title="Recebimento de Blanks">
      <Tabs onChange={handleChangeTabs}>
        <Tabs.TabPane tab="Lote" key="1">
          <RecebeLote />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Blank individual" key="2">
          <RecebeProduto />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}
Recebimento.Card = styled(Card)`
  margin: 12px auto;
  max-width: 1024px;
`

const RecebeLote = () => {
  const { Layout } = RecebeLote

  const [{ loading }, req] = useAxios(
    {
      method: 'POST',
      url: 'produtos/recebimento',
    },
    { manual: true }
  )

  async function handleScan(lote: LoteModel) {
    try {
      await req({ data: { blanks: lote.produtos.map((p) => p.serial) } })
      notification.success({ message: 'Lote recebido com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao receber Lote'
      notification.error({ message })
    }
  }

  return (
    <Layout>
      <Spin spinning={loading} tip="Efetuando o recebimento do lote...">
        <LotesScanner onScan={handleScan} showProdutos={true} />
      </Spin>
    </Layout>
  )
}
RecebeLote.Layout = styled.div``

const RecebeProduto = () => {
  const { Layout } = RecebeProduto

  const [{ loading }, req] = useAxios(
    {
      method: 'POST',
      url: 'produtos/recebimento',
    },
    { manual: true }
  )

  async function handleScan(blank: ProdutoModel) {
    try {
      await req({ data: { blanks: [blank.serial] } })
      notification.success({ message: 'Lote recebido com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao receber Blank'
      notification.error({ message })
    }
  }

  return (
    <Layout>
      <Spin spinning={loading} tip="Efetuando o recebimento do blank...">
        <BlanksScanner onScan={handleScan} showLote={true} showProduto={false} />
      </Spin>
    </Layout>
  )
}
RecebeProduto.Layout = styled.div``
