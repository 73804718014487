import styled from '@emotion/styled'
import { Table, Button, Checkbox, Tabs, Badge } from 'antd'
import useAxios from 'axios-hooks'
import { format, parseISO } from 'date-fns'
import React from 'react'
import usePermissaoUsuario from '../common/hooks/usePermissaoUsuario'
import { PedidoModel, PERMISSOES } from '../common/Models'
import { StatusEmplacamentoDetran } from '../common/seplac/StatusEmplacamentoDetran'
import { Show } from '../common/Show'
import { HistoricoContainer } from './ListaPedidos/Historico'

export const PendentesFinalizacaoDetranGo = () => {
  usePermissaoUsuario([PERMISSOES.REENVIAR_FINALIZACAO_DETRAN], { changeRoute: true })

  const [nfInformadas, setNfInformadas] = React.useState(false)
  const { Layout } = PendentesFinalizacaoDetranGo
  const [{ data, loading }, fetch] = useAxios<PedidoModel[]>('/emplacamentos/pendentes-detran-go')
  const [emplacamentoId, setEmplacamentoId] = React.useState<number | null>(null)

  function handleClickVisualizarHistorico(row: PedidoModel) {
    setEmplacamentoId(row.emplacamento.id)
  }

  const notaFiscal = data?.filter((pedido) => {
    if (pedido.emplacamento.dataEnvioNfeDetranGo != null) return false
    if (!nfInformadas) return true
    return !!pedido.emplacamento.numeroNF
  })
  const emplacamento = data?.filter((x) => x.emplacamento.dataEnvioEmplacamentoDetranGo == null)
  const imagens = data?.filter((pedido) => {
    if (pedido.emplacamento.dataEnvioImagensInstalacaoDetranGo != null) return false
    if (!pedido.emplacamento.codigoAutorizacaoDetran) return false
    return true
  })

  return (
    <Layout>
      <Tabs>
        <Tabs.TabPane key="1" tab={<LabelBadge label="Nota Fiscal" total={notaFiscal?.length} />}>
          <Checkbox value={nfInformadas} onChange={() => setNfInformadas(!nfInformadas)} /> Somente
          NFs informadas
          <Tabela
            data={notaFiscal}
            loading={loading}
            handleClickVisualizarHistorico={handleClickVisualizarHistorico}
            fetch={fetch}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={<LabelBadge label="Emplacamentos" total={emplacamento?.length} />}
        >
          <Tabela
            data={emplacamento}
            loading={loading}
            handleClickVisualizarHistorico={handleClickVisualizarHistorico}
            fetch={fetch}
            tab="Emplacamentos"
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab={<LabelBadge label="Imagens" total={imagens?.length} />}>
          <Tabela
            data={imagens}
            loading={loading}
            handleClickVisualizarHistorico={handleClickVisualizarHistorico}
            fetch={fetch}
          />
        </Tabs.TabPane>
      </Tabs>
      <Show condition={emplacamentoId}>
        <HistoricoContainer
          emplacamentoId={emplacamentoId}
          closeModal={() => setEmplacamentoId(null)}
        />
      </Show>
    </Layout>
  )
}
PendentesFinalizacaoDetranGo.Layout = styled.div``

const Tabela = ({ data, loading, handleClickVisualizarHistorico, fetch, tab = '' }) => {
  return (
    <Table
      dataSource={data}
      loading={loading}
      rowKey="id"
      columns={[
        {
          title: 'Códigos de Autorização',
          render: (row: PedidoModel) => <CodigosAutorizacao row={row} />,
        },
        { title: 'Placa', render: (row: PedidoModel) => row.emplacamento.placa },
        { title: 'Empresa', render: (row: PedidoModel) => row.empresaOrigem.descricao },
        {
          title: 'Data',
          render: (row: PedidoModel) => format(parseISO(row.createdAt), 'dd/MM/yyyy HH:mm'),
        },
        {
          title: '',
          render: (row: PedidoModel) => (
            <>
              {tab === 'Emplacamentos' ? (
                <RenderVisualizarHistorico
                  row={row}
                  handleClickVisualizarHistorico={handleClickVisualizarHistorico}
                />
              ) : (
                <Button onClick={() => handleClickVisualizarHistorico(row)}>
                  Visualizar Histórico
                </Button>
              )}
            </>
          ),
        },
        {
          title: '',
          render: (row: PedidoModel) => <StatusEmplacamentoDetran pedido={row} onUpdate={fetch} />,
        },
      ]}
    />
  )
}

const RenderVisualizarHistorico = ({
  row,
  handleClickVisualizarHistorico,
}: {
  row: PedidoModel
  handleClickVisualizarHistorico
}) => {
  const [{ data }, consulta] = useAxios(
    `/emplacamentos/autorizacao-estampagem/${row.emplacamento.codigoAutorizacaoDenatran}`
  )
  const [{ loading }, reenvia] = useAxios(
    {
      method: 'POST',
      data: { pedidoId: row.id },
      url: '/emplacamentos/reenvia-registro-estampagem',
    },
    { manual: true }
  )
  return (
    <>
      <Button onClick={() => handleClickVisualizarHistorico(row)}>Visualizar Histórico</Button>
      <br />
      <div>
        <small>Status Denatran</small>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontWeight: 'bold', marginRight: 12 }}>
          {data?.autorizacaoEstampagem?.estadoAutorizacao}
        </div>
        {data?.autorizacaoEstampagem?.estadoAutorizacao === 'EM_ESTAMPAGEM' ? (
          <Button
            onClick={() => {
              void reenvia().then(() => {
                void consulta()
              })
            }}
            size="small"
            loading={loading}
          >
            Reenviar ao Denatran
          </Button>
        ) : null}
      </div>
    </>
  )
}

const LabelBadge = ({ total, label }) => {
  return (
    <Badge count={total}>
      <div style={{ margin: '0 12px' }}>{label}</div>
    </Badge>
  )
}

const CodigosAutorizacao = ({ row }: { row: PedidoModel }) => {
  return (
    <div>
      <div>
        <strong style={{ fontSize: '.8em' }}>Cód. Denatran:</strong>
        <br /> {row.emplacamento.codigoAutorizacaoDenatran || '-'}
      </div>
      <div>
        <strong style={{ fontSize: '.8em' }}>Cód. Detran:</strong>
        <br /> {row.emplacamento.codigoAutorizacaoDetran || '-'}
      </div>
    </div>
  )
}
