import React from 'react'
import { Card, notification, Button } from 'antd'
import useAxios from 'axios-hooks'
import { Form, FormikProvider, useFormik } from 'formik'
import { TextField } from '../common/TextField'
import { Show } from '../common/Show'
import { AuthStore } from '../auth/AuthStore'

export const AlteraSenha = () => {
  const dispatch = AuthStore.useDispatch()
  const [{ loading }, reqUpdateSenha] = useAxios(
    { url: '/usuarios/senha', method: 'PUT' },
    { manual: true }
  )
  async function handleSubmit(values, { resetForm }) {
    try {
      const dadosLogin = await reqUpdateSenha({ data: values }).then((x) => x.data)
      dispatch(AuthStore.actions.loginSuccess(dadosLogin))
      resetForm()
      notification.success({ message: 'Senha alterada com sucesso' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao alterar a senha'
      notification.error({ message })
    }
  }
  return (
    <Card style={{ margin: '24px auto', maxWidth: 800 }}>
      <AlteraSenha.Form onSubmit={handleSubmit} loading={loading} />
    </Card>
  )
}

const AlteraSenhaForm = ({
  onSubmit,
  loading,
  senhaAtual,
}: {
  onSubmit
  loading: boolean
  senhaAtual?
}) => {
  const formik = useFormik({
    initialValues: { senhaAtual: senhaAtual || '', novaSenha: '', confirmacaoNovaSenha: '' },
    validate: (values) => {
      const errors: any = {}
      if (!senhaAtual) {
        if (!values.senhaAtual) errors.senhaAtual = 'Senha Atual é obrigatória'
        if (values.senhaAtual.length < 6)
          errors.senhaAtual = 'A senha deve ter no mínimo 6 caracteres'
      }
      if (!values.novaSenha) errors.novaSenha = 'Nova Senha é obrigatória'
      if (!values.confirmacaoNovaSenha)
        errors.confirmacaoNovaSenha = 'Confirmação da nova senha é obrigatória'
      if (values.novaSenha.length < 6)
        errors.novaSenha = 'Sua senha deve ter no mínimo 6 caracteres'
      if (values.novaSenha !== values.confirmacaoNovaSenha)
        errors.confirmacaoNovaSenha = 'Confirmação de senha incompatível'
      return errors
    },
    onSubmit,
  })
  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Show condition={!senhaAtual}>
          <TextField type="password" label="Senha atual" name="senhaAtual" />
        </Show>
        <TextField type="password" label="Nova Senha" name="novaSenha" />
        <TextField type="password" label="Confirmação de senha" name="confirmacaoNovaSenha" />
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button htmlType="submit" loading={loading}>
            Confirmar
          </Button>
        </div>
      </Form>
    </FormikProvider>
  )
}

AlteraSenha.Form = AlteraSenhaForm
