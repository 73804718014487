import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { VendaProdutos } from './venda/VendaProdutos'
import { ListaVenda } from './venda/ListaVenda'
import { ListaDevolucoes } from './devolucoes/ListaDevolucoes'

const Pedidos = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/venda`} component={VendaProdutos} />
      <Route path={`${match.path}/lista-vendas`} component={ListaVenda} />
      <Route path={`${match.path}/lista-devolucoes`} component={ListaDevolucoes} />
    </Switch>
  )
}

export default Pedidos
