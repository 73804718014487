import React from 'react'
import { PedidoModel, SERVICOS } from '../../../common/Models'
import { PlacaMercosul } from '../../../common/seplac/PlacaMercosul'
import { Show } from '../../../common/Show'

export const RenderPlaca = ({ pedido }: { pedido: PedidoModel }) => {
  if (pedido.servicoId === SERVICOS.PAR_DE_PLACAS) {
    return (
      <div style={{ position: 'relative', padding: '3% 0' }}>
        <Show condition={pedido.servicoId === SERVICOS.PAR_DE_PLACAS}>
          <PlacaMercosul
            placa={pedido.emplacamento.placa}
            categoria={pedido.emplacamento.categoriaVeiculo}
            colecao={pedido.emplacamento.especieVeiculo === 'COLECAO'}
            moto={pedido.emplacamento.moto}
            style={{ width: '97%', position: 'absolute', top: '3%', left: '3%' }}
          />
        </Show>
        <PlacaMercosul
          placa={pedido.emplacamento.placa}
          categoria={pedido.emplacamento.categoriaVeiculo}
          colecao={pedido.emplacamento.especieVeiculo === 'COLECAO'}
          moto={pedido.emplacamento.moto}
          style={{ width: '97%', marginTop: '-5%', marginLeft: '-3%' }}
        />
      </div>
    )
  }
  return (
    <PlacaMercosul
      placa={pedido.emplacamento.placa}
      categoria={pedido.emplacamento.categoriaVeiculo}
      colecao={pedido.emplacamento.especieVeiculo === 'COLECAO'}
      moto={pedido.emplacamento.moto}
    />
  )
}
