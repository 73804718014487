import React from 'react'
import styled from '@emotion/styled'
import { Button, Modal } from 'antd'
import useAxios from 'axios-hooks'
import { format, parseISO } from 'date-fns'
import { LoteModel, PedidoModel, ProdutoModel, TIPOS_PRODUTOS } from '../../common/Models'

export const ComprovanteDevolucao = ({ pedidoId, onClose }) => {
  const { getLotes } = ComprovanteDevolucao
  const [{ data: pedido }] = useAxios(`/pedidos/${pedidoId}`, { manual: !pedidoId })
  const lotesIds = getLotes(pedido)
  const [{ data: lotes }] = useAxios(
    { url: '/lotes/', params: { ids: lotesIds } },
    { manual: !pedido }
  )
  return (
    <Modal
      visible={pedidoId}
      footer={<Button onClick={() => window.print()}>Imprimir</Button>}
      title="Comprovante de Devolução"
      onCancel={onClose}
    >
      <Conteudo pedido={pedido}>
        <ListaProdutos produtos={pedido?.produtos} lotes={lotes?.rows} />
      </Conteudo>
    </Modal>
  )
}
ComprovanteDevolucao.getLotes = (pedido: PedidoModel) => {
  if (!pedido?.produtos) return []
  return Array.from(new Set(pedido.produtos.map((p) => p.loteId)))
}

const Conteudo = ({ pedido, children }: { pedido: PedidoModel; children }) => {
  const { Assinatura, Layout, Table } = Conteudo

  const [{ data: usuarioLogado }] = useAxios('/usuarios/me')

  if (!pedido) return null

  return (
    <Layout>
      <div>
        Id do pedido: <strong>{pedido.id}</strong>
      </div>
      <div>
        Origem: <strong>{pedido.empresaOrigem.descricao}</strong>
      </div>
      <div>
        Destino: <strong>{pedido.empresaDestino.descricao}</strong>
      </div>
      <div>
        Data: <strong>{format(parseISO(pedido.createdAt), 'dd/MM/yyyy HH:mm')}</strong>
      </div>
      <div>
        Emitido por: <strong>{usuarioLogado?.usuario?.nome}</strong>
      </div>

      <div>
        <Table>
          <thead>
            <tr>
              <th style={{ textAlign: 'right' }}>Quantidade</th>
              <th>Produto</th>
              <th>Lote/serial</th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </div>

      <div>Recebi os produtos constantes no pedido de venda acima</div>

      <div>Data ___/___/_________</div>

      <Assinatura className="assinatura">Nome / Assinatura do Recebedor</Assinatura>
    </Layout>
  )
}
Conteudo.Assinatura = styled.div`
  border-top: 1px solid black;
  width: 400px;
  text-align: center;
  margin: 28px auto 0 auto;
`
Conteudo.Layout = styled.div`
  @media print {
    position: fixed;
    background: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    & > div {
      margin-top: 24px;
    }
    .assinatura {
      margin-top: 64px;
    }
  }
`
Conteudo.Table = styled.table`
  width: 100%;
  td,
  th {
    padding: 2px 6px;
    border-bottom: 1px solid #dedede;
  }
`

const ListaProdutos = ({ produtos, lotes }: { produtos: ProdutoModel[]; lotes: LoteModel[] }) => {
  const { getLotesCompletos, getProdutosSoltos, getDescricaoLote, getDescricaoProduto } =
    ListaProdutos
  const lotesCompletos = getLotesCompletos(produtos, lotes)
  const produtosSoltos = getProdutosSoltos(produtos, lotesCompletos)
  return (
    <>
      {lotesCompletos?.map((lote) => {
        const descricao = getDescricaoLote(lote, produtos)
        return (
          <tr key={lote.id}>
            <td style={{ textAlign: 'right' }}>{lote.qtdBlanks}</td>
            <td>{descricao}</td>
            <td>{lote.numero}</td>
          </tr>
        )
      })}
      {produtosSoltos?.map((produto) => {
        const descricao = getDescricaoProduto(produto)
        return (
          <tr key={produto.id}>
            <td style={{ textAlign: 'right' }}>1</td>
            <td>{descricao}</td>
            <td>{produto.serial}</td>
          </tr>
        )
      })}
    </>
  )
}
ListaProdutos.getLotesCompletos = (produtos: ProdutoModel[], lotes: LoteModel[]): LoteModel[] => {
  if (!lotes || !produtos) return []
  return lotes.filter((lote) => {
    return produtos.filter((p) => p.loteId === lote.id).length === lote.qtdBlanks
  })
}
ListaProdutos.getProdutosSoltos = (
  produtos: ProdutoModel[],
  lotes: LoteModel[]
): ProdutoModel[] => {
  if (!lotes || !produtos) return []
  const lotesIds = lotes.map((l) => l.id)
  return produtos.filter((produto) => {
    return !lotesIds.includes(produto.loteId)
  })
}
ListaProdutos.getDescricaoLote = (lote: LoteModel, produtos: ProdutoModel[]): string => {
  const produto = produtos.find((p) => p.loteId === lote.id)
  const descricao = ListaProdutos.getDescricaoProduto(produto as ProdutoModel)
  return descricao.replace('Placa', 'Lote de placas')
}
ListaProdutos.getDescricaoProduto = (produto: ProdutoModel): string => {
  if ([TIPOS_PRODUTOS.BLANK_MOTO, TIPOS_PRODUTOS.PLACA_MOTO].includes(produto.tipoProdutoId)) {
    return 'Placa de moto mercosul'
  }
  return 'Placa de carro mercosul'
}
